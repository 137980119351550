<template>
    <div class="container">
      <Header></Header>
      <BoxTop></BoxTop>
      <BoxBottom></BoxBottom>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import BoxTop from '../components/BoxTop.vue'
import BoxBottom from '../components/BoxBottom.vue'
import { getAllData, getCode } from '@/api/index'
export default {
  components: { Header, BoxTop, BoxBottom },
  name: 'App',
  data() {
    return {
    }
  },
  created() {
    this.getAllData()
    setInterval(() => {
      this.getNewCode().then(res => {
        if (!res) {
          this.getAllData()
        }
      })
    }, 1000 * 10)
  },
  methods: {
    getAllData() {
      getAllData().then(res => {
        const { employeeTotalNm, postTotalNum, industryIndexEntity } = res.data;
        this.$store.commit('setEmployeeTotalNm', employeeTotalNm);
        this.$store.commit('setPostTotalNum', postTotalNum);
        this.$store.commit('setAllData', res.data);
        this.$store.commit('setIndustryIndexEntity', industryIndexEntity);
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}

.container {
  width: 100%;
  height: 100%;
  background: url("../assets/image/bg.png") no-repeat center;
  background-size: 100% 100%;
}
</style>
