<template>
  <div class="box-content-3">
    <div class="title">
      海河英才引进情况
    </div>
    <div class="chart-body">
      <div class="chart-top">
        <div class="chart-dipan"></div>
        <div ref="echart" id="echart" class="echartDiv"></div>
      </div>
      <div @click="handleClick" style="cursor: pointer;">
        <BoxViewNumber :boxnum="$store.state.page2Data.haiheTalentIntroCount.totalNum" style="margin-top: -10px;">
        </BoxViewNumber>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as echarts from 'echarts'
import 'echarts-gl' // 3d图表库
Vue.prototype.$echarts = echarts

import BoxViewNumber from './BoxViewNumber.vue'
export default {
  components: { BoxViewNumber },
  name: 'chart',
  data() {
    return {

    }
  },
  watch: {
    '$store.state.page2Data.haiheTalentIntroCount'(newValue, oldVal) {
      this.initChartR2()
    }
  },
  methods: {
    handleClick() {
      this.$router.push({
        path: '/map'
      })
    },
    initChartR2() {
      const myChart = echarts.init(this.$refs.echart);
      function getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
          isSelected = false;
        }

        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 0.55 : 0.5;

        // 返回曲面参数方程
        return {
          u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32,
          },

          v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },

          x: function (u, v) {
            if (u < startRadian) {
              return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
              return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          y: function (u, v) {
            if (u < startRadian) {
              return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
              return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          z: function (u, v) {
            if (u < -Math.PI * 0.5) {
              return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
              return Math.sin(u) * h * 0.1;
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
          },
        };
      }

      // 生成模拟 3D 饼图的配置项
      function getPie3D(pieData, internalDiameterRatio) {
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
          typeof internalDiameterRatio !== 'undefined'
            ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
            : 1 / 3;

        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value;
          let seriesItem = {
            name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
            type: 'surface',
            parametric: true,
            wireframe: {
              show: false,
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k: k,
            },
          };

          if (typeof pieData[i].itemStyle != 'undefined') {
            let itemStyle = {};

            typeof pieData[i].itemStyle.color != 'undefined' ? (itemStyle.color = pieData[i].itemStyle.color) : null;
            typeof pieData[i].itemStyle.opacity != 'undefined'
              ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
              : null;

            seriesItem.itemStyle = itemStyle;
          }
          series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value;

          series[i].pieData.startRatio = startValue / sumValue;
          series[i].pieData.endRatio = endValue / sumValue;
          series[i].parametricEquation = getParametricEquation(
            series[i].pieData.startRatio,
            series[i].pieData.endRatio,
            false,
            false,
            k,
            series[i].pieData.value
          );

          startValue = endValue;

          legendData.push(series[i].name);
        }

        series.push({
          name: 'pie2d',
          type: 'pie',
          labelLine: {
            length: 20,
            length2: 20,
            lineStyle: {
              color: '#E68957'
            }
          },
          label:{
           color:'#FFF'
          },
          startAngle: -50, //起始角度，支持范围[0, 360]。
          clockwise: false,//饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
          radius: ['20%', '50%'],
          center: ['50%', '50%'],
          data: pieData.map((item, index) => {
            return { ...item, itemStyle: { color: 'transparent' } }
          }),
          itemStyle: {
            opacity: 1
          }
        });
        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
          //animation: false,
          legend: {
            show: false
          },
          animation: true,
          tooltip: {
            formatter: (params) => {
              if (
                params.seriesName !== 'mouseoutSeries' &&
                params.seriesName !== 'pie2d'
              ) {
                return `${params.seriesName
                  }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color
                  };"></span>${series[params.seriesIndex].pieData.value + '%'
                  }`
              }
            },
            textStyle: {
              fontSize: 14
            }
          },
          title: {
            x: 'center',
            top: '20',
            textStyle: {
              color: '#fff',
              fontSize: 22
            }
          },
          labelLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            normal: {
              show: true,
              length: 10,
              length2: 10
            }
          },
          label: {
            show: true,
            position: 'outside',
            formatter: '{b} \n{d}%',
            textStyle: {
              fontSize: '12px'
            }
          },
          xAxis3D: {
            min: -1,
            max: 1,
          },
          yAxis3D: {
            min: -1,
            max: 1,
          },
          zAxis3D: {
            min: -1,
            max: 1,
          },
          grid3D: {
            show: false,
            boxHeight: 10,
            viewControl: {
              //3d效果可以放大、旋转等，请自己去查看官方配置
              alpha: 40,
              // beta: 40,
              distance: 150,
              rotateSensitivity: 0,
              zoomSensitivity: 0,
              panSensitivity: 0,
              autoRotate: false,
            },
            //后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
            // postEffect: {
            //   //配置这项会出现锯齿，请自己去查看官方配置有办法解决
            //   enable: true,
            //   bloom: {
            //     enable: true,
            //     bloomIntensity: 0.1,
            //   },
            //   SSAO: {
            //     enable: true,
            //     quality: 'medium',
            //     radius: 1,
            //   },
            // },
          },

          series: series,
        };
        return option;
      }
      // 传入数据生成 option
      const optionsData = [
        {
          name: '技能型',
          value: this.$store.state.page2Data.haiheTalentIntroCount.skillPercent,
          itemStyle: {
            opacity: 0.8,
            color: '#025BF4'
          }
        },
        {
          name: '创业型',
          value: this.$store.state.page2Data.haiheTalentIntroCount.entrepreneurialPercent,
          itemStyle: {
            opacity: 0.8,
            color: '#92D6F8'
          }
        },
        {
          name: '学历型',
          value: this.$store.state.page2Data.haiheTalentIntroCount.educationPercent,
          itemStyle: {
            opacity: 0.8,
            color: '#5176FD'
          }
        },
        {
          name: '急需型',
          value: this.$store.state.page2Data.haiheTalentIntroCount.urgentNeedPercent,
          itemStyle: {
            opacity: 0.8,
            color: '#1DC6A8'
          }
        },
        {
          name: '资格型',
          value: this.$store.state.page2Data.haiheTalentIntroCount.qualificationPercent,
          itemStyle: {
            opacity: 0.8,
            color: '#5BBBD1'
          }
        }
      ]
      // 传入数据生成 option
      const option = getPie3D(
        optionsData,
        0
      );
      myChart.setOption(option)

    }
  }
}
</script>

<style lang="scss" scoped>
.box-content-3 {
  position: relative;
  width: 100%;
  margin-top: 10px;

  .title {
    width: 329.6px;
    height: 43.5px;
    background: url("../../assets/page2/样式07_1_@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
    line-height: 43.5px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-indent: 28px;
  }

  .chart-body {
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;

    .chart-top {
      width: 100%;
      height: 200px;

      .chart-dipan {
        width: 275px;
        height: 140px;
        background: url("../../assets/image/chart-1.png") no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        margin-left: -137.5px;
        bottom: 40px;
      }

      .echartDiv {
        margin-top: 0;
        width: 100%;
        height: 200px;
      }
    }

  }
}
</style>