<template>
  <div class="box-top">
    <div class="left">
      <div class="icon"></div>
      <div class="content">
        <div class="title">人力资源服务产业园总产值（2023年度）</div>
        <div class="num">
          <countTo :startVal='0' :endVal='$store.state?.page2Data?.totalOutputValue || 0' :duration='3000' :decimals="2"></countTo>
          <span class="ren">亿元</span>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="icon"></div>
    </div>
    <div class="right">
      <div class="icon"></div>
      <div class="content">
        <div class="title">人力资源服务产业园服务人员数（2023年度）</div>
        <div class="num">
          <countTo :startVal='0' :endVal='$store.state?.page2Data?.servicePersonnelNumber || 0' :duration='3000' :decimals="2"></countTo>
          <span class="ren">万人</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
}
</script>


<style lang="scss" scoped>
.box-top {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  margin-top: 30px;

  .left {
    width: 853px;
    height: 130px;
    background: url("../../assets/image/boxtop-left.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;

    .icon {
      width: 116px;
      height: 116px;
      background: url("../../assets/image/boxtop-left-icon.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 176px;
    }

    .content {
      height: 116px;
      margin-left: 55px;

      .title {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        margin-top: 10px;
      }

      .num {
        font-family: DIN Alternate, DIN Alternate;
        font-weight: 700;
        font-size: 62px;
        color: #47D1FF;
        font-style: normal;
        text-transform: none;

        .ren {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }

  .center {
    width: 207px;
    height: 180px;
    background: url("../../assets/image/boxtop-conter.gif") no-repeat center;
    background-size: 100% 100%;
    margin-top: -28px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 84px;
      height: 84px;
      background: url("../../assets/image/boxtop-icon.png") no-repeat center;
      background-size: 100% 100%;
    }
  }

  .right {
    width: 853px;
    height: 130px;
    background: url("../../assets/image/boxtop-right.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;

    .icon {
      width: 116px;
      height: 116px;
      background: url("../../assets/image/boxtop-right-icon.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 176px;
    }

    .content {
      height: 116px;
      margin-left: 55px;

      .title {
        margin-top: 10px;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
      }

      .num {
        font-family: DIN Alternate, DIN Alternate;
        font-weight: 700;
        font-size: 62px;
        color: #47D1FF;
        font-style: normal;
        text-transform: none;

        .ren {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }
}
</style>