<template>
  <div class="box-view-number">
    <div class="number-content">
      <div v-for="(item, index) in arrNum" :key="index">
        <div class="num-item">{{ item.val }}</div>
      </div>
      <div class="unit">人</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    boxnum: {
      default: 0,
    }
  },
  data() {
    return {
      arrNum: [
        {
          val: 1
        },
        {
          val: 1
        }
      ]
    }
  },
  watch: {
    'boxnum'(newValue, oldVal) {
      this.handleCreatedNum(newValue)
    }
  },
  created() {
    this.handleCreatedNum(this.boxnum)
  },
  methods: {
    handleCreatedNum(num) {
      this.arrNum = [];
      if (num) {
        let numberStr = parseInt(num).toString();
        // 遍历字符串中的每一个字符
        for (const digit of numberStr) {
          let obj = {
            val: digit
          }
          this.arrNum.push(obj);
        }
      } else {
        this.arrNum = [
          {
            val: 0
          },
        ]
      }
      console.log(this.arrNum, ":this.arrNum");

    }
  }
}
</script>

<style lang="scss" scoped>
.box-view-number {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;

  .unit {
    height: 35px;
    line-height: 40px;
    color: aqua;
    text-align: center;
    font-size: 14px;
    margin-left: 5px;
  }

  .number-content {
    display: flex;

    .num-item {
      width: 25px;
      height: 35px;
      background: url("../../assets/page2/组 3845@2x.png") no-repeat center;
      background-size: 100% 100%;

      color: aqua;
      text-align: center;
      line-height: 35px;
      font-weight: bold;
      font-size: 24px;
      margin: 0 2px;
    }
  }
}
</style>