<template>
  <div class="chart">
    <div class="title">
      和平区岗位需求规模TOP10
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
let colorList = ['#FDD96B', '#43E9A3', '#31EEF0', '#0E96FF', '#0E96FF', '#0E96FF', '#0E96FF'];

const img_1 = require("../../assets/page4/img_1.png");
const img_2 = require("../../assets/page4/img_2.png");
const img_3 = require("../../assets/page4/img_3.png");
const img_4 = require("../../assets/page4/img_4.png");
const img_5 = require("../../assets/page4/img_5.png");
const img_6 = require("../../assets/page4/img_6.png");
const img_7 = require("../../assets/page4/img_7.png");
const img_8 = require("../../assets/table/img_8.png");
const img_9 = require("../../assets/table/img_9.png");
const img_10 = require("../../assets/table/img_10.png");
export default {
  data() {
    return {
      data: [
        { value: 88, name: '金融服务' },
        { value: 77, name: '商贸零售' },
        { value: 66, name: '人力资源' },
        { value: 66, name: '航运服务' },
        { value: 55, name: '文化旅游' },
        { value: 44, name: '数字信息' },
        { value: 33, name: '医药健康' },
      ]
    }
  },
  // data () {
  //   return {
  //     eventWarning: [],
  //   }
  // },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { postDemandTop, medianSalaryYoy2 } = newValue;
      this.data = [
        {value:postDemandTop.top1Percent,name:postDemandTop.top1Name},
        {value:postDemandTop.top2Percent,name:postDemandTop.top2Name},
        {value:postDemandTop.top3Percent,name:postDemandTop.top3Name},
        {value:postDemandTop.top4Percent,name:postDemandTop.top4Name},
        {value:postDemandTop.top5Percent,name:postDemandTop.top5Name},
        {value:postDemandTop.top6Percent,name:postDemandTop.top6Name},
        {value:postDemandTop.top7Percent,name:postDemandTop.top7Name},
        {value:postDemandTop.top8Percent,name:postDemandTop.top8Name},
        {value:postDemandTop.top9Percent,name:postDemandTop.top9Name},
        {value:postDemandTop.top10Percent,name:postDemandTop.top10Name},
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      let option = {
        grid: {
          left: '20%',  // 调整左边距
          right: '15%', // 调整右边距
          top: '10',   // 调整顶部边距
          bottom: '10' // 调整底部边距
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false
        },
        xAxis: [{
          splitLine: {
            show: false
          },
          max: 100,
          type: 'value',
          show: false
        }],
        yAxis: [
          {
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            type: 'category',
            axisTick: {
              show: false
            },
            inverse: true,
            axisLabel: {
              margin: 10
            },
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: { "color": "#085ABB", "fontSize": 12 },
              margin: 100,
              align: 'right', // Align the labels to the left
            },
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: { "color": "#46D0FF", "fontSize": 12 },
              formatter: function (value, index) {
                const id = index + 1;
                if (id < 4) {
                  return `{rank${id}|${value} %}`;
                } else {
                  return `{rank|${value} %}`;
                }
              },
              rich: {
                rank1: {
                  color: '#FDD96B',
                },
                rank2: {
                  color: '#43E9A3',
                },
                rank3: {
                  color: '#31EEF0',
                },
                rank: {
                  color: '#46D0FF',
                },
              },
              align: 'left', // Align the labels to the left
            },
            data: this.data.map(item => ({
              value: item.value,
              name: item.name,
            }))
          }
        ],
        series: [{
          name: '',
          type: 'bar',
          barWidth: 10, // Width of the bar
          showBackground: true,
          backgroundStyle: {
            color: '#0A3F87',
            borderRadius: 5, //设置背景的圆角
            borderColor: "#1656AD",
            borderWidth: 1
          },
          yAxisIndex: 0,
          label: {
            show: true,
            color: "#fff"
          },
          data: this.data.map((item, index) => ({
            value: item.value,
            name: item.name,
            itemStyle: {
              color: colorList[index],
              borderRadius: 5,
            },
            label: {
              show: true,
              position: 'left', // Place the label on the right side of the bar
              itemStyle: {
                color: colorList[index > 2 ? index : 3]
              },
              formatter: function (value) {
                console.log(value, ":value");
                return `{img${value.dataIndex}|} ${value.name}`;
              },
              rich: {
                img0: {
                  width: 22,
                  height: 15,
                  backgroundColor: {
                    image: img_1,
                  },
                },
                img1: {
                  width: 22,
                  height: 15,
                  backgroundColor: {
                    image: img_2,
                  },
                },
                img2: {
                  width: 22,
                  height: 15,
                  backgroundColor: {
                    image: img_3,
                  },
                },
                img3: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_4,
                  },
                },
                img4: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_5,
                  },
                },
                img5: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_6,
                  },
                },
                img6: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_7,
                  },
                },
                img7: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_8,
                  },
                },
                img8: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_9,
                  },
                },
                img9: {
                  width: 18,
                  height: 15,
                  backgroundColor: {
                    image: img_10,
                  },
                },
              },

            }
          }))
        }]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 240px;
    }
  }
}
</style>
