<template>
  <div class="container" :class="{ 'heping': activePath == '/maphp' }">
    <Header :type="activePath == '/maphp' ? 4 : 2"></Header>
    <BoxViewNumber class="box-view-number" :boxnum="$store.state.mapData.haiheTalentIntroCount2.totalNum">
    </BoxViewNumber>
    <Nav></Nav>
    <!-- <Province></Province> -->
    <div class="content">
      <el-switch class="switch" v-model="activePath" active-color="#F5EA89" inactive-color="#F5EA89" active-text="和平区"
        inactive-text="天津市" :active-value="'/maphp'" inactive-value="/map" @change="handleSwitchChange">
      </el-switch>
      <div class="title">
        海河英才人员情况
      </div>
      <div style="margin-top: 15px;">
        <BoxContent1 :hideValue="true"></BoxContent1>
        <BoxContent2 style="margin-top: 10px;"></BoxContent2>
        <BoxContent3 style="margin-top: 10px;" v-if="activePath == '/maphp'"></BoxContent3>
        <BoxContent4 style="margin-top: 10px;" v-if="activePath == '/map'"></BoxContent4>
        <Chart1></Chart1>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import BoxTop from '../components/page2/BoxTop.vue'
import BoxBottom from '../components/page2/BoxBottom.vue'
import { selectTalentDetailInfoByLastCode, getCode } from '@/api/index'
import BoxContent1 from '../components/map/BoxContent1.vue'
import BoxContent2 from '../components/map/BoxContent2.vue'
import BoxContent3 from '../components/map/BoxContent3.vue'
import BoxContent4 from '../components/map/BoxContent4.vue'
import Chart1 from '../components/map/Chart1.vue'
import Province from '../components/map/Province.vue'
import BoxViewNumber from '../components/page2/BoxViewNumber.vue'

import Nav from '../components/page2/Nav.vue'
export default {
  components: { Chart1, Header, BoxTop, BoxBottom, BoxContent1, BoxContent2, BoxContent3, BoxContent4, Nav, BoxViewNumber, Province },
  name: 'App',
  data() {
    return {
      postMethod: {
        "/map": selectTalentDetailInfoByLastCode,
        "/maphp": selectTalentDetailInfoByLastCode
      },
      activePath: this.$route.path,
      value: 1,
    }
  },
  watch: {
    "$route": {
      handler(newVal, oldVal) {
        this.activePath = newVal.path;
        this.getAllData();
      },
      immediate: true
    }
  },
  methods: {
    handleSwitchChange() {
      this.getAllData();
    },
    // handleHomeClick
    handleHomeClick() {
      this.$router.push({
        path: '/home'
      })
    },
    handleBackClick() {
      this.$router.go(-1)
    },
    getAllData() {
      selectTalentDetailInfoByLastCode({}).then(res => {
        if (this.activePath == '/map') {
          this.$store.commit('setMapData', res.data.tianjin);
        } else {
          this.$store.commit('setMapData', res.data.hpq);
        }
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  position: absolute;
  left: -200px;
  z-index: 99999999;

  ::v-deep .el-switch__label {
    color: #FFF !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}



.container {
  width: 100%;
  height: 100%;
  background: url("../assets/map/map1.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;

  .box-view-number {
    width: 68px;
    height: 68px;
    position: absolute;
    left: 680px;
    top: 180px;
    cursor: pointer;
  }

  .back {
    width: 88px;
    height: 88px;
    position: absolute;
    left: 64px;
    bottom: 77px;
    background: url("../assets/map/上一个-默认@2x.png") no-repeat center;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .content {
    width: 680px;
    height: 843px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 150px;
    right: 15px;

    .title {
      width: 220px;
      height: 42px;
      background: url("../assets/image/post-title.png") no-repeat center;
      background-size: 100% 100%;
      line-height: 42px;

      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: 18px;
      color: #E5F9FF;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-indent: 26px;
    }
  }
}

.heping {
  background: url("../assets/map/map2.png") no-repeat center;
  background-size: 100% 100%;
}
</style>
