<template>
  <div class="chart">
    <div class="title">
      企业招聘经验要求占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      eventWarning: [],
      eventHandling: []
    }
  },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { enterpriseRequirementExperience, jobseekerYoy2 } = newValue;
      this.eventWarning = [
        enterpriseRequirementExperience.range1Percent,
        enterpriseRequirementExperience.range2Percent,
        enterpriseRequirementExperience.range3Percent,
        enterpriseRequirementExperience.range4Percent,
        enterpriseRequirementExperience.range5Percent,
      ];
      this.initChart();
    }
  },
  methods: {
    initChart() {
      var myChart = echarts.init(this.$refs.echart);
      let option = {
        grid: {
          top: '26%',
          left: "10%",
          right: '10%',
          bottom: '10%',
        },
        legend: {
          icon: 'rect',
          x: '560',
          y: '0',
          orient: 'horizontal',
          itemWidth: 12,
          itemHeight: 12,
          formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            color: '#fff',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'bottom',
              },
            },
          },
          data: ['2023上半年', '2024上半年'],
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          textStyle: {
            color: '#fff',
            fontSize: 12,
            lineHeight: 24,
          },
          padding: [5, 10],
          backgroundColor: 'rgba(0, 0, 0, 0.50)',
          formatter(params) {	// 根据条件修改
            let unit = '个'
            let relVal = params[0].name
            for (let i = 0; i < params.length; i++) {
              if (i === 1) {
                unit = '%'
              }
              if (params[i].seriesName) {
                if (typeof (params[i].color) === 'string') {
                  relVal += `<div style="display:flex;align-items:center"><div style="width: 13px;height: 5px; margin-right: 5px; background: ${params[i].color}"></div><div style="padding-right: 20px">${params[i].seriesName}</div>${params[i].value} ${unit}</div>`
                } else {
                  relVal += `<div style="display:flex;align-items:center"><div style="width: 13px;height: 5px; margin-right: 5px; background: ${params[i].color.colorStops[0].color}"></div><div style="padding-right: 20px">${params[i].seriesName}</div>${params[i].value} ${unit}</div>`
                }
              }
            }
            return relVal
          },
        },
        xAxis: {
          type: 'category',
          data: ['无经验', '1～3年', '3～5年', '5～10年', '10年以上' ],
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.45)',
            },
          },
          axisLabel: {
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [{
          type: 'value',
          min: 0,
          minInterval: 1,
          splitArea: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.15)',
              // type: 'dashed', // dotted 虚线
            },
          },
          axisLabel: {
            color: '#fff',
            formatter: '{value}%', // 右侧Y轴文字显示
          },
        },
        {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: '#fff',
          },
          splitArea: {
            show: false,
          },
        }
        ],
        series: [
          { // 柱底圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [16.1, 9], // 调整截面形状
            z: 1,
            symbolOffset: [0, 3],
            itemStyle: {
              color: 'rgba(255, 93, 93, 0)',
            },
            data: this.eventWarning,
          },
          {
            name: '',
            type: 'bar',
            z: 2,
            barWidth: 16,
            barMinHeight: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(255, 93, 93, 1)' },
                { offset: 1, color: 'rgba(255, 93, 93, 0)' },
              ]),
            },
            data: this.eventWarning,
          },
          { // 柱顶圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [16, 9], // 调整截面形状
            symbolOffset: [0, -4],
            z: 3,
            symbolPosition: 'end',
            itemStyle: {
              color: 'rgba(255, 93, 93, 1)',
            },
            data: this.eventWarning,
          }, {
              name: '',
              'z': 19,
              yAxisIndex: 0,
              type: 'line',
              data: this.eventWarning,
              lineStyle: {
                normal: {
                  width: 1,
                  color: 'rgba(219, 177, 104, 1)' // 线条颜色
                },
                borderColor: 'rgba(0,0,0,1)'
              },
            },
        ],
      };
      myChart.setOption(option, true);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 280px;
    display: flex;
    position: absolute;
    top: 10px;
    left: 0;

    .echartDiv {
      width: 100%;
      height: 100%;
    }
  }
}
</style>