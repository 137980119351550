<template>
  <div class="container">
    <Header :type="4"></Header>
    <Nav></Nav>
    <div class="box-left">
      <Chart1></Chart1>
      <Chart2></Chart2>
      <Chart3></Chart3>
    </div>
    <div class="box-centet">
      <Chart4></Chart4>
      <div class="centerBox">
        <Chart5></Chart5>
        <Chart6></Chart6>
      </div>
    </div>
    <div class="box-right">
      <Chart7></Chart7>
      <Chart8></Chart8>
      <Chart9></Chart9>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Nav from '../components/page2/Nav.vue'
import Chart1 from '../components/table/Chart1.vue'
import Chart2 from '../components/table/Chart2.vue'
import Chart3 from '../components/table/Chart3.vue'
import Chart4 from '../components/table/Chart4.vue'
import Chart5 from '../components/table/Chart5.vue'
import Chart6 from '../components/table/Chart6.vue'
import Chart7 from '../components/table/Chart7.vue'
import Chart8 from '../components/table/Chart8.vue'
import Chart9 from '../components/table/Chart9.vue'
import { selectHPQHrMarketMonitor1, getCode } from '@/api/index'
export default {
  components: { Header, Nav, Chart1, Chart2, Chart3,Chart4,Chart5 ,Chart6 ,Chart7 ,Chart8,Chart9},
  name: 'Page4',
  data() {
    return {
    }
  },
  created() {
    this.getAllData();
  },
  methods: {
    getAllData() {
      selectHPQHrMarketMonitor1({}).then(res => {
        this.$store.commit('setTableData', res.data);
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}

.container {
  width: 100%;
  height: 100%;
  background: url("../assets/table/bj.png") no-repeat center;
  background-size: 100% 106%;
  position: relative;

  .box-left {
    width: 558px;
    height: 920px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 140px;
    left: 15px;
  }

  .box-centet {
    width: 760px;
    height: 920px;
    
    // box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
  }
  .centerBox{
    // width: 780px;
    height: 606px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
  }

  .box-right {
    width: 558px;
    height: 920px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 140px;
    right: 15px;
  }
}
</style>
