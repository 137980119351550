<template>
  <div class="container-box">
    <div class="map">
      <div class="quyu quyu-1" @click="handleClick(1)">
        <div class="map-title">武清园区</div>
      </div>

      <div class="quyu quyu-2" @click="handleClick(2)">
        <div class="map-title">红桥园区</div>
      </div>

      <div class="quyu quyu-3" @click="handleClick(3)">
        <div class="map-title">津南园区</div>
      </div>

      <div class="quyu quyu-4" @click="handleClick(4)">
        <div class="map-title">滨海园区</div>
      </div>

      <div class="quyu quyu-5" @click="handleClick(5)">
        <div class="map-title">发促中心</div>
      </div>

      <div class="heping-icon" @click="handleClick(6)"></div>
      <div class="heping-title" @click="handleClick(6)">
        和平中心园区
      </div>
    </div>
    <div class="popup" id="popup" v-if="viewPopup"
      :class="{ 'popup-2': checkIndex == 2, 'popup-3': checkIndex == 3, 'popup-4': checkIndex == 4, 'popup-5': checkIndex == 5 }">
      <div class="popup-body">
        <div class="popup-body-title">
          {{ titleData }}
        </div>
        <div class="popup-body-content">
          <div>
            {{ contentText }}
          </div>
          <div class="img-list">
            <div class="img-item">
              <img :src="imgObj[0]" alt="">
            </div>
            <div class="img-item">
              <img :src="imgObj[1]" alt="">
            </div>
            <div class="img-item">
              <img :src="imgObj[2]" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewPopup: false,
      checkIndex: 0,
    }
  },
  computed: {
    titleData() {
      let obj = {
        1: "武清园区",
        2: "红桥园区",
        3: "津南园区",
        4: "滨海园区",
        5: "发促中心",
      }
      return obj[this.checkIndex];
    },
    contentText() {
      let obj = {
        1: "天津市武清人力资源服务产业园区，坐落于武清主城区西部新区核心区域，是京津冀协同发展战略下的重要人力资源服务平台。园区占地广阔，设施完善，已成功引入多家国内外知名人力资源企业，形成了强大的产业集聚效应。",
        2: "天津人力资源服务产业园红桥园区，作为国家级人力资源服务产业园的重要组成部分，集人才服务、产业孵化、交流合作于一体。园区秉承创新理念，汇聚优质资源，致力于构建高效、专业的人力资源服务平台，为区域经济发展提供坚实的人才支撑和智力保障。",
        3: "天津人力资源服务产业园津南园区，坐落于海棠众创大街，集产业聚集、人才汇聚、创新协同于一体。园区凭借智慧智能管理，打造完善服务体系，赋能企业孵化与产业升级，为区域经济社会发展注入强劲动力，是京津冀人力资源服务领域的璀璨明珠。",
        4: "天津人力资源服务产业园滨城园区，国家级人力资源服务标杆，坐落于天津经济技术开发区，占地广阔，设施先进。园区以“一域服务全局”为战略定位，汇聚猎头、招聘、咨询等多元业态，打造全链条人力资源服务生态圈。",
        5: "天津人力资源服务产业园发促中心是专注于推动人力资源服务业创新发展的核心机构，致力于构建高效、专业的人力资源服务产业集聚区。中心通过整合优质资源，提供一站式服务，包括政策咨询、业务指导、合作交流等，助力企业快速成长，优化人才配置。",
      }
      return obj[this.checkIndex];
    },
    imgObj() {
      let imgarr = [
        [require("../../assets/page2/wuqing-1.png"), require("../../assets/page2/wuqing-2.png"), require("../../assets/page2/wuqing-3.png")],
        [require("../../assets/page2/hongqiao-1.png"), require("../../assets/page2/hongqiao-2.png"), require("../../assets/page2/hongqiao-3.png")],
        [require("../../assets/page2/jinnan-1.png"), require("../../assets/page2/jinnan-2.png"), require("../../assets/page2/jinnan-3.png")],
        [require("../../assets/page2/bincheng-1.png"), require("../../assets/page2/bincheng-2.png"), require("../../assets/page2/bincheng-3.png")],
        [require("../../assets/page2/facu-1.png"), require("../../assets/page2/facu-2.png"), require("../../assets/page2/facu-3.png")]
      ]
      return imgarr[this.checkIndex - 1];
    }
  },
  methods: {
    handleClick(index) {
      console.log(index, "::::::::::::!@#!@");

      this.viewPopup = false;
      if (index != 6) {
        if (this.checkIndex == index) {
          this.viewPopup = false;
          this.checkIndex = 0;
        } else {
          this.checkIndex = index;
          this.viewPopup = true;
        }
      } else {
        this.$router.push({
          path: '/hp'
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-box {
  width: 500px;
  height: 788px;
  position: relative;

  .popup {
    position: absolute;
    width: 503px;
    height: 283px;
    background: url("../../assets/page2/矩形@2x.png") no-repeat center;
    background-size: 100% 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    box-sizing: border-box;
    padding: 45px 45px 45px 45px;

    .popup-body {
      width: 100%;
      height: 100%;

      .popup-body-title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 18px;
        color: #E5F9FF;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .popup-body-content {
        height: 166px;
        width: 100%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        .img-list {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .img-item {
            width: 132px;
            height: 74px;

            img {
              width: 100%;
              height: 100%;
            }
          }

        }
      }
    }
  }

  .popup-2 {
    top: 100px;
  }

  .popup-3 {
    top: 200px;
  }

  .popup-4 {
    top: 90px;
  }

  .popup-5 {
    top: 130px;

  }


  .map {
    width: 90%;
    height: 700px;
    background: url('../../assets/page2/地图@2x.png') no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;

    .heping-icon {
      width: 42px;
      height: 42px;
      background: url('../../assets/page2/组 3839@2x.png') no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 400px;
      left: 130px;
      z-index: 20;
      cursor: pointer;
    }

    .heping-title {
      cursor: pointer;
      width: 110px;
      height: 24px;
      background: rgba(182, 21, 21, 0.43);
      box-shadow: inset 0px 0px 8px 0px #FF6565;
      border: 1px solid #FF5D5D;
      border-radius: 5px;

      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      font-style: normal;
      text-transform: none;

      position: absolute;
      top: 400px;
      left: 10px;
      z-index: 20;
    }

    .quyu {
      width: 110px;
      height: 66px;
      background: url('../../assets/page2/组 3838@2x(1).png') no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      cursor: pointer;
      z-index: 5;

      .map-title {
        width: 100px;
        height: 20px;
        margin: 0 auto;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 2px;
      }
    }

    .quyu-1 {
      top: 260px;
      left: 50px;
    }

    .quyu-2 {
      top: 360px;
      left: 80px;
    }

    .quyu-3 {
      top: 460px;
      left: 170px;
    }

    .quyu-4 {
      top: 346px;
      left: 300px;
    }

    .quyu-5 {
      top: 390px;
      left: 120px;
    }
  }

}
</style>