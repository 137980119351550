<template>
  <div class="container-box">
    <div class="map">
      <div class="quyu quyu-1" @click="handleClick(1)">
        <div class="map-title">创新大厦</div>
      </div>

      <div class="quyu quyu-2" @click="handleClick(2)">
        <div class="map-title">合生财富广场</div>
      </div>

      <div class="quyu quyu-3" @click="handleClick(3)">
        <div class="map-title">滨江国际</div>
      </div>

      <div class="quyu quyu-4" @click="handleClick(4)">
        <div class="map-title">港澳大厦</div>
      </div>

      <div class="quyu quyu-5" @click="handleClick(5)">
        <div class="map-title">君隆广场</div>
      </div>
      <div class="quyu quyu-6" @click="handleClick(6)">
        <div class="map-title">赛顿中心</div>
      </div>

      <div class="heping-icon"></div>
      <div class="heping-title" @click="handleClick(7)">
        保利国际广场
      </div>
    </div>

    <div class="popup" id="popup" v-if="viewPopup"
      :class="{ 'popup-2': checkIndex == 2, 'popup-3': checkIndex == 3, 'popup-4': checkIndex == 4, 'popup-5': checkIndex == 5, 'popup-6': checkIndex == 6, 'popup-7': checkIndex == 7 }">
      <div class="popup-body">
        <div class="popup-body-title">
          {{ titleData }}
        </div>
        <div class="popup-body-content">
          <div>
            {{ contentText }}
          </div>
          <div class="img-list">
            <div class="img-item">
              <img :src="imgObj[0]" alt="">
            </div>
            <div class="img-item">
              <img :src="imgObj[1]" alt="">
            </div>
            <div class="img-item">
              <img :src="imgObj[2]" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewPopup: false,
      checkIndex: 0,
    }
  },
  computed: {
    titleData() {
      let obj = {
        1: "创新大厦",
        2: "合生财富广场",
        3: "滨江国际",
        4: "港澳大厦",
        5: "君隆广场",
        6: "赛顿中心",
        7: "保利国际广场",
      }
      return obj[this.checkIndex];
    },
    contentText() {
      let obj = {
        1: "创新大厦，位于和平区南市街道南马路11号，是一座集科技、商务于一体的智能化写字楼。大厦提供5A级物业管理服务。周边交通便捷，紧邻地铁2、4号线交汇站，并拥有多个公交线路及上千个停车位。创新大厦汇聚众多科技型公司，是和平区重点项目，也是企业理想的办公选择。",
        2: "合生财富广场，坐落于和平区中心，北安桥两侧，坐拥270度海河商务带景观。该项目为合生创展集团倾力打造，总体量约20万平方米，双塔楼设计，配备完善商业服务。合生财富广场不仅提升了城市形象，更为区域经济发展注入新活力，是天津商业新地标。",
        3: "滨江国际，坐落于和平区滨江道繁华商圈，是城市商业的璀璨明珠。商厦总建筑面积广阔，集购物、休闲、娱乐于一体，拥有多样化的品牌商户和先进的商业设施。其地理位置优越，交通便利，吸引了大量人流与物流，是天津市民及游客的热门消费场所。",
        4: "港澳大厦，位于郑州道18号，是一座现代化的商业地标。大厦集办公、商务于一体，地理位置优越，交通便捷，紧邻地铁与多条公交线路。大厦内部设施完善，办公环境优雅，配备了先进的物业管理服务。周边商业氛围浓厚，餐饮、购物、休闲一应俱全，是企业和商务人士的理想选择。",
        5: "君隆广场，坐落于天津市和平区南京路99号，由御道津旅（天津）发展有限公司倾力打造。广场占地面积广阔，建筑宏伟，绿化率高，环境优雅，是商务办公、休闲娱乐的绝佳选择。周边配套设施完善，涵盖医疗、教育、购物等多元化服务，为居民及办公人士提供便捷舒适的生活体验。",
        6: "赛顿中心，坐落于天津市和平区西康路与成都道交口，是集高端住宅与商务办公于一体的综合社区。社区周边配套完善，涵盖多所知名学校、医院及购物中心，生活便捷。赛顿中心以其优越的地理位置和完善的设施，成为和平区不可多得的优质生活空间。",
        7: "保利国际广场，坐落于天津市和平区福安大街与禄安大街交汇处，地理位置优越，交通四通八达。作为城市商业地标，保利国际广场集商务办公、商业购物、休闲娱乐于一体，外观时尚大气，内部设施完善，是众多企业入驻的首选之地。注与入驻。",
      }
      return obj[this.checkIndex];
    },
    imgObj() {
      let imgarr = [
        [require("../../assets/hp/hp-1.png"), require("../../assets/hp/hp-2.png"), require("../../assets/hp/hp-3.png")],
        [require("../../assets/hp/hp-4.png"), require("../../assets/hp/hp-5.png"), require("../../assets/hp/hp-6.png")],
        [require("../../assets/hp/hp-7.png"), require("../../assets/hp/hp-8.png"), require("../../assets/hp/hp-9.png")],
        [require("../../assets/hp/hp-10.png"), require("../../assets/hp/hp-11.png"), require("../../assets/hp/hp-12.png")],
        [require("../../assets/hp/hp-13.png"), require("../../assets/hp/hp-14.png"), require("../../assets/hp/hp-15.png")],
        [require("../../assets/hp/hp-16.png"), require("../../assets/hp/hp-17.png"), require("../../assets/hp/hp-18.png")],
        [require("../../assets/hp/hp-19.png"), require("../../assets/hp/hp-20.png"), require("../../assets/hp/hp-21.png")],
      ]
      return imgarr[this.checkIndex - 1];
    }
  },
  methods: {
    handleClick(index) {
      this.viewPopup = false;
      if (this.checkIndex == index) {
        this.viewPopup = false;
        this.checkIndex = 0;
      } else {
        this.checkIndex = index;
        this.viewPopup = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-box {
  width: 552px;
  height: 788px;
  position: relative;


  .popup {
    position: absolute;
    width: 503px;
    height: 283px;
    background: url("../../assets/page2/矩形@2x.png") no-repeat center;
    background-size: 100% 100%;
    top: 105px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    box-sizing: border-box;
    padding: 45px 45px 45px 45px;

    .popup-body {
      width: 100%;
      height: 100%;

      .popup-body-title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 18px;
        color: #E5F9FF;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .popup-body-content {
        height: 166px;
        width: 100%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        .img-list {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .img-item {
            width: 132px;
            height: 74px;

            img {
              width: 100%;
              height: 100%;
            }
          }

        }
      }
    }
  }

  .popup-2 {
    top: 70px;
  }

  .popup-3 {
    top: 280px;
  }

  .popup-4 {
    top: 370px;
  }

  .popup-5 {
    top: 360px;
  }

  .popup-6 {
    top: 140px;
  }

  .popup-7 {
    top: 180px;
  }

  .map {
    width: 466px;
    height: 509px;
    background: url('../../assets/hp/和平区@2x.png') no-repeat center;
    background-size: 466px 509px;
    margin: 0 auto;
    margin-top: 70px;
    margin-left: 40px;
    position: relative;

    .heping-icon {
      width: 42px;
      height: 42px;
      background: url('../../assets/page2/组 3839@2x.png') no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 66px;
      left: 77px;
      z-index: 20;
      cursor: pointer;
    }

    .heping-title {
      cursor: pointer;
      width: 110px;
      height: 24px;
      background: rgba(182, 21, 21, 0.43);
      box-shadow: inset 0px 0px 8px 0px #FF6565;
      border: 1px solid #FF5D5D;
      border-radius: 5px;

      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      font-style: normal;
      text-transform: none;

      position: absolute;
      top: 70px;
      left: 120px;
      z-index: 20;
    }

    .quyu {
      width: 110px;
      height: 66px;
      background: url('../../assets/page2/组 3838@2x(1).png') no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      z-index: 5;


      .map-title {
        width: 100px;
        height: 20px;
        margin: 0 auto;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 2px;
      }
    }

    .quyu-1 {
      top: 2px;
      left: -10px;
    }

    .quyu-2 {
      top: -30px;
      left: 80px;
    }

    .quyu-3 {
      top: 180px;
      left: 320px;
    }

    .quyu-4 {
      top: 270px;
      left: 270px;
    }

    .quyu-5 {
      top: 260px;
      left: 170px;
    }

    .quyu-6 {
      top: 370px;
      left: 120px;
    }
  }

}
</style>