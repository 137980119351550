<!-- 人力资源产业业态 -->
<template>
  <div class="box">
    <div class="title">
      <div class="text">天津人力资源服务企业（{{ industryIndexEntity.totalNum }}家）</div>
    </div>
    <div class="content">
      <div class="card">
        <div class="card-item card-item-1">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity.hrOutsourcedNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源服务外包</div>
          <img src="../../assets/page2/icon人力资源服务外包@2x.png">
        </div>
        <div class="card-item card-item-2">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.hrSoftwareNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源管理软件</div>
          <img src="../../assets/page2/icon人力资源管理软件@2x.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-3">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.advertiseNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源招聘</div>
          <img src="../../assets/image/card-icon-3.png" width="68">
        </div>
        <div class="card-item card-item-4">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.headhunterNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">猎头</div>
          <img src="../../assets/image/card-icon-4.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-5">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.hrTrainNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">培训</div>
          <img src="../../assets/image/card-icon-5.png" width="68">
        </div>
        <div class="card-item card-item-6">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.hrEvaluationNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">测评背调</div>
          <img src="../../assets/image/card-icon-6.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-7">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.hrConsultingNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">管理咨询</div>
          <img src="../../assets/image/card-icon-7.png" width="68">
        </div>
        <div class="card-item card-item-8">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.laborRelationConsultingNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">劳动关系</div>
          <img src="../../assets/image/card-icon-8.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-9">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.salaryBenefitsNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">薪酬福利</div>
          <img src="../../assets/image/card-icon-7.png" width="68">
        </div>
        <div class="card-item card-item-10">
          <div class="num">
            <countTo :startVal='0' :endVal='industryIndexEntity?.backgroundCheckNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">电子签</div>
          <img src="../../assets/image/card-icon-8.png" width="68">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
  data() {
    return {
      industryIndexEntity: this.$store.state.page2Data.industryIndexEntity
    }
  },
  watch: {
    '$store.state.page2Data.industryIndexEntity'(newValue, oldVal) {
      this.industryIndexEntity = newValue;
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  width: 316px;
  height: 785px;

  .title {
    width: 310px;
    height: 42px;
    background: url("../../assets/page2/样式07_1_@2x.png") no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    .text {
      height: 16px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 16px;
      font-style: normal;
      text-transform: none;
      position: absolute;
      left: 26px;
      top: 10px;
    }
  }

  .content {
    width: 316px;
    height: 749px;
    border-radius: 0px 0px 0px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 12px;

    .card {
      display: flex;
      margin-bottom: 16px;

      .card-item {
        width: 158px;
        height: 131px;
        background: url("../../assets/page2/人力资源服务外包@2x.png") no-repeat center;
        background-size: 100% 100%;
        text-align: center;
        box-sizing: border-box;

        img {
          width: 69px;
          height: 54px;
        }

        .num {
          font-family: DIN Alternate, DIN Alternate;
          font-weight: 700;
          font-size: 27px;
          line-height: 27px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-top: 12px;

          .unit {
            font-size: 12px;
          }

        }

        .name {
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 14px;
          color: #E5F9FF;
          line-height: 11px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          padding-top: 5px;
          padding-bottom: 8px;
        }

      }

      .card-item-1 {
        color: #47D1FF;
        background: url("../../assets/page2/人力资源服务外包@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-2 {
        color: #EDC279;
        background: url("../../assets/page2/人力资源管理软件@2x(1).png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-3 {
        color: #E3FEBF;
        background: url("../../assets/page2/招聘@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-4 {
        color: #69E6B8;
        background: url("../../assets/page2/猎头@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-5 {
        color: #8392FF;
        background: url("../../assets/page2/人力资源培训@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-6 {
        color: #F292E2;
        background: url("../../assets/page2/人力资源测评@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-7 {
        color: #FF8D9C;
        background: url("../../assets/page2/人力资源管理咨询@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-8 {
        color: #58E2D5;
        background: url("../../assets/page2/劳动关系咨询@2x.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-9 {
        color: #EDDE79;
        background: url("../../assets/page2/人力资源管理软件@2x(1).png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-10 {
        color: #B1D0FF;
        background: url("../../assets/page2/劳动关系咨询@2x(1).png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
</style>