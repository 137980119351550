<template>
  <div class="container">
    <Header :type="3"></Header>
    <Nav></Nav>
    <div class="box-left">
      <Chart1></Chart1>
      <Chart2></Chart2>
    </div>
    <div class="box-centet">
      <HpCenter></HpCenter>
    </div>
    <div class="box-right">
      <Chart3></Chart3>
      <Chart4></Chart4>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Nav from '../components/page2/Nav.vue'
import Chart1 from '../components/page3/Chart1.vue'
import Chart2 from '../components/page3/Chart2.vue'
import Chart3 from '../components/page3/Chart3.vue'
import Chart4 from '../components/page3/Chart4.vue'
import HpCenter from '../components/hp/HpCenter.vue'
import { selectHPQHrDataAnalysis, getCode } from '@/api/index'
export default {
  components: { Header, Nav, Chart1, Chart2, Chart3, Chart4, HpCenter },
  name: 'App',
  data() {
    return {
    }
  },
  created() {
    this.getAllData();
  },
  methods: {
    getAllData() {
      selectHPQHrDataAnalysis({}).then(res => {
        console.log(res.data, ":::::::::::::setPage3Data");
        this.$store.commit('setPage3Data', res.data);
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}

.container {
  width: 100%;
  height: 100%;
  background: url("../assets/page3/bgk.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;

  .box-left {
    width: 680px;
    height: 788px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 180px;
    left: 15px;
  }

  .box-centet {
    width: calc(100vw - 1300px);
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
  }

  .box-right {
    width: 680px;
    height: 788px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 180px;
    right: 15px;
  }
}
</style>
