<template>
  <div class="chart">
    <div class="title">
      求职者学历占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
    <!-- <div class="label">
      <div class="label-1"><span></span>2023年上半年</div>
      <div class="label-2"><span></span>2024年上半年</div>
    </div> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      eventWarning:[]
    }
  },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { educationalStructureDataVo, medianSalaryYoy2 } = newValue;
      this.eventWarning = [
        educationalStructureDataVo.collegeDiplomaBelowPercent,
        educationalStructureDataVo.undergraduateDegreePercent,
        educationalStructureDataVo.masterPercent,
        educationalStructureDataVo.doctorPercent,
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      var data1 = this.eventWarning
      // let data2 =[data1[0]+5]
      let data2 = []
      data1.map((item,index) => {
        data2.push(item+20)
      })
      // data2.push(data1[data1.length-1]+5)
const option = {
    tooltip: {
      trigger: 'item',
      show:false
    },
    legend:{
      show:false
    },
    grid: {
      left: '10%',
      right: '10%',
      top: '15%',
      bottom: '15%'
    },
    xAxis: {
      data: ['专科及以下', '本科', '硕士', '博士'],
      show: true,
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: 'gray',
          opacity: 1
        }
      },
      // lineStyle: {
      //     show: true,
      //     type: 'solid'
      // },
      axisLabel: {
        
        interval: 0,
        textStyle: {
          color: '#fff',
          fontSize: 18
        },
        margin: 20 // 刻度标签与轴线之间的距离。
      }

    },
    yAxis: [{
      splitLine: {
        // show: false,
        lineStyle: {
          type: 'dashed',
          opacity: 0.5
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      min:0,
      max:100,
      splitNumber:5,
      axisLabel: {
        textStyle: {
          color: '#fff',
          fontSize: 16
        }
      }
    },
     {
      show: false,
      name: '{%}',
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        show:false,
        textStyle: { fontSize: 16, color: '#fff' }

      }
    }],
    series: [

      // 下半截柱状图
      {
        name: '求职者学历占比',
        type: 'bar',
        barWidth: 32,
        barGap: '-100%',
        itemStyle: { // lenged文本
          opacity: 1,
          color: function(params) {
            var a = params.name.slice(0, 2)

            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#0088FF' // 0% 处的颜色
            }, {
              offset: 1,
              color: '#47D1FF' // 100% 处的颜色
            }], false)
          }
        },

        data: data1
      },

      {
        'name': '',
        'type': 'pictorialBar',
        'symbolSize': [32, 12],
        'symbolOffset': [0, -6],
        'z': 5,
        itemStyle: {
          opacity: 1,
          color: function(params) {
            var a = params.name.slice(0, 2)

            return '#46D0FF'
          }
        },
        'symbolPosition': 'end',
        'data': data1
      },
      {
        name: '求职者学历占比',
        'z': 9,
        yAxisIndex: 0,
        type: 'line',
        data: data2,
        smooth: true,
        lineStyle: {
          normal: {
            width: 1,
            color: '#FE6D49' // 线条颜色
          },
          borderColor: 'rgba(0,0,0,1)'
        },
        areaStyle: { // 区域填充样式
          normal: {
            // 线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#FF4747'

            },
            {
              offset: 1,
              color: 'rgba(253, 170, 76, 0)'
            }
            ], false)

          }
        }
      }
    ]
  }
  
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>