<template>
  <div class="box-content-3">
    <div class="title">
      求职人员情况
    </div>
    <div class="chart-body">
      <div class="chart-header">
        工作经验分布
      </div>
      <div ref="echart" id="echart" class="echartDiv"></div>

      <div class="label">
        <div class="label-item" v-for="item in data" :key="item.name">
          <span class="iconspan" :style="{ 'backgroundColor': item.itemStyle.color }"></span>
          {{ item.name }}
          <span class="proportion">{{ item.proportion }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as echarts from 'echarts'
import 'echarts-gl' // 3d图表库
Vue.prototype.$echarts = echarts

export default {
  name: 'chart',
  data () {
    return {
      data: [
        {
          value: 28,
          proportion: 0.05,
          name: '10年以上',
          itemStyle: {
            color: "#FF8266",
          }
        }, {
          value: 30,
          proportion: 0.05,
          name: '5-10年',
          itemStyle: {
            color: "#FFCF71",
          },
        }, {
          value: 32,
          proportion: 0.05,
          name: '3-5年',
          itemStyle: {
            color: "#30F4FA",
          },
        }, {
          value: 38,
          proportion: 0.05,
          name: '1-3年',
          itemStyle: {
            color: "#3895FF",
          }
        },
        {
          value: 40,
          proportion: 0.05,
          name: '1年以下',
          itemStyle: {
            color: "#025BF4",
          },
        },

      ]
    }
  },
  watch: {
    '$store.state.hpData.jobSeekerExperience' (newValue, oldVal) {
      const { range1Percent, range2Percent, range3Percent, range4Percent, range5Percent } = newValue;
      console.log("🚀 ~ range1Percent:", range1Percent)
      const total = range1Percent + range2Percent + range3Percent + range4Percent + range5Percent;

      this.data[0].value = range1Percent;
      this.data[0].proportion = range1Percent;
      // this.data[0].proportion = total == 0 ? 0 : (parseFloat(range1Percent / total) * 100).toFixed(2);

      this.data[1].value = range2Percent;
      this.data[1].proportion = range2Percent;
      // this.data[1].proportion = total == 0 ? 0 : (parseFloat(range2Percent / total) * 100).toFixed(2);

      this.data[2].value = range3Percent;
      this.data[2].proportion = range3Percent;
      // this.data[2].proportion = total == 0 ? 0 : (parseFloat(range3Percent / total) * 100).toFixed(2);

      this.data[3].value = range4Percent;
      this.data[3].proportion = range4Percent;
      // this.data[3].proportion = total == 0 ? 0 : (parseFloat(range4Percent / total) * 100).toFixed(2);

      this.data[4].value = range5Percent;
      this.data[4].proportion = range5Percent;
      // this.data[4].proportion = total == 0 ? 0 : (parseFloat(range5Percent / total) * 100).toFixed(2);
      console.log("🚀 ~ this.data:", this.data)

      this.initChartR2()
    }
  },
  methods: {
    initChartR2 () {
      const myChart = echarts.init(this.$refs.echart);

      const option = {
        series: [
          {
            name: 'Radius Mode',
            type: 'pie',
            radius: [20, 70],
            center: ['25%', '50%'],
            roseType: 'radius',
            itemStyle: {
              borderRadius: 5
            },
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: false
              }
            },
            data: this.data
          },
        ]
      };
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.box-content-3 {
  position: relative;
  width: 100%;
  // margin-top: 10px;

  .title {
    width: 329.6px;
    height: 43.5px;
    background: url("../../assets/page2/样式07_1_@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
    line-height: 43.5px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-indent: 28px;
  }

  .chart-body {
    width: 100%;
    height: 250px;
    position: relative;
    box-sizing: border-box;
    padding: 0 15px;

    .label {
      width: 150px;
      height: 200px;
      position: absolute;
      right: 0;
      top: 60px;

      .label-item {
        width: 100%;
        color: #ffffff;
        font-size: 12px;
        margin: 15px;

        .iconspan {
          width: 12px;
          height: 8px;
          display: inline-block;
          margin-right: 5px;
        }

        .proportion {
          color: #47D1FF;
        }
      }
    }


    .chart-header {
      width: 184px;
      height: 32px;
      background: url("../../assets/image/Vector 411@2x.png") no-repeat center;
      background-size: 100% 100%;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: 14px;
      color: #47D1FF;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      line-height: 31px;
      text-indent: 20px;
    }

    .echartDiv {
      margin-top: 0;
      margin-left: 10px;
      width: 100%;
      height: 100%;
    }

  }
}
</style>