<!-- 人员结构 -->
<template>
  <div class="box">
    <div class="title">
      <div class="beijing"></div>
      <div class="text">人员结构</div>
    </div>
    <div class="content">
      <div class="content-left">
        <PostList type="1"></PostList>
        <PostList type="2"></PostList>
        <PostList type="3"></PostList>
      </div>
      <div class="content-right">
        <div class="line-1"></div>
        <div class="line-2"></div>
        <div class="line-3"></div>
        <div class="content-right-title">
          <div class="content-right-title-name">求职人员情况</div>
        </div>
        <div class="content-right-box">
          <Chart1></Chart1>
          <Chart2></Chart2>
        </div>
        <div class="content-right-box">
          <Chart3></Chart3>
          <Chart4></Chart4>
        </div>
        <div class="content-right-box">
          <Chart5></Chart5>
          <Chart6></Chart6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostList from './PostList.vue'
import Chart1 from './Chart1.vue'
import Chart2 from './Chart2.vue'
import Chart3 from './Chart3.vue'
import Chart4 from './Chart4.vue'
import Chart5 from './Chart5.vue'
import Chart6 from './Chart6.vue'
export default {
  components: { PostList, Chart1, Chart2, Chart3, Chart4, Chart5, Chart6 },
}
</script>

<style lang="scss" scoped>
.box {
  width: calc(100% - 326px);
  height: 785px;
  margin-left: 10px;

  .title {
    height: 36px;
    background: url("../assets/image/Vector-2.png") no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    .beijing {
      width: 276px;
      height: 14px;
      background: url("../assets/image/组 3222@2x.png") no-repeat center;
      background-size: 100% 100%;
      margin: 0 auto;
      margin-top: 7px;
    }

    .text {
      width: 192px;
      height: 16px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      position: absolute;
      left: 50%;
      margin-left: -96px;
      top: 8px;
    }
  }

  .content {
    width: 100%;
    height: 749px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    border-radius: 0px 0px 0px 0px;
    display: flex;

    .content-left {
      width: 316px;
      height: 100%;
      position: relative;

      &::after {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        content: "";
        background: url("../assets/image/line-20.png") no-repeat center;
        background-size: 100% 100%;
      }
    }

    .content-right {
      width: calc(100% - 316px);
      height: 100%;
      position: relative;

      .line-1 {
        width: 100%;
        height: 1px;
        position: absolute;
        background: url("../assets/image/line-27.png") no-repeat center;
        background-size: 100% 100%;
        top: 250px;
        left: 0;
      }

      .line-2 {
        width: 100%;
        height: 1px;
        position: absolute;
        background: url("../assets/image/line-27.png") no-repeat center;
        background-size: 100% 100%;
        top: 497px;
        left: 0;
      }

      .line-3 {
        width: 1px;
        height: 100%;
        position: absolute;
        background: url("../assets/image/line-26.png") no-repeat center;
        background-size: 100% 100%;
        top: 0;
        left: 653px;
      }


      .content-right-title {
        height: 42px;
        width: 100%;

        .content-right-title-name {
          width: 218px;
          height: 42px;
          background: url("../assets/image/post-title.png") no-repeat center;
          background-size: 100% 100%;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 18px;
          color: #E5F9FF;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          line-height: 43.5px;
          text-indent: 30px;
        }
      }

      .content-right-box {
        display: flex;
      }
    }
  }
}
</style>