<template>
  <div class="box-content4">
    <ul class="card">
      <el-popover style="background: none;" placement="right" width="503" trigger="click"
        v-for="(item, index) in optionsData1" :key="index" v-model="item.popoverShow" @show="handleShow(item)">
        <div class="popup">
          <div class="popup-body">
            <div class="popup-body-title">
              {{ item.title }}产业链
            </div>
            <div class="popup-body-content">
              <div class="list-header">
                <div class="label">
                  人力资源服务企业名称
                </div>
                <div class="type">
                  服务业态
                </div>
              </div>
              <div class="scrollbox" v-if="item.isshow">
                <vue-seamless-scroll :data="dataList" style="height: 140px;overflow: hidden;"
                  :class-option="optionSetting">
                  <div class="scrollitem" :class="{ 'scrollitem-bg': index % 2 != 0 }" v-for="(item, index) in dataList"
                    :key="index">
                    <div class="label">
                      {{ item.name }}
                    </div>
                    <div class="type">
                      {{ item.type }}
                    </div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
        <li class="card-item" slot="reference" :style="{
          background: `url(${item.icon}) no-repeat center`
        }">
          <div class="text"> {{ item.title }}</div>
        </li>
      </el-popover>
    </ul>
    <ul class="card">
      <el-popover style="background: none;" placement="right" width="503" trigger="click"
        v-for="(item, index) in optionsData2" :key="index" @show="handleShow(item)">
        <div class="popup">
          <div class="popup-body">
            <div class="popup-body-title">
              {{ item.title }}产业链
            </div>
            <div class="popup-body-content">
              <div class="list-header">
                <div class="label">
                  人力资源服务企业名称
                </div>
                <div class="type">
                  服务业态
                </div>
              </div>
              <div class="scrollbox" v-if="item.isshow">
                <vue-seamless-scroll :data="dataList" style="height: 140px;overflow: hidden;"
                  :class-option="optionSetting">
                  <div class="scrollitem" :class="{ 'scrollitem-bg': index % 2 != 0 }" v-for="(item, index) in dataList"
                    :key="index">
                    <div class="label">
                      {{ item.name }}
                    </div>
                    <div class="type">
                      {{ item.type }}
                    </div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
        <li class="card-item" slot="reference" :style="{
          background: `url(${item.icon}) no-repeat center`
        }">
          <div class="text"> {{ item.title }}</div>
        </li>
      </el-popover>
    </ul>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      optionsData1: [
        {
          title: "轻纺",
          icon: require("../../assets/page1/icon_1.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "中医药",
          icon: require("../../assets/page1/icon_2.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "车联网",
          icon: require("../../assets/page1/icon_3.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "新能源汽车",
          icon: require("../../assets/page1/icon_4.png"),
          popoverShow: false,
          isshow: false,
        }, {
          title: "航空航天",
          icon: require("../../assets/page1/icon_5.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "集成电路",
          icon: require("../../assets/page1/icon_6.png"),
          popoverShow: false,
          isshow: false,
        },

      ],
      optionsData2: [
        {
          title: "生物医药",
          icon: require("../../assets/page1/icon_7.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "新能源",
          icon: require("../../assets/page1/icon_8.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "新材料",
          icon: require("../../assets/page1/icon_9.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "绿色石化",
          icon: require("../../assets/page1/icon_10.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "信创",
          icon: require("../../assets/page1/icon_11.png"),
          popoverShow: false,
          isshow: false,
        },
        {
          title: "高端装备",
          icon: require("../../assets/page1/icon_12.png"),
          popoverShow: false,
          isshow: false,
        }
      ],
      dataList: [
        { name: "北京外企人力资源服务天津有限公司", type: "全板块" },
        { name: "上海外服（天津）人力资源有限公司", type: "全板块" },
        { name: "北京网聘信息技术有限公司天津分公司", type: "全板块" },
        { name: "中智天津项目外包服务有限公司", type: "全板块" },
        { name: "天津市经职人力资源顾问有限公司", type: "传统业态" },
        { name: "天津前锦众程人力资源有限公司", type: "传统业态" },
        { name: "天津优成人力资源服务有限公司", type: "传统业态" },
        { name: "天津市高博人才开发有限公司", type: "传统业态" },
        { name: "天津俊途企业管理咨询有限公司", type: "传统业态" },
        { name: "耘鼎（天津）服务外包有限公司", type: "传统业态" },
        { name: "天津市金诺人力资源开发有限公司", type: "传统业态" },
        { name: "天津优成人力资源服务有限公司", type: "传统业态" },
        { name: "天津市大和劳务服务有限责任公司", type: "传统业态" },
        { name: "天津中普劳务服务有限公司", type: "传统业态" },
        { name: "天津外服国际人力资源（集团）有限公司", type: "传统业态" },
        { name: "仲恒（天津）国际人力资源有限公司", type: "高端业态" },
        { name: "锐仕方达（天津）企业管理咨询有限公司", type: "高端业态" },
        { name: "一合（天津）人力资源集团有限公司", type: "高端业态" },
        { name: "天津人瑞人力资源服务有限公司", type: "高端业态" },
        { name: "魔方灵工（成都）人力资源服务有限公司天津分公司", type: "高端业态" },
        { name: "北京科锐国际人力资源股份有限公司天津分公司", type: "高端业态" },
        { name: "甄程（天津）信息技术有限公司", type: "高端业态" },
        { name: "天津市高博人才开发有限公司", type: "高端业态" },
        { name: "天津优才科技有限公司", type: "高端业态" },
        { name: "天津鉴通企业管理咨询服务有限公司", type: "高端业态" },
        { name: "天津人瑞人力资源服务有限公司", type: "高端业态" },
        { name: "天津市经职人力资源顾问有限公司", type: "高端业态" },
        { name: "天津市众欣石油工程技术服务有限公司", type: "高端业态" },
        { name: "锐仕方达（天津）企业管理咨询有限公司", type: "高端业态" },
        { name: "方胜灵工科技（天津）有限公司", type: "高端业态" },
        { name: "天津邦芒服务外包有限公司", type: "高端业态" },
      ],
      isshow: false,
    }
  },
  computed: {
    //控制滚动属性
    optionSetting() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.dataList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        autoPlay: true, // 是否自动滚动
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  methods: {
    handleShow(scope) {
      scope.isshow = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.box-content4 {
  width: 100%;

  .card {
    width: 100%;
    list-style: none;
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    justify-content: space-around;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 30px;


    .card-item {
      width: 94px;
      height: 68px;
      background-size: 100% 100%;

      .text {
        color: #E5F9FF;
        font-size: 14px;
        text-align: center;
        margin-top: -10px;
      }
    }



  }
}

.popup {
  width: 503px;
  height: 273px;
  background: url("../../assets/page2/矩形@2x.png") no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 45px 45px 45px 45px;

  .popup-body {
    width: 100%;
    height: 100%;

    .popup-body-title {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 14px;
      color: #E5F9FF;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-align: center;
      margin-bottom: 10px;
    }

    .popup-body-content {
      height: 166px;
      width: 100%;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      .list-header {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        display: flex;
        text-align: center;
        background: rgba(0, 114, 200, 0.25);

        .label {
          width: 60%;
        }

        .type {
          width: 40%;
        }
      }

      .scrollbox {
        // width: 100%;
        // height: 140px;
        // overflow: hidden;
        margin-top: 5px;

        .seamless-warp {
          height: 100%;
          overflow: hidden
        }

        .scrollitem {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          display: flex;
          text-align: center;

          .label {
            width: 60%;
            text-align: left;
          }

          .type {
            width: 40%;
            text-align: center;
          }
        }

        .scrollitem-bg {
          background: rgba(0, 114, 200, 0.25);
        }
      }

    }
  }
}
</style>