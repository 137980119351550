<template>
  <div class="header">
    <div class="title" :class="{ 'title-2': type == 2, 'title-3': type == 3, 'title-4': type == 4 }"></div>
    <div class="time">
      <span>{{ time }}</span>
      <span>{{ date }}</span>
      <span>{{ weekObj[week] }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    type: {
      default: 1,
    }
  },
  data() {
    return {
      time: "",
      date: moment().format('YYYY-MM-DD'),
      week: moment().day(),
      weekObj: {
        0: "星期日",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六",
      },
    }
  },
  created() {
    setInterval(() => {
      this.getCurrentTime();
    }, 1000);
  },
  methods: {
    getCurrentTime() {
      this.time = moment().format('HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 91px;
  background: url("../assets/image/header.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;

  .title {
    width: 573px;
    height: 71px;
    background: url("../assets/image/hedaer-title.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }

  .title-2 {
    background: url("../assets/page2/hedaer-title.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }

  .title-3 {
    background: url("../assets/map/hedaer-title.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }

  .title-4 {
    background: url("../assets/page4/hedaer-title.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }


  .time {
    font-size: 20px;
    color: #B1D2EB;
    position: absolute;
    left: 62px;
    top: 12px;

    span {
      margin-right: 10px;
    }
  }


}
</style>