<template>
  <div class="chart">
    <div class="title">
      服务开展情况
    </div>
    <div class="chartbody">
      <div class="echart" ref="echart"></div>
      <div class="chartbody-item">
        <div class="name">劳务派遣</div>
        <div class="box-1">
          <div class="box-item-1">企业数</div>
          <div class="box-item-2"></div>
          <div class="box-item-3">{{ dataArr[2] }}<span>家</span></div>
        </div>
        <div class="box-2"></div>
      </div>
      <div class="chartbody-item">
        <div class="name">高级人才寻访</div>
        <div class="box-1">
          <div class="box-item-1">企业数</div>
          <div class="box-item-2"></div>
          <div class="box-item-3">{{ dataArr[1] }}<span>家</span></div>
        </div>
        <div class="box-2"></div>
      </div>
      <div class="chartbody-item">
        <div class="name">人力资源外包服务</div>
        <div class="box-1">
          <div class="box-item-1">企业数</div>
          <div class="box-item-2"></div>
          <div class="box-item-3">{{ dataArr[0] }}<span>家</span></div>
        </div>
        <div class="box-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

// laborDispatchNum：劳务派遣
// executiveSearchNum：高级人才寻访
// hrosNum：人力资源外包服务

export default {
  data() {
    return {
      dataArr: [0, 0, 0],
      balckBar: [200, 200, 200]
    }
  },
  watch: {
    '$store.state.page3Data.serviceEnterpriseDataVo'(newValue, oldVal) {
      const { laborDispatchNum, executiveSearchNum, hrosNum } = newValue;
      this.dataArr = [laborDispatchNum, executiveSearchNum, hrosNum].reverse();
      console.log(this.dataArr, ":this.dataArr");

      this.initEcharts();
    }
  },
  methods: {
    initEcharts() {
      const myChart = echarts.init(this.$refs.echart);
      let option = {
        "grid": {
          "left": 0,
          "top": 10,
          "bottom": 10,
          "right": 150
        },
        "xAxis": {
          "max": 200,
          "splitLine": {
            "show": false
          },
          "axisLine": {
            "show": false
          },
          "axisLabel": {
            "show": false
          },
          "axisTick": {
            "show": false
          }
        },
        "yAxis": [{
          "type": "category",
          "inverse": false,
          "axisLine": {
            "show": false
          },
          "axisTick": {
            "show": false
          },
          "axisLabel": {
            "margin": 10,
            "textStyle": {
              "color": "#2ad1d2",
              "fontSize": 16
            }
          }
        },

        ],
        "series": [{ //内
          type: 'bar',
          barWidth: 20,
          legendHoverLink: false,
          silent: true,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: '#0AA7FF' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#0AA7FF' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            } //底色
          },
          data: this.dataArr,
          z: 100
        },
        { //外
          type: 'bar',
          barWidth: 20,
          barGap: '-100%',
          legendHoverLink: false,
          silent: true,
          data: this.balckBar,
          itemStyle: {
            color: '#0A3F87',
          },
          z: 98
        },
        { //分隔
          "type": "pictorialBar",
          "animationDuration": 0,
          itemStyle: {
            color: "#053473"
          },
          "symbolRepeat": "fixed",
          "symbolMargin": "8",
          symbol: 'rect',
          "symbolClip": true,
          "symbolSize": [4, 20],
          "symbolPosition": "start",
          "symbolOffset": [
            0, 0
          ],
          // "symbolBoundingData": 5000,
          "data": this.balckBar,
          z: 101
        }
        ]
      }

      myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 394px;
  width: 100%;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    min-height: 300px;
    margin-top: 15px;
    box-sizing: border-box;
    padding-left: 42px;
    position: relative;

    .echart {
      width: 100%;
      height: 330px;
      position: absolute;
      top: 18px;
      left: 80px;
    }

    .chartbody-item {
      width: 594px;
      height: 96px;
      background: url("../../assets/page3/gzrybjt.png") no-repeat center;
      background-size: 100% 100%;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;

      .name {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #47D1FF;
        text-align: left;
        font-style: normal;
        text-transform: none;
        position: absolute;
        top: 3px;
        left: 42px;
      }

      .box-1 {
        width: 515px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 42px;
        margin-top: 40px;

        .box-item-1 {
          width: 100px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
        }

        .box-item-2 {
          width: 100%;
          height: 1px;
          background: url("../../assets/page3/line.png") no-repeat center;
          background-size: 100% 100%;
        }

        .box-item-3 {
          width: 100px;
          text-align: right;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 16px;
          color: #47D1FF;

          span {
            font-size: 12px;
          }
        }


      }
    }
  }
}
</style>