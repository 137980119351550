<template>
  <div class="box-bottom">
    <Industry></Industry>
    <Personnel></Personnel>
  </div>
</template>

<script>
import Industry from './Industry.vue'
import Personnel from './Personnel.vue'
export default {
  components: { Industry, Personnel },
}
</script>

<style lang="scss" scoped>
.box-bottom {
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: -15px;
  display: flex;
}
</style>