<template>
  <div class="post-list">
    <div class="line"></div>
    <div class="title">
      <span v-if="type == 1">岗位需求</span>
      <span v-else-if="type == 2">今日新增岗位</span>
      <span v-else-if="type == 3">今日新增就业数量</span>
    </div>
    <div class="table" v-if="type == 1">
      <div class="thead">
        <div class="thead-item-1">行业</div>
        <div class="thead-item-2">占比</div>
        <div class="thead-item-3">岗位数</div>
      </div>
      <div class="tbody">
        <div class="tbody-item-1">工业企业、现代服务</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVo?.postModernServicesPercent ||
          0 }}%
        </div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVo?.postModernServicesNum || 0'
            :duration='3000'></countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">人工智能大数据</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVo?.postAiBigdataPercent ||
          0 }}%</div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVo?.postAiBigdataNum || 0'
            :duration='3000'></countTo>
        </div>
      </div>
      <div class="tbody">
        <div class="tbody-item-1">企业管理</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVo?.postBusinessManagementPercent ||
          0 }}%
        </div>
        <div class="tbody-item-3">
          <countTo :startVal='0'
            :endVal='$store.state.allData?.postEmploymentIndexDataVo?.postBusinessManagementNum || 0' :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">蓝领职工</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVo?.postBlueCollarPercent ||
          0 }}%</div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVo?.postBlueCollarNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
    </div>
    <div class="table" v-if="type == 2">
      <div class="thead">
        <div class="thead-item-1">行业</div>
        <div class="thead-item-2">占比</div>
        <div class="thead-item-3">岗位数</div>
      </div>
      <div class="tbody">
        <div class="tbody-item-1">工业企业、现代服务</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVoToday?.postModernServicesPercent ||
          0 }}%</div>
        <div class="tbody-item-3">
          <countTo :startVal='0'
            :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.postModernServicesNum || 0' :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">人工智能大数据</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVoToday?.postAiBigdataPercent ||
          0 }}%
        </div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.postAiBigdataNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody">
        <div class="tbody-item-1">企业管理</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVoToday?.postBusinessManagementPercent
          ||
          0 }}%
        </div>
        <div class="tbody-item-3">
          <countTo :startVal='0'
            :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.postBusinessManagementNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">蓝领职工</div>
        <div class="tbody-item-2">{{ $store.state.allData?.postEmploymentIndexDataVoToday?.postBlueCollarPercent ||
          0 }}%
        </div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.postBlueCollarNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
    </div>
    <div class="table" v-if="type == 3">
      <div class="thead">
        <div class="thead-item-1">行业</div>
        <div class="thead-item-2"></div>
        <div class="thead-item-3">人数</div>
      </div>
      <div class="tbody">
        <div class="tbody-item-1">工业企业、现代服务</div>
        <div class="tbody-item-2"></div>
        <div class="tbody-item-3">
          <countTo :startVal='0'
            :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.empModernServicesNum || 0' :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">人工智能大数据</div>
        <div class="tbody-item-2"></div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.empAiBigdataNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">企业管理</div>
        <div class="tbody-item-2"></div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.empBusinessManagementNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
      <div class="tbody tbody-bg">
        <div class="tbody-item-1">蓝领职工</div>
        <div class="tbody-item-2"></div>
        <div class="tbody-item-3">
          <countTo :startVal='0' :endVal='$store.state.allData?.postEmploymentIndexDataVoToday?.empBlueCollarNum || 0'
            :duration='3000'>
          </countTo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
  props: {
    type: {
      default: "1"
    }
  },
  created() {
    console.log(this.type);
  }
}
</script>

<style lang="scss" scoped>
.post-list {
  position: relative;
  margin-bottom: 20px;

  .line {
    width: 100%;
    height: 1px;
    background: url("../assets/image/line-23.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: -21px;
    left: 0;
  }

  .title {
    width: 219.6px;
    height: 43.5px;
    background: url("../assets/image/post-title.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 43.5px;
    text-indent: 30px;
  }

  .table {
    margin-top: 6px;
    box-sizing: border-box;
    padding: 0 15px;

    .thead {
      width: 283px;
      height: 36px;
      background: rgba(0, 114, 200, 0.25);
      display: flex;

      .thead-item-1 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 12px;
        color: #B1D2EB;
        font-style: normal;
        text-transform: none;
        text-indent: 17px;
        line-height: 36px;
      }

      .thead-item-2 {
        width: 25%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 12px;
        color: #B1D2EB;
        font-style: normal;
        text-transform: none;
        line-height: 36px;
        text-align: right;
      }

      .thead-item-3 {
        width: 25%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 12px;
        color: #B1D2EB;
        font-style: normal;
        text-transform: none;
        line-height: 36px;
        text-align: center;
      }
    }

    .tbody-bg {
      background: rgba(0, 114, 200, 0.08);
    }

    .tbody {
      width: 283px;
      height: 36px;
      display: flex;
      line-height: 36px;

      .tbody-item-1 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 36px;
        text-indent: 15px;
      }

      .tbody-item-2 {
        width: 25%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #47D1FF;
        line-height: 36px;
        text-align: right;
      }

      .tbody-item-3 {
        width: 25%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #47D1FF;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}
</style>