<template>
  <div class="chart">
    <div class="title">
      服务人员情况
    </div>
    <div class="chartbody">
      <div class="chart-1">
        <div ref="echart" id="echart" class="echartDiv"></div>
      </div>
      <div class="chart-2">
        <div class="beijing">
          <div class="num">{{ $store.state.page3Data.serviceEnterpriseDataVo.totalNum }}<span>人</span></div>
          <div class="name">工作人员总数</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      optionsData: [
        {
          "name": "本科",
          key: "undergraduateDegreePercent",
          "value": 500,
          itemStyle: {
            color: '#D1C23D',
          }
        },
        {
          "name": "高中及以下",
          key: "vocationalBelowPercent",
          "value": 500,
          itemStyle: {
            color: '#29D9E0',
          }
        },
        {
          "name": "大专",
          key: "collegeDiplomaPercent",
          "value": 500,
          itemStyle: {
            color: '#1285B2',
          }
        },
        {
          "name": "硕士及以上",
          key: "masterPercent",
          "value": 500,
          itemStyle: {
            color: '#1B84EA',
          }
        },
      ]
    }
  },
  watch: {
    '$store.state.page3Data.educationalStructureDataVo'(newValue, oldVal) {
      const { collegeDiplomaPercent, masterPercent, undergraduateDegreePercent, vocationalBelowPercent } = newValue;
      let total = collegeDiplomaPercent + masterPercent + undergraduateDegreePercent + vocationalBelowPercent;
      let benke = parseFloat(undergraduateDegreePercent / total * 100).toFixed(2);
      let gaozhong = parseFloat(vocationalBelowPercent / total * 100).toFixed(2);
      let dazhuan = parseFloat(collegeDiplomaPercent / total * 100).toFixed(2);
      let shuoshi = parseFloat(masterPercent / total * 100).toFixed(2);
      this.optionsData[0].value = parseFloat(benke);
      this.optionsData[1].value = parseFloat(gaozhong);
      this.optionsData[2].value = parseFloat(dazhuan);
      this.optionsData[3].value = parseFloat(shuoshi);
      this.initChart();
    }
  },
  methods: {
    initChart() {
      const myChart = echarts.init(this.$refs.echart);
      const series = this.getPie3D(this.optionsData, 0.8, 240, 28, 26, 0.5);
      series.push({
        name: "pie2d",
        type: "pie",
        label: {
          opacity: 1,
          fontSize: 14,
          lineHeight: 20,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
        },
        labelLine: {
          length: 30,
          length2: 30,
        },
        startAngle: -30, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["40%", "60%"],
        center: ["50%", "50%"],
        data: this.optionsData,
        itemStyle: {
          opacity: 0,
        },
      });
      let option = {
        animation: false,
        title: {
          x: "center",
          top: "20",
          textStyle: {
            color: "#fff",
            fontSize: 22,
          },
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b} \n{d}%",
          textStyle: {
            color: "#fff",
            fontSize: "12px",
          },
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 1,
          //top: '30%',
          bottom: "50%",
          // environment: "rgba(255,255,255,0)",
          viewControl: {
            distance: 180,
            alpha: 25,
            beta: 60,
          },
        },
        series: series,
      };
      myChart.setOption(option);
    },
    getParametricEquation(startRatio,
      endRatio,
      isSelected,
      isHovered,
      k,
      height) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2;

      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== "undefined" ? k : 1 / 3;

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1;

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX +
              Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY +
              Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u);
          }
          return Math.sin(v) > 0 ? 1 * height : -1;
        },
      };
    },
    getPie3D(pieData, internalDiameterRatio) {
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let k =
        typeof internalDiameterRatio !== "undefined"
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;

        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
        };

        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};

          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        console.log(series[i]);
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );

        startValue = endValue;

        legendData.push(series[i].name);
      }
      return series;
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 394px;
  width: 100%;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    margin-top: 40px;

    .chart-1 {
      width: 400px;
      height: 100%;

      .echartDiv {
        width: 100%;
        height: 100%;
      }
    }

    .chart-2 {
      width: 202px;
      height: 202px;
      background: url("../../assets/page3/dong2.gif") no-repeat center;
      background-size: 100% 100%;

      .beijing {
        width: 202px;
        height: 202px;
        background: url("../../assets/page3/dong1.gif") no-repeat center;
        background-size: 130% 130%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 17px;
      }

      .num {
        font-family: DIN Alternate, DIN Alternate;
        font-weight: 700;
        font-size: 32px;
        color: #47D1FF;
        line-height: 36px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 55px;

        span {
          font-size: 16px;
        }
      }

      .name {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
</style>