import request from '@/utils/request'
import http from '@/utils/http'

export function listCarouse(data) {
  return http({
    url: '/system/content/listCarouse?contentType='+data,
    method: 'get',
    data
  })
}
export function getAllData(data = {}) {
  return request({
    url: '/reportData/selectDetailInfoByLastCode',
    method: 'post',
    data
  })
}

export function getCode() {
  return request(
    {
      url: '/reportData/selectLastCode',
      method: 'post',
    }
  )
}

export function getSelect() {
  return request(
    {
      url: '/system/report/companyList',
      method: 'get',
    }
  )
}

export function getTable(data) {
  return request(
    {
      url: '/system/report/getPositionDistributionReportData',
      method: 'get',
      params: data
    }
  )
}

export function selectTJDetailInfoByLastCode(data) {
  return request(
    {
      url: '/reportData/selectTJDetailInfoByLastCode',
      method: 'post',
      data
    }
  )
}

export function selectHPQDetailInfoByLastCode(data) {
  return request(
    {
      url: '/reportData/selectHPQDetailInfoByLastCode',
      method: 'post',
      data
    }
  )
}

export function selectTalentTJDetailInfoByLastCode(data) {
  return request(
    {
      url: '/reportData/selectTalentTJDetailInfoByLastCode',
      method: 'post',
      data
    }
  )
}

export function selectTalentHPQDetailInfoByLastCode(data) {
  return request(
    {
      url: '/reportData/selectTalentHPQDetailInfoByLastCode',
      method: 'post',
      data
    }
  )
}

export function selectHPQHrDataAnalysis(data) {
  return request(
    {
      url: '/reportData/selectHPQHrDataAnalysis',
      method: 'post',
      data
    }
  )
}

export function selectHPQHrMarketMonitor1(data) {
  return request(
    {
      url: '/reportData/selectHPQHrMarketMonitor1',
      method: 'post',
      data
    }
  )
}
export function selectHPQHrMarketMonitor2(data) {
  return request(
    {
      url: '/reportData/selectHPQHrMarketMonitor2',
      method: 'post',
      data
    }
  )
}

export function selectTalentDetailInfoByLastCode(data) {
  return request(
    {
      url: '/reportData/selectTalentDetailInfoByLastCode',
      method: 'post',
      data
    }
  )
}