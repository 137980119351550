<template>
  <div class="chart">
    <div class="chart-body">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
    <div class="label">
      <div class="label-1"><span></span>2023年上半年</div>
      <div class="label-2"><span></span>2024年上半年</div>
    </div>
    <div v-if="show" class="tips">
      求人倍率=职位总数/人才总数
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      yData: [0, 0, 0, 0, 0, 0, 0],
      yData2: [0, 0, 0, 0, 0, 0, 0],
      show:false
    }
  },
  watch: {
    '$store.state.hpData'(newValue, oldVal) {
      const { industryRecruitmentRatio1, industryRecruitmentRatio2 } = newValue;
      this.yData = [
        industryRecruitmentRatio1.financialServicePercent,
        industryRecruitmentRatio1.intelligentManufacturingPercent,
        industryRecruitmentRatio1.modernServicePercent,
        industryRecruitmentRatio1.transportationPercent,
        industryRecruitmentRatio1.cultureEntertainmentPercent,
        industryRecruitmentRatio1.artificialIntelligencePercent,
        industryRecruitmentRatio1.medicalHealthPercent
      ];
      this.yData2 = [
        industryRecruitmentRatio2.financialServicePercent,
        industryRecruitmentRatio2.intelligentManufacturingPercent,
        industryRecruitmentRatio2.modernServicePercent,
        industryRecruitmentRatio2.transportationPercent,
        industryRecruitmentRatio2.cultureEntertainmentPercent,
        industryRecruitmentRatio2.artificialIntelligencePercent,
        industryRecruitmentRatio2.medicalHealthPercent
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      let option = {
        grid: {
          left: 20,
          right: "4%",
          bottom: "4%",
          top: "26%",
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签
        },
        xAxis: {
          type: 'category', // category(坐标轴类型)
          // data: ['金融服务', '商贸零售', '人力资源', '航运服务', '文化旅游', '数字信息', '医药健康'],
          data:['金融服务', '现代商贸', '现代服务', '交通运输', '文化旅游', '信息技术', '医疗健康'],
          axisTick: { // 坐标轴刻度相关配置
            show: false // 是否显示坐标轴刻度
          },
          axisLine: { // 坐标轴轴线相关配置
            lineStyle: { // 坐标轴轴线样式
              color: 'rgba(255,255,255,0.15)' // 坐标轴轴线颜色
            }
          },
          axisLabel: { // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 12,
            margin: 20
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            // 网格线
            show: true,
            lineStyle: { //分割线
              color: "rgba(255, 255, 255, 0.10)",
              width: 1,
              type: "solid" //dotted：虚线 solid:实
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
            
          },
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar", // pictorialBar(象形柱图)
            // label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            //   show: true, //是否显示标签
            //   position: ['30', '-18'], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            //   color: '#01E4FF',
            //   fontSize: 12
            // },
            barGap: "10%",
            symbolSize: [20, 10], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [-12, 7], // 图形相对于原本位置的偏移
            z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: { // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 这里 offset: 0 1 ,表示从下往上的渐变色
                {
                  offset: 0, // 0%处的颜色
                  color: "rgba(71, 209, 255, 1)"
                },
                {
                  offset: 1, // 100%处的颜色
                  color: "rgba(71, 209, 255, 1)"
                }
              ])
            },
            data: this.yData
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: 'bar', // 柱状图
            barWidth: 20, // 柱条的宽度,不设时自适应
            barGap: '20%', // 柱子与柱子之间的距离
            itemStyle: { // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: {
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "type": "linear",
                "global": false,
                "colorStops": [{
                  "offset": 0, // 0%处的颜色
                  "color": "rgba(0, 136, 255, 1)"
                }, {
                  "offset": 1, // 100%处的颜色
                  "color": "rgba(71, 209, 255, 1)"
                }]
              }
            },
            data: this.yData
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            name: '2023上半年',
            type: "pictorialBar",
            symbolSize: [20, 10],
            symbolOffset: [-12, -6],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(31,155,255,1)"
                },
                {
                  offset: 1,
                  color: "rgba(0,229,255,1)"
                }
              ], false)
            },
            data: this.yData
          },

          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar", // pictorialBar(象形柱图)
            // label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            //   show: true, //是否显示标签
            //   position: ['58', '-18'], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            //   color: '#01E4FF',
            //   fontSize: 12
            // },
            symbolSize: [20, 10], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [12, 7], // 图形相对于原本位置的偏移
            z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: { // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 这里 offset: 0 1 ,表示从下往上的渐变色
                {
                  offset: 0, // 0%处的颜色
                  color: "rgba(255,247,123,1)"
                },
                {
                  offset: 1, // 100%处的颜色
                  color: "rgba(255,247,123,1)"
                }
              ])
            },
            data: this.yData2
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: 'bar', // 柱状图
            barWidth: 20, // 柱条的宽度,不设时自适应
            barGap: '20%', // 柱子与柱子之间的距离
            itemStyle: { // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: {
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "type": "linear",
                "global": false,
                "colorStops": [{
                  "offset": 0, // 0%处的颜色
                  "color": "rgba(253,170,76,1)"
                }, {
                  "offset": 1, // 100%处的颜色
                  "color": "rgba(255,247,123,1)"
                }]
              }
            },
            data: this.yData2
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            name: '2024上半年',
            type: "pictorialBar",
            symbolSize: [20, 10],
            symbolOffset: [12, -6],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 246, 123, 1)"
                },
                {
                  offset: 1,
                  color: "rgba(255, 246, 123, 1)"
                }
              ], false)
            },
            data: this.yData2
          }
        ]
      };
      option && myChart.setOption(option);
      myChart.on('mouseover', this.onChartMouseOver);
      myChart.on('mouseout', this.onChartMouseOut);
    },
    onChartMouseOver(){
      this.show=true
    },
    onChartMouseOut(){
      this.show=false
    }
  }
}
</script>

<style lang="scss" scoped>
.tips{
  border-radius: 6px;
    color: #FFF;
    background-color: #0088FF;
    position: relative;
    z-index: 999;
    top: -150px;
    display: inline-block;
    padding: 2px 4px;
    left: 390px;
}
.chart {
  width: 100%;
  height: 232px;
  box-sizing: border-box;
  padding: 0 15px;
  overflow: hidden;
  position: relative;

  .label {
    position: absolute;
    top: 0;
    right: 30px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .chart-header {
    width: 184px;
    height: 32px;
    background: url("../../assets/image/Vector 411@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #47D1FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 31px;
    text-indent: 20px;
  }

  .chart-body {
    display: flex;
    box-sizing: border-box;
    height: 240px;
    margin-top: -30px;

    .echartDiv {
      width: 100%;
      height: 100%;
    }

  }
}
</style>