<template>
  <div class="chart">
    <div class="title">
      求职者期望薪资占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data () {
    return {
      eventWarning: [],
    }
  },
  watch: {
    '$store.state.page4Data'(newValue, oldVal) {
      const { salaryStructureDataVo, medianSalaryYoy2 } = newValue;
      this.eventWarning = 
        [salaryStructureDataVo.range1Percent,
        salaryStructureDataVo.range2Percent,
        salaryStructureDataVo.range3Percent,
        salaryStructureDataVo.range4Percent,
        salaryStructureDataVo.range5Percent,
        salaryStructureDataVo.range6Percent,
        ],
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar () {
      const myChart = echarts.init(this.$refs.echart);

      var ydata = [
        {
          name: '6000元以下',
          value: this.eventWarning[0]
        },
        {
          name: '6000～8000元',
          value: this.eventWarning[1]
        },
        {
          name: '8000～10000元',
          value: this.eventWarning[2]
        },
        {
          name: '10000～15000元',
          value: this.eventWarning[3]
        },
        {
          name: '15000～25000元',
          value: this.eventWarning[4]
        },
        {
          name: '25000元以上',
          value: this.eventWarning[5]
        },
      ]
      const total= ydata.reduce((p,i)=>{
        return p+i.value
      },0)
      var color = ['#308DFB', '#41DCFD', '#FC9F72', '#FDD970', '#B476FA', '#7CFFB2',]
      var xdata = ['5000元以下', '5000～8000元', '8000～11000元', '11000～14000元', '14000～17000元', '17000元以上']

      const option = {
        color: color,
        title: {
          text: '期望薪资',
          top: '43%',
          left:'23%',
          textStyle: {
            color: '#fff',
            fontSize: 17,
            fontWeight: 400
          },
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: '5%',
          itemGap: 20,
          itemWidth: 16,
          itemHeight: 16,
          textStyle: {
            color: '#fff',
            fontSize: 16,
            rich: {
              a: {
                color: '#47D1FF',
                lineHeight: 18,
                fontSize: 16,
              },
              b: {
                width: 120
              }
            }
          },
          data: ['6000元以下', '6000～8000元', '8000～10000元', '10000～15000元', '15000～25000元', '25000元以上'],
          formatter: (name) => {
            let percentage, value
            const item = ydata.find(item=>item.name==name)

            value=item.value
            percentage = (value / total * 100).toFixed(2)
            const str = [
              '{b|',
              name,
              '}{a|',
              percentage,
              '%}',

            ].join('')
            return str
          },
        },
        series: [
          {
            type: 'pie',
            clockwise: false, // 饼图的扇区是否是顺时针排布
            minAngle: 2, // 最小的扇区角度（0 ~ 360）
            radius: ['70%', '90%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: { // 图形样式
              normal: {
                borderColor: '#053473',
                borderWidth: 2
              }
            },
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: '{text|{b}}\n{c} ({d}%)',
                rich: {
                  text: {
                    color: '#666',
                    fontSize: 14,
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: 8
                  },
                  value: {
                    color: '#8693F3',
                    fontSize: 24,
                    align: 'center',
                    verticalAlign: 'middle'
                  }
                }
              },
              emphasis: {
                show: false,
                textStyle: {
                  fontSize: 24
                }
              }
            },
            data: ydata
          },
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            center: ['30%', '50%'],
            pointer: {
              show: false
            },
            splitNumber: 100,
            axisLine: {
              lineStyle: {
                width: 0,
                splitLine: 12,
                color: [
                  [1, 'transparent'],
                ]
              }
            },
            splitLine: {
              show: true,
              distance: 22,
              length: 12,
              lineStyle: {
                color: '#919eaa',
                width: 1
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 500
            },
            // title: {
            //   fontSize: 12,
            //   show: true,
            // },
          }, {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            center: ['30%', '50%'],
            pointer: {
              show: false
            },
            splitNumber: 50,
            axisLine: {
              lineStyle: {
                width: 0,
                splitLine: 112,
                color: [
                  [1, 'transparent'],
                ]
              }
            },
            splitLine: {
              show: true,
              distance: 15,
              length: 1,
              lineStyle: {
                color: '#819eaa',
                width: 1
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 5000
            },
          },
        ]
      }
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 280px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>