import { render, staticRenderFns } from "./BoxContent2.vue?vue&type=template&id=0d72b018&scoped=true"
import script from "./BoxContent2.vue?vue&type=script&lang=js"
export * from "./BoxContent2.vue?vue&type=script&lang=js"
import style0 from "./BoxContent2.vue?vue&type=style&index=0&id=0d72b018&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d72b018",
  null
  
)

export default component.exports