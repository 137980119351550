<template>
  <div class="box-content-2">
    <div class="title-card">
      <div :class="{ 'active': active == 1 }" class="title-card-item" @click="handleClick(1)">岗位需求占比</div>
      <div :class="{ 'active': active == 2 }" class="title-card-item" @click="handleClick(2)">求职人员占比</div>
    </div>
    <div class="table">
      <div class="thead">
        <div class="thead-item-1">行业</div>
        <div class="thead-item-2" v-if="active == 1">岗位需求占比（年度）</div>
        <div class="thead-item-2" v-else-if="active == 2">求职人员占比（年度）</div>
      </div>
      <div style="cursor: pointer;">
        <div class="tbody" :class="{ 'tbody-bg': index % 2 != 0 }" v-for="(item, index) in list" :key="item.key">
          <div class="tbody-item-1">{{ item.label }}</div>
          <div class="tbody-item-2">
            {{ item.value || 0 }} %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      list: [
        {
          key: "informationTechnologyPercent",
          label: '信息技术',
          value: 0,
        },
        {
          key: "financialServicePercent",
          label: '金融服务',
          value: 0,
        },
        {
          key: "medicalHealthPercent",
          label: '医疗健康',
          value: 0,
        },
        {
          key: "educationalConsultationPercent",
          label: '教育咨询',
          value: 0,
        },
        {
          key: "intelligentManufacturingPercent",
          label: '智能制造',
          value: 0,
        },
        {
          key: "cultureEntertainmentPercent",
          label: '文化娱乐',
          value: 0,
        },
        {
          key: "energyMaterialsPercent",
          label: '能源材料',
          value: 0,
        },
        {
          key: "architecturalEngineeringPercent",
          label: '建筑工程',
          value: 0,
        },
        {
          key: "transportationPercent",
          label: '交通运输',
          value: 0,
        },
        {
          key: "modernServicePercent",
          label: '现代服务',
          value: 0,
        },
        {
          key: "newsMediaPercent",
          label: '汽车能源',
          value: 0,
        },
        {
          key: "artificialIntelligencePercent",
          label: '现代商贸',
          value: 0,
        }
      ]
    }
  },
  watch: {
    '$store.state.page2Data'(newValue, oldVal) {
      this.handleInitData();
    }
  },
  computed: {
    formData() {
      if (this.active == 1) {
        return this.$store.state.page2Data.postNumCount;
      } else if (this.active == 2) {
        return this.$store.state.page2Data.jobSeekerNumCount;
      } else {
        return {};
      }
    }
  },
  methods: {
    handleClick(active) {
      this.active = active;
      this.handleInitData();
    },
    handleInitData() {
      let formData = this.formData;
      this.list.forEach(item => {
        let key = item.key;
        item['value'] = formData[key]
      })
      this.list.sort(function (a, b) {
        return b.value - a.value;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.box-content-2 {
  position: relative;
  width: 100%;
  margin-top: 8px;

  .title-card {
    width: 330px;
    height: 54px;
    display: flex;
    justify-content: center;

    .title-card-item {
      background: #053473;
      border: 1px solid rgba(9, 166, 251, 0.4);
      width: 165px;
      height: 100%;
      text-align: center;
      line-height: 54px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: 20px;
      color: rgba(229, 249, 255, 0.8);
      text-align: center;
      font-style: normal;
      text-transform: none;
      cursor: pointer;

      &:nth-child(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none;
      }

      &:nth-child(2) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: none;
      }
    }

    .title-card-item.active {
      color: #47D1FF;
      background: linear-gradient(360deg, #0C58A9 0%, #064FA3 100%);
    }



  }

  .table {
    margin-top: 6px;
    box-sizing: border-box;

    .thead {
      width: 330px;
      height: 54px;
      background: rgba(0, 114, 200, 0.25);
      display: flex;

      .thead-item-1 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 14px;
        color: #B1D2EB;
        font-style: normal;
        text-transform: none;
        text-indent: 17px;
        line-height: 54px;
      }

      .thead-item-2 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        font-size: 14px;
        color: #B1D2EB;
        font-style: normal;
        text-transform: none;
        line-height: 54px;
        text-align: right;
        padding-right: 15px;

      }

    }

    .tbody-bg {
      background: rgba(0, 114, 200, 0.08);
    }

    .tbody {
      width: 330px;
      height: 54px;
      display: flex;
      line-height: 54px;

      .tbody-item-1 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 54px;
        text-indent: 15px;
      }

      .tbody-item-2 {
        width: 50%;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 14px;
        color: #47D1FF;
        line-height: 54px;
        text-align: right;
        padding-right: 20px;
      }
    }
  }
}
</style>