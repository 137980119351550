<template>
  <div class="chart">
    <div class="title">
      七大产业繁荣指数
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
const colors = [
  {
    'left': 'rgba(255, 72, 72, .16)',
    'right': 'rgba(255, 72, 72, .6)',
    'top': 'rgba(255, 72, 72, 1)',
    'bottom': 'rgba(255, 72, 72, .46)',
    'front': 'rgba(255, 72, 72, .66)',
  },
  {
    'left': 'rgba(254, 51, 186, .16)',
    'right': 'rgba(254, 51, 186, .6)',
    'top': 'rgba(254, 51, 186, 1)',
    'bottom': 'rgba(254, 51, 186, .46)',
    'front': 'rgba(254, 51, 186, .66)',
  },
  {
    'left': 'rgba(203, 51, 254, .16)',
    'right': 'rgba(203, 51, 254, .6)',
    'top': 'rgba(203, 51, 254, 1)',
    'bottom': 'rgba(203, 51, 254,  .46)',
    'front': 'rgba(203, 51, 254, .66)',
  },
  {
    'left': 'rgba(51, 92, 254, .16)',
    'right': 'rgba(51, 92, 254, .6)',
    'top': 'rgba(51, 92, 254, 1)',
    'bottom': 'rgba(51, 92, 254, .46)',
    'front': 'rgba(51, 92, 254, .66)',
  },
  {
    'left': 'rgba(46, 237, 237, .16)',
    'right': 'rgba(46, 237, 237, .6)',
    'top': 'rgba(46, 237, 237, 1)',
    'bottom': 'rgba(46, 237, 237, .46)',
    'front': 'rgba(46, 237, 237, .66)',
  },
  {
    'left': 'rgba(51, 254, 163, .16)',
    'right': 'rgba(51, 254, 163, .6)',
    'top': 'rgba(51, 254, 163, 1)',
    'bottom': 'rgba(51, 254, 163, .46)',
    'front': 'rgba(51, 254, 163, .66)',
  },
  {
    'left': 'rgba(226, 199, 62, .16)',
    'right': 'rgba(226, 199, 62, .6)',
    'top': 'rgba(226, 199, 62, 1)',
    'bottom': 'rgba(226, 199, 62, .46)',
    'front': 'rgba(226, 199, 62, .66)',
  },
]
export default {
  data() {
    return {
      valueList: [
        20, 53, 47, 65, 29, 55, 66
      ],
      xAxisData: ['金融服务', '商贸零售', '人力资源', '航运服务', '文化旅游', '数字信息', '医药健康']
    }
  },
  watch: {
    '$store.state.page4Data.prosperityIndex'(newValue, oldVal) {
      this.valueList = [
        newValue.financialServicePercent,
        newValue.intelligentManufacturingPercent,
        newValue.modernServicePercent,
        newValue.transportationPercent,
        newValue.cultureEntertainmentPercent,
        newValue.artificialIntelligencePercent,
        newValue.medicalHealthPercent
      ];
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      console.log("🚀 ~ this.valueList:", this.valueList)

      const myChart = echarts.init(this.$refs.echart);
      const offsetX = 12;  //bar宽
      const offsetY = 6;   //倾斜角度
      // 绘制左侧面
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          // 会canvas的应该都能看得懂，shape是从custom传入的
          const xAxisPoint = shape.xAxisPoint;
          // console.log(shape);
          const c0 = [shape.x, shape.y];
          const c1 = [shape.x - offsetX, shape.y - offsetY];
          const c2 = [xAxisPoint[0] - offsetX, xAxisPoint[1] - offsetY];
          const c3 = [xAxisPoint[0], xAxisPoint[1]];
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath();
        },
      });
      // 绘制右侧面
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c1 = [shape.x, shape.y];
          const c2 = [xAxisPoint[0], xAxisPoint[1]];
          const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY];
          const c4 = [shape.x + offsetX, shape.y - offsetY];
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
        },
      });
      // 绘制顶面
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x + offsetX, shape.y - offsetY]; //右点
          // const c3 = [shape.x, shape.y - offsetX];
          const c3 = [shape.x, shape.y - offsetY * 2];
          const c4 = [shape.x - offsetX, shape.y - offsetY];
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
        },
      });
      // 注册三个面图形
      echarts.graphic.registerShape('CubeLeft', CubeLeft);
      echarts.graphic.registerShape('CubeRight', CubeRight);
      echarts.graphic.registerShape('CubeTop', CubeTop);
      let xAxisData = this.xAxisData;
      let colorArr = [["#12D5AF"], ["#0BC19D", "rgba(13,8,16,0)"], ["#68EFD4", "rgba(14,185,151,0)"]]
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params, ticket, callback) {
            const item = params[1];
            return item.name + ' : ' + item.value;
          },
        },
        grid: {
          left: '4%',
          right: '4%',
          top: '15%',
          bottom: '2%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false,
            lineStyle: {
              width: 2,
              color: '#2B7BD6',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#fff",
            margin: 15,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              width: 2,
              color: '#fff',
            },
          },
          splitLine: {
            // 网格线
            show: true,
            lineStyle: { //分割线
              color: "rgba(255, 255, 255, 0.10)",
              width: 1,
              type: "solid" //dotted：虚线 solid:实
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 12,
          },
          // boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)]);
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: colors[params.dataIndexInside]['top']
                      // fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //   {
                      //     offset: 0,
                      //     color: colorArr[1][0],
                      //   },
                      //   {
                      //     offset: 1,
                      //     color: colorArr[1][1],
                      //   },
                      // ]),
                    },
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: colors[params.dataIndexInside]['top']
                      // fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //   {
                      //     offset: 0,
                      //     color: colorArr[2][0],
                      //   },
                      //   {
                      //     offset: 1,
                      //     color: colorArr[2][1],
                      //   },
                      // ]),
                    },
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: colors[params.dataIndexInside]['top']
                      // fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //   {
                      //     offset: 0,
                      //     color: colorArr[0][0],
                      //   },
                      //   {
                      //     offset: 1,
                      //     color: colorArr[0][0],
                      //   },
                      // ]),
                    },
                  },
                ],
              };
            },
            data: this.valueList.map(item=>{
              return item-0.15
            }),
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: (e) => {
                  // return e.value + '次';
                  return e.value;
                },
                fontSize: 12,
                color: "#fff",
                // offset: [0, -1],
              },
            },
            itemStyle: {
              color: 'transparent',
            },
            tooltip: {},
            data: this.valueList,
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 100%;
    }
  }
}
</style>