import { render, staticRenderFns } from "./Chart3.vue?vue&type=template&id=2f80abce&scoped=true"
import script from "./Chart3.vue?vue&type=script&lang=js"
export * from "./Chart3.vue?vue&type=script&lang=js"
import style0 from "./Chart3.vue?vue&type=style&index=0&id=2f80abce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f80abce",
  null
  
)

export default component.exports