<template>
  <div class="chart">
    <div class="title">
      求职者经验占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      eventWarning: [],
      eventHandling: []
    }
  },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { jobSeekerExperience, jobseekerYoy2 } = newValue;
      this.eventWarning = [
        jobSeekerExperience.range1Percent,
        jobSeekerExperience.range2Percent,
        jobSeekerExperience.range3Percent,
        jobSeekerExperience.range4Percent,
        jobSeekerExperience.range5Percent,
      ];
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar () {
      const myChart = echarts.init(this.$refs.echart);
      const data1 =this.eventWarning
      let data2 = []
      data1.map((item,index) => {
        data2.push(+item+10)
      })
      const
        option = {
          tooltip: {
            trigger: 'item',
          },
          grid: {
            left: '10%',
            top: '10%',
            right: '5%',
            bottom: '20%',
          },
          xAxis: [{
            data: ["无经验", "1～3年", "3～5年", "5～10年", "10年以上"],
            axisLabel: {
              textStyle: {
                color: 'rgba(122,173,212,1)',
                fontSize: 14
              },
              margin: 30, //刻度标签与轴线之间的距离。
            },

            axisLine: {
              show: true, //不显示x轴
              lineStyle: {
                color: 'rgba(53,65,95,1)',
              }
            },
            axisTick: {
              show: false //不显示刻度
            },
            boundaryGap: true,
            splitLine: {
              show: false
            }
          }],
          yAxis: [{
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(53,65,95,1)',
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: 'rgba(122,173,212,1)',
                fontSize: 14
              },
            }
          }],
          series: [
          {
              name: '',
              'z': 19,
              yAxisIndex: 0,
              type: 'line',
              data: data2,
              lineStyle: {
                normal: {
                  width: 1,
                  color: 'rgba(219, 177, 104, 1)' // 线条颜色
                },
                borderColor: 'rgba(0,0,0,1)'
              },
            },
            {//柱底圆片
              name: "",
              type: "pictorialBar",
              symbolSize: [20, 15],//调整截面形状
              symbolOffset: [0, 0],
              barWidth: 20,
              z: 12,
              itemStyle: {
                "normal": {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(163, 242, 255, 1)"
                  },
                  {
                    offset: 1,
                    color: "rgba(163, 242, 255, 1)"
                  }
                  ], false)
                }
              },
              data:data1
            },
            //柱体
            {
              name: "",
              type: "pictorialBar",
              barWidth: 20,
              z:20,
              symbolOffset: [0, -10],
              symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
              itemStyle: {
                normal: {
                  //渐变色
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(163, 242, 255, .4)"
                  },
                  {
                    offset: 1,
                    color: "rgba(91, 233, 255, .8)"
                  }
                  ])
                }
              },
              data: data1
            },
            
          ]
        };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>