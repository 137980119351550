<template>
  <div class="container-box">
    <div class="content-right-title">
      <div class="content-right-title-name">服务产业链</div>
    </div>
    <div class="content-right-box">
      <BoxContent4></BoxContent4>
      <div class="content-right-title">
        <div class="content-right-title-name">求职人员情况</div>
      </div>
      <BoxContent5></BoxContent5>
      <BoxContent6></BoxContent6>
    </div>
  </div>
</template>

<script>
import BoxContent4 from './BoxContent4.vue'
import BoxContent5 from './BoxContent5.vue'
import BoxContent6 from './BoxContent6.vue'
export default {
  components: { BoxContent4, BoxContent5, BoxContent6 }

}
</script>

<style lang="scss" scoped>
.container-box {
  width: 680px;
  height: 788px;
  background: #053473;
  box-shadow: inset 0px 0px 20px 0px #085ABB;

  .content-right-box {
    display: flex;
    flex-direction: column;
  }

  .content-right-title {
    height: 42px;
    width: 100%;

    .content-right-title-name {
      width: 218px;
      height: 42px;
      background: url("../../assets/image/post-title.png") no-repeat center;
      background-size: 100% 100%;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: 18px;
      color: #E5F9FF;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      line-height: 43.5px;
      text-indent: 30px;
    }
  }
}
</style>