<template>
  <div class="chart">
    <div class="chart-header">
      岗位需求数变化趋势
    </div>
    <div class="chart-body">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      list: [
      ],
    }
  },
  watch: {
    '$store.state.allData'(newValue, oldVal) {
      this.getEcharts3DBar(this.$store.state.allData.postChangeDataVo);
    }
  },
  methods: {
    getEcharts3DBar(data) {
      const myChart = echarts.init(this.$refs.echart);
      // 产值
      const zzx1 = data.map(item => {
        return item.postNum == 0 ? null : item.postNum;
      });

      var barWidth = 20;

      let option = {
        grid: {
          left: "2%",
          right: "4%",
          bottom: "4%",
          top: "26%",
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签
        },
        xAxis: {
          type: "category",
          data: data.map(item => item.yearMonth),
          axisTick: { // 坐标轴刻度相关配置
            show: false // 是否显示坐标轴刻度
          },
          axisLine: { // 坐标轴线的样式
            show: false,
            lineStyle: {
              color: "#99ffff",
            },
          },
          axisLabel: { // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 12,
            margin: 10
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              color: "#5BE9FF",
            },
          },
          splitLine: {
            // 网格线
            show: true,
            lineStyle: { //分割线
              color: "rgba(255, 255, 255, 0.10)",
              width: 1,
              type: "solid" //dotted：虚线 solid:实
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#B1D2EB",
              fontSize: 12,
            },
          },
        },
        series: [
          // 产值中间正方形
          {
            type: "pictorialBar",
            symbol: "diamond",
            symbolSize: [barWidth, 8],
            symbolOffset: [5.5, -4.5],
            symbolPosition: "end",
            z: 12,
            data: zzx1,
            itemStyle: { // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 这里 offset: 0 1 ,表示从下往上的渐变色
                {
                  offset: 0, // 0%处的颜色
                  color: "rgba(71, 209, 255, 1)"
                },
                {
                  offset: 1, // 100%处的颜色
                  color: "rgba(71, 209, 255, 1)"
                }
              ])
            },
          },
          // 产值进度柱子
          {
            name: "计划产值",
            type: "bar",
            barWidth: barWidth,
            stack: "1",
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0, 246, 236, 0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(91, 233, 255, 1)' // 100% 处的颜色
                  }
                ]
              }
            },
            label: {
              show: true,
              position: ["0", "-34"],
              color: "#fff",
              fontSize: 12,
              formatter: function (params) {
                console.log(params, data);
                const { name, dataIndex } = params
                const value = params.data
                const arr = [
                  data[dataIndex].postPercent,
                  '%\n{a|',
                  value,
                  "}"
                ]
                return arr.join('')
              },
              rich: {
                a: {
                  color: '#47D1FF',
                  lineHeight: 18,
                  fontSize: 12,
                },
              }
            },
            data: zzx1,
          },
          // 产值进度柱子
          {
            show: false,
            name: "中间的线",
            type: "bar",
            barWidth: 0.5,
            barGap: "-50%",
            itemStyle: {
              normal: {
                opacity: 0.7,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0, 246, 236, 0)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(91, 233, 255, 1)' // 100% 处的颜色
                    }
                  ]
                }
              },
            },
            data: zzx1,
          },
        ],
      };
      option && myChart.setOption(option);
    }

  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 50%;
  height: 232px;
  box-sizing: border-box;
  padding: 0 30px;
  overflow: hidden;
  padding-left: 45px;

  .chart-header {
    width: 184px;
    height: 32px;
    background: url("../assets/image/Vector 411@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #47D1FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 31px;
    text-indent: 20px;
  }

  .chart-body {
    display: flex;
    height: 160px;

    .echartDiv {
      margin-top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>