<template>
  <div  >
    <el-carousel height="1000px"  >
      <el-carousel-item v-for="item in 7" :key="item">
        <div v-if="item == 1" class="item">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2">
                <p><span>国家级人力资源服务产业园</span></p>
              </div>
              <div class="text u3">
                <p style="font-size:30px;"><span>中国天津人力资源服务产业园于2015年起步建设，</span></p>
                <p style="font-size:30px;"><span>2016年认定为市级产业园，</span></p>
                <p style="font-size:30px;"><span>2018年10月获批为国家级园区，</span></p>
                <p style="font-size:30px;"><span>2019年5月中国天津人力资源服务产业园在和平区正式挂牌。</span></p>
                <p style="font-size:30px;"><span>2023年9月，中国天津人力资源服务产业园和平保利园区挂牌。</span></p>
                <p style="font-size:30px;"><span>经过近10年的实践探索，</span></p>
                <p style="font-size:30px;"><span>逐渐形成“</span><span
                    style="font-size:48px;">政府引导</span><span>、</span><span
                    style="font-size:48px;">国有企业运营</span><span>、</span></p>
                <p style="font-size:30px;"><span style="font-size:48px;">专业化运行</span><span>、</span><span
                    style="font-size:48px;">市场化运作</span><span>”的现代化管理运营模式，</span></p>
                <p style="font-size:30px;"><span>推动园区不断朝着高端化、高质化、高新化方向发展。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home0.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 2" class="item bg">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 200px">
                <p><span>使命担当，高位谋划</span></p>
              </div>
              <div class="u3">
                <p style="font-size:30px;"><span>党的二十大强调</span><span
                    style="font-size:48px;">科技、人才、创新</span><span>重要性，</span></p>
                <p style="font-size:30px;"><span>天津市人力资源服务产业不断开创新局面。</span></p>
                <p style="font-size:30px;"><span>和平园区是中国天津人力资源服务产业园的中心园区，</span></p>
                <p style="font-size:30px;"><span>贯彻党的</span><span style="font-size:48px;">二十大精神</span><span>，践行
                  </span><span style="font-size:48px;">“十项行动”</span><span>，</span></p>
                <p style="font-size:30px;"><span>园区建设规划为</span><span style="font-size:48px;">“一园多点、辐射全区”</span></p>
                <p style="font-size:30px;"><span>的总体空间布局，力争走在全国前列。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home1.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 3" class="item">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 200px">
                <p><span>勇毅前行 踵事增华</span></p>
              </div>
              <div class="text u3">
                <p style="font-size:30px;"><span>在持续的发展建设中，</span></p>
                <p style="font-size:30px;"><span style="font-size:48px;">和平园区</span><span>成果显著，</span></p>
                <p style="font-size:30px;"><span>实现了较好的</span><span style="font-size:48px;">经济</span><span>和</span><span
                    style="font-size:48px;">社会</span><span>效益；</span></p>
                <p style="font-size:30px;"><span>同时，在天津市人力资源产业发展中，</span></p>
                <p style="font-size:30px;"><span>占据有力的地位。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home2.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 4" class="item">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 200px">
                <p><span>全链赋能 惠企聚才</span></p>
              </div>
              <div class="u3 ">
                <p style="font-size:30px;"><span>和平园区坚持以发展</span><span style="font-size:48px;">产业</span><span>、</span>
                </p>
                <p style="font-size:30px;"><span>服务</span><span style="font-size:48px;">企业</span><span>、汇聚</span><span
                    style="font-size:48px;">人才</span><span>为目标，</span></p>
                <p style="font-size:30px;"><span>聚焦产业</span><span style="font-size:48px;">创新</span><span>、企业</span><span
                    style="font-size:48px;">发展</span><span>、人才</span><span
                    style="font-size:48px;">服务</span><span>，</span></p>
                <p style="font-size:30px;"><span>深化园区政策支撑，</span></p>
                <p style="font-size:30px;"><span>助推园区高水平建设发展。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home3.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 5" class="item bg">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 250px">
                <p><span>卓越成就 载誉笃行</span></p>
              </div>
              <div class="u3 ">
                <p style="font-size:30px;"><span>和平园区</span></p>
                <p style="font-size:30px;"><span>坚持</span><span style="font-size:48px;">科技创新</span><span>和</span><span
                    style="font-size:48px;">基础研究</span><span>结合，</span></p>
                <p style="font-size:30px;"><span>也催生了人力资源产业的</span><span
                    style="font-size:48px;">前沿</span><span>成就。</span></p>
              </div>
              <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home4.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 6" class="item">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 200px">
                <p><span>入驻企业</span></p>
              </div>
              <div class="u3 ">
                <p style="font-size:30px;"><span style="font-size:48px;">上市</span><span>公司7家</span></p>
                <p style="font-size:30px;"><span>全国</span><span style="font-size:48px;">总部</span><span>4家</span></p>
                <p style="font-size:30px;"><span>先后引进全国</span><span
                    style="font-size:48px;">知名</span><span>企业智联招聘、前程无忧、</span></p>
                <p style="font-size:30px;"><span>中智天津、人瑞人才、科锐国际、锐仕方达等百强人力资源企业落户和平。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <img src="@/assets/index/home5.jpg" class="img1" alt="">
            </div>
          </div>
        </div>
        <div v-if="item == 7" class="item bg">
          <div class="con">
            <div style="height: 100%;">
              <div class="u2" style="margin-top: 100px">
                <p><span>园区配套服务</span></p>
              </div>
              <div class="u3">
                <p><span>和平园区设置宣传展示区、共享办公区、</span></p>
                <p><span>党建活动区、政务服务区、人才直播间、</span></p>
                <p><span>人才会客室等功能区域。</span></p>
                <p><span><br></span></p>
                <p><span>共享办公区整体面积900多平米，包括100余个</span></p>
                <p><span>共享办公工位、配套多个独立办公区，</span></p>
                <p><span>可用于企业办公也可为企业登记注册，</span></p>
                <p><span>为初创企业节省创业成本。</span></p>
                <p><span><br></span></p>
                <p><span>办公区同时配套有商务洽谈区、咖啡吧、</span></p>
                <p><span>读书角等。</span></p>
                <p><span>读书角购置了专业书籍、工具书籍、政策书籍1200余本，</span></p>
                <p><span>均可用于入园企业共享使用。</span></p>
              </div> <div class="btn">
                <el-button type="danger" @click="handleClick">联系我们</el-button>
              </div>
            </div>
            <div>
              <div class="img2div">
                <img src="@/assets/index/home61.jpg" class="img2" alt="">
                <img src="@/assets/index/home62.jpg" class="img2" alt="">
                <img src="@/assets/index/home63.jpg" class="img2" alt="">
                <img src="@/assets/index/home64.jpg" class="img2" alt="">
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-dialog
    :visible.sync="dialogVisible"
    width="100%"
    custom-class="previewDialog"
    append-to-body
    :title="null">  
      <img src="@/assets/index/contact.png" class="dialog-img" alt="">
  </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    handleClick() {
      this.dialogVisible=true
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-img{
  width: 600px;
  margin: auto;
  position: relative;
  left: -300px;
  border-radius: 20px;
}
.con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &>div {
    width: 50%;
  }
  & ::v-deep .el-button{
    padding: 16px 50px;
    font-size: 20px;
  }
}

.img1 {
  width: 900px;
  height: 650px;
  border-radius: 30px;
}
.img2div{
  width: 900px;
  height: 650px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.img2{
  width: 440px;
  height: 320px;
  border-radius: 10px;
}
.item {
  background: #E6EAF0;
  width: 100%;
  height: 100%;
}

.u2 {
  position: relative;
  margin-top: 150px;
  margin-bottom: 50px;
  left: 66px;
  width: 660px;
  height: 74px;
  font-family: "微软雅黑 Bold", 微软雅黑, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 55px;
  color: rgb(13, 22, 38);
  border-width: 0px;
}

.u3 {
  position: relative;
  left: 66px;
  width: 870px;
  font-family: 微软雅黑, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(143, 153, 174);
  border-width: 0px;
  font-size: 30px;
}

.btn {
  position: relative;
  left: 66px;
  margin-top: 50px;
}

.u3 p {
  line-height: 1.5;
  margin: 0;
}
.bg{
  background: rgba(72, 133, 239, 1);
  .u3{
    color:#FFF;
  }
  .u2{
    color:#FFF;
  }
}
</style>
