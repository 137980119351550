<template>
  <div class="chart">
    <div class="chart-header">
      学历结构
    </div>
    <div class="chart-body">
      <div class="chart-left">
        <div class="chart-dipan"></div>
        <div ref="echart" id="echart" class="echartDiv"></div>
      </div>
      <div class="chart-right">
        <ul class="list">
          <li class="list-item" v-for="item in optionsData" :key="item.itemStyle.color">
            <div class="name"><span class="icon" :style="{ 'backgroundColor': item.itemStyle.color }"></span>{{ item.name }}
            </div>
            <div class="proportion">{{ item.proportion }}%</div>
            <div class="num">{{ item.value }}人</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      optionsData: []
    }
  },
  watch: {
    '$store.state.allData'(newValue, oldVal) {
      this.optionsData = [
        {
          name: "博士",
          proportion: this.$store.state.allData?.educationalStructureDataVo?.doctorPercent || 0,
          value: this.$store.state.allData?.educationalStructureDataVo?.doctorNum || 0,
          itemStyle: {
            color: "#025BF4",
          },
        },
        {
          name: "硕士",
          proportion: this.$store.state.allData?.educationalStructureDataVo?.masterPercent || 0,
          value: this.$store.state.allData?.educationalStructureDataVo?.masterNum || 0,
          itemStyle: {
            color: "#92D6F8",
          },
        },
        {
          name: "大学本科",
          proportion: this.$store.state.allData?.educationalStructureDataVo?.undergraduateDegreePercent || 0,
          value: this.$store.state.allData?.educationalStructureDataVo?.undergraduateDegreeNum || 0,
          itemStyle: {
            color: "#5176FD",
          },
        },
        {
          name: "大学专科",
          proportion: this.$store.state.allData?.educationalStructureDataVo?.collegeDiplomaPercent || 0,
          value: this.$store.state.allData?.educationalStructureDataVo?.collegeDiplomaNum || 0,
          itemStyle: {
            color: "#1DC6A8",
          },
        },
        {
          name: "中专及以下",
          proportion: this.$store.state.allData?.educationalStructureDataVo?.vocationalBelowPercent || 0,
          value: this.$store.state.allData?.educationalStructureDataVo?.vocationalBelowNum || 0,
          itemStyle: {
            color: "#5BBBD1",
          },
        },
      ]
      this.echartInit();
    }
  },
  methods: {
    echartInit() {
      const myChart = echarts.init(this.$refs.echart);
      const series = this.getPie3D(this.optionsData, 0.8);
      series.push({
        labelLine: {
          normal: {
            length: 45,
            length2: 45,
            show: false    // 隐藏所有指示线
          }
        },
        name: "pie2d",
        type: "pie",
        startAngle: -30, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["40%", "60%"],
        center: ["50%", "50%"],
        data: this.optionsData,
        itemStyle: {
          opacity: 0,
        },
        label: {
          show: false,
        },
      });
      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        animation: true,
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 0.5, // 设置立体柱状图的高度
          boxWidth: 100,
          bottom: "50%",
          // environment: "rgba(255,255,255,0)",
          viewControl: {
            distance: 180,
            alpha: 30, // 倾斜角度
            beta: 100, // 旋转角度
            autoRotate: true, // 自动旋转
            rotateSensitivity: 0, // 禁止旋转
            zoomSensitivity: 0, // 禁止缩放
          },
        },
        series: series,
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getParametricEquation(
      startRatio,
      endRatio,
      isSelected,
      isHovered,
      k,
      height) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2;

      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== "undefined" ? k : 1 / 3;

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1;

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u);
          }
          return Math.sin(v) > 0 ? 1 * height : -1;
        },
      };
    },
    getPie3D(pieData, internalDiameterRatio) {
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let k =
        typeof internalDiameterRatio !== "undefined"
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;

        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined" ? `series${i}` : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
        };

        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};

          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        console.log(series[i]);
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );

        startValue = endValue;

        legendData.push(series[i].name);
      }
      return series;
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 50%;
  height: 232px;
  box-sizing: border-box;
  padding: 0 15px;
  overflow: hidden;

  .chart-header {
    width: 184px;
    height: 32px;
    background: url("../assets/image/Vector 411@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #47D1FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 31px;
    text-indent: 20px;
  }

  .chart-body {
    display: flex;

    .chart-left {
      width: 60%;
      height: 200px;
      position: relative;

      .chart-dipan {
        width: 275px;
        height: 140px;
        background: url("../assets/image/chart-1.png") no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        margin-left: -137.5px;
        bottom: 20px;
      }

      .echartDiv {
        margin-top: 0;
        width: 100%;
        height: 200px;
      }
    }

    .chart-right {
      width: 40%;
      height: 200px;

      .list {
        list-style: none;
        font-size: 12px;
        margin: 0;
        padding: 0;
        margin-top: 10px;

        .list-item {
          margin-bottom: 10px;

          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          font-size: 12px;
          display: flex;

          .name {
            width: 100px;
            color: rgba(255, 255, 255, 0.85);

            .icon {
              width: 12px;
              height: 8px;
              display: inline-block;
              margin-right: 5px;
            }
          }

          .proportion {
            width: 50px;
            color: #47D1FF;
          }

          .num {
            width: calc(100% - 150px);
            color: #47D1FF;
          }
        }

      }
    }
  }
}
</style>