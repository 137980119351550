
import axios from 'axios'
const service = axios.create({
  baseURL: '/api/',
  timeout: 1000 * 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['TOKEN'] = 'hrip'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
  //判断code码
  return response.data;
}, error => {
  return Promise.reject(error);
});

export default service
