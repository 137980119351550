<template>
  <div class="chart">
    <div class="title">
      七大产业求职申请率
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";

export default {
  data() {
    return {
      payload: {
        id: '',
        data: {
          unit: ['%'],
          x: ['金融服务', '现代商贸', '现代服务', '交通运输', '文化旅游', '信息技术', '医药健康'],
          data1: [],
        },
      }
    }
  },
  watch: {
    '$store.state.page4Data.jobApplicationMultiple'(newValue, oldVal) {
      this.payload.data.data1 = [
        newValue.financialServicePercent,
        newValue.intelligentManufacturingPercent,
        newValue.modernServicePercent,
        newValue.transportationPercent,
        newValue.cultureEntertainmentPercent,
        newValue.artificialIntelligencePercent,
        newValue.medicalHealthPercent
      ];
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      const unit = this.payload.data.unit || [];
      const x = this.payload.data.x || [];
      const data1 = this.payload.data.data1 || [];
      const title = this.payload.data.title || [];
      const option = {
        grid: {
          top: 35,
          left: 15,
          right: 15,
          bottom: 10,
          // 是否包含文本
          containLabel: true,
        },
        tooltip: {
          // 触发类型  经过轴触发axis  经过轴触发item
          trigger: 'axis',
          backgroundColor: 'rgba(9, 30, 60, 0.6)',
          extraCssText: 'box-shadow: 0 0 8px rgba(0, 128, 255, 0.27) inset;',
          borderWidth: 0,
          confine: false,
          appendToBody: true,
          textStyle: {
            color: '#fff',
            fontSize: 10,
          },
          // 轴触发提示才有效
          axisPointer: {
            type: 'shadow',
          },
          shadowStyle: {
            color: 'rgba(157, 168, 245, 0.1)',
          },

          formatter: (data) => {
            var tip = '<h5 class="echarts-tip-h5">' + data[0].name + '</h5>';
            data.forEach((item) => {
              let unit = '';
              if (item.seriesType === 'bar') {
                tip += '<div class="echarts-tip-div">';
                tip += '<div class="right">' + item.value + unit + '</div>';
                tip += '</div>';
              }
            });
            return tip;
          },
        },
        xAxis: {
          data: x,
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#4176a3',
              width: '0.5', //坐标线的宽度
            },
          },
          axisLabel: {
            textStyle: {
              color: '#fff', //底部文字颜色
              fontSize: 12,
            },
          },
        },
        yAxis: [
          {
            nameTextStyle: {
              align: 'left',
              fontSize: 11,
              color: '#4176a3',
            },
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: 'transparent', //左边框颜色
              },
            },
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: '#ADD6FF', //左文字颜色
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: 30,
            showBackground: true,
            backgroundStyle: {
              color: '#0A3F87',
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#25E9FB', //渐变2
                },
                {
                  offset: 1,
                  color: '#028CFF', //渐变1
                },
              ]),
            },
            data: data1,
            z: 0,
            zlevel: 0,
          },
          {
            type: 'pictorialBar',
            barWidth: 30,
            itemStyle: {
              color: '#0A3F87', //数据的间隔颜色
            },
            symbolRepeat: 'true',
            symbolMargin: 3,
            symbol: 'rect',
            symbolSize: [30, 4],
            data: data1,
            z: 1,
            zlevel: 0,
            label: {
              show: true,
              position: 'top',
              fontSize: 14,
              color: '#fff', //柱状顶部文字颜色
              formatter: function (params) {
                return params.data;
              },
            }
          },
        ],
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 240px;
    }
  }
}
</style>
