<template>
  <div class="province">
    <div class="province-item" v-for="item in provinces" :key="item.key" :class="item.className">
      <div class="text">
        {{ item.value }}人
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      provinces: [
        {
          className: "beijing",
          key: "beijingNum",
          value: 0,
        },
        {
          className: "tianjin",
          key: "tianjinNum",
          value: 0,
        },
        {
          className: "hebei",
          key: "hebeiNum",
          value: 0,
        },
        {
          className: "shanxi",
          key: "shanxiNum",
          value: 0,
        },
        {
          className: "neimenggu",
          key: "neimengguNum",
          value: 0,
        },
        {
          className: "liaoning",
          key: "liaoningNum",
          value: 0,
        },
        {
          className: "jilin",
          key: "jilinNum",
          value: 0,
        },
        {
          className: "heilongjiang",
          key: "heilongjiangNum",
          value: 0,
        },
        {
          className: "shanghai",
          key: "shanghaiNum",
          value: 0,
        },
        {
          className: "jiangsu",
          key: "jiangsuNum",
          value: 0,
        },
        {
          className: "zhejiang",
          key: "zhejiangNum",
          value: 0,
        },
        {
          className: "anhui",
          key: "anhuiNum",
          value: 0,
        },
        {
          className: "fujian",
          key: "fujianNum",
          value: 0,
        },
        {
          className: "jiangxi",
          key: "jiangxiNum",
          value: 0,
        },
        {
          className: "shandong",
          key: "shandongNum",
          value: 0,
        },
        {
          className: "henan",
          key: "henanNum",
          value: 0,
        },
        {
          className: "hubei",
          key: "hubeiNum",
          value: 0,
        },
        {
          className: "hunan",
          key: "hunanNum",
          value: 0,
        },
        {
          className: "guangdong",
          key: "guangdongNum",
          value: 0,
        },
        {
          className: "guangxi",
          key: "guangxiNum",
          value: 0,
        },
        {
          className: "hainan",
          key: "hainanNum",
          value: 0,
        },
        {
          className: "chongqing",
          key: "chongqingNum",
          value: 0,
        },
        {
          className: "sichuan",
          key: "sichuanNum",
          value: 0,
        },
        {
          className: "guizhou",
          key: "guizhouNum",
          value: 0,
        },
        {
          className: "yunnan",
          key: "yunnanNum",
          value: 0,
        },
        {
          className: "xizang",
          key: "xizangNum",
          value: 0,
        },
        {
          className: "shanxi2",
          key: "shanxi2Num",
          value: 0,
        },
        {
          className: "gansu",
          key: "gansuNum",
          value: 0,
        },
        {
          className: "qinghai",
          key: "qinghaiNum",
          value: 0,
        },
        {
          className: "ningxia",
          key: "ningxiaNum",
          value: 0,
        },
        {
          className: "xinjiang",
          key: "xinjiangNum",
          value: 0,
        },
        {
          className: "taiwan",
          key: "taiwanNum",
          value: 0,
        },
        {
          className: "xianggang",
          key: "xianggangNum",
          value: 0,
        },
        {
          className: "aomen",
          key: "aomenNum",
          value: 0,
        }
      ]
    }
  },
  watch: {
    '$store.state.mapData.haiheTalentIntroCount2'(newValue, oldVal) {
      this.provinces.forEach(item => {
        item.value = newValue[item.key];
      });
    }
  },

}
</script>

<style lang="scss" scoped>
.province {
  width: 1200px;
  height: 800px;
  position: absolute;
  left: 120px;
  top: 190px;

  .province-item {
    width: 80px;
    height: 66px;
    background: url("../../assets/map/dingwei.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;

    .text {
      width: 70px;
      height: 20px;
      position: absolute;
      left: 5px;
      top: 2px;
      text-align: center;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
    }

    &.beijing {
      top: 200px;
      right: 350px;
    }

    &.tianjin {
      top: 220px;
      right: 300px;
    }

    &.hebei {
      top: 260px;
      right: 350px;
    }

    &.shanxi {
      top: 280px;
      right: 420px;
    }

    &.neimenggu {
      top: 80px;
      right: 340px;
    }

    &.liaoning {
      top: 160px;
      right: 200px;
    }

    &.jilin {
      top: 120px;
      right: 160px;
    }

    &.heilongjiang {
      top: 40px;
      right: 150px;
    }

    &.shanghai {
      top: 380px;
      right: 150px;
    }

    &.jiangsu {
      top: 360px;
      right: 200px;
    }

    &.zhejiang {
      top: 430px;
      right: 180px;
    }

    &.anhui {
      top: 380px;
      right: 270px;
    }

    &.fujian {
      top: 530px;
      right: 200px;
    }

    &.jiangxi {
      top: 480px;
      right: 280px;
    }

    &.shandong {
      top: 290px;
      right: 280px;
    }

    &.henan {
      top: 340px;
      right: 380px;
    }

    &.hubei {
      top: 410px;
      right: 380px;
    }

    &.hunan {
      top: 500px;
      right: 380px;
    }

    &.guangdong {
      top: 600px;
      right: 320px;
    }

    &.guangxi {
      top: 600px;
      right: 460px;
    }

    &.hainan {
      bottom: 10px;
      right: 420px;
    }

    &.chongqing {
      top: 460px;
      right: 530px;
    }

    &.sichuan {
      top: 440px;
      right: 640px;
    }

    &.guizhou {
      top: 540px;
      right: 540px;
    }

    &.yunnan {
      top: 580px;
      right: 640px;
    }

    &.xizang {
      top: 420px;
      left: 176px;
    }

    &.shanxi2 {
      top: 360px;
      left: 640px;
    }

    &.gansu {
      top: 310px;
      left: 520px;
    }

    &.qinghai {
      top: 310px;
      left: 400px;
    }

    &.ningxia {
      top: 260px;
      left: 570px;
    }

    &.xinjiang {
      top: 120px;
      left: 180px;
    }

    &.taiwan {
      right: 100px;
      bottom: 180px;
    }

    &.xianggang {
      right: 280px;
      bottom: 100px;
    }

    &.aomen {
      right: 320px;
      bottom: 80px;
    }
  }


}
</style>