<!-- 人力资源产业业态 -->
<template>
  <div class="box">
    <!-- <div class="title">
      <div class="beijing"></div>
      <div class="text">人力资源产业业态</div>
    </div> -->
    <div class="leftBox">
      <div class="contentBox">

        <div class="content-right-title">
          <div class="content-right-title-name">和平区人力资源服务企业（{{ $store.state?.hpData?.industryIndexEntity?.totalNum }}家）
          </div>
        </div>
        <div class="content">
          <div class="card">
            <div class="card-item card-item-1" @click="handleClick(1)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.hrOutsourcedNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">人力资源服务外包</div>
              <img src="../../assets/hp/1.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-2" @click="handleClick(2)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.hrSoftwareNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">人力资源管理软件</div>
              <img src="../../assets/hp/2.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-3" @click="handleClick(3)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.advertiseNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">人力资源招聘</div>
              <img src="../../assets/hp/3.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-4" @click="handleClick(4)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.headhunterNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">猎头</div>
              <img src="../../assets/hp/4.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-5" @click="handleClick(5)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.hrTrainNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">培训</div>
              <img src="../../assets/hp/5.png" width="83" class="card-item-img">
            </div>
          </div>
          <div class="card">
            <div class="card-item card-item-6" @click="handleClick(6)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.hrEvaluationNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">测评背调</div>
              <img src="../../assets/hp/6.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-7" @click="handleClick(7)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.hrConsultingNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">管理咨询</div>
              <img src="../../assets/hp/7.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-8" @click="handleClick(8)">
              <div class="num">
                <countTo :startVal='0'
                  :endVal='$store.state?.hpData?.industryIndexEntity?.laborRelationConsultingNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">劳动关系</div>
              <img src="../../assets/hp/8.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-9" @click="handleClick(9)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.salaryBenefitsNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">薪酬福利</div>
              <img src="../../assets/hp/9.png" width="83" class="card-item-img">
            </div>
            <div class="card-item card-item-10" @click="handleClick(10)">
              <div class="num">
                <countTo :startVal='0' :endVal='$store.state?.hpData?.industryIndexEntity?.backgroundCheckNum || 0'
                  :duration='3000' />
                <span class="unit">家</span>
              </div>
              <div class="name">背景调查</div>
              <img src="../../assets/hp/10.png" width="83" class="card-item-img">
            </div>
          </div>
        </div>
      </div>
      <div class="leftCenter">
        <div class="content-right-title">
          <div class="content-right-title-name">和平区重点产业就业人员分布</div>
        </div>
        <Chart1></Chart1>
      </div>
      <div class="leftBottom">
        <div class="content-right-title">
          <div class="content-right-title-name">和平区重点产业求人倍率</div>
        </div>
        <Chart2></Chart2>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import Chart2 from './Chart2.vue';
import Chart1 from './Chart1.vue';
export default {
  components: { countTo, Chart1, Chart2 },
  data() {
    return {
      updateIndex: []
    }
  },
  // watch: {
  //   "$store.state.industryIndexEntity"(newValue, oldVal) {
  //     let new_advertiseNum = newValue.advertiseNum;
  //     let new_headhunterNum = newValue.headhunterNum;
  //     let new_hrConsultingNum = newValue.hrConsultingNum;
  //     let new_hrEvaluationNum = newValue.hrEvaluationNum;
  //     let new_hrOutsourcedNum = newValue.hrOutsourcedNum;
  //     let new_hrSoftwareNum = newValue.hrSoftwareNum;
  //     let new_hrTrainNum = newValue.hrTrainNum;
  //     let new_laborRelationConsultingNum = newValue.laborRelationConsultingNum;
  //     const { advertiseNum, headhunterNum, hrConsultingNum, hrEvaluationNum, hrOutsourcedNum, hrSoftwareNum, hrTrainNum, laborRelationConsultingNum } = oldVal;

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //     if (new_headhunterNum != headhunterNum) {
  //       this.updateIndex.push('headhunterNum');
  //     }

  //     if (new_hrConsultingNum != hrConsultingNum) {
  //       this.updateIndex.push('hrConsultingNum');
  //     }

  //     if (new_hrEvaluationNum != hrEvaluationNum) {
  //       this.updateIndex.push('hrEvaluationNum');
  //     }

  //     if (new_hrOutsourcedNum != hrOutsourcedNum) {
  //       this.updateIndex.push('hrOutsourcedNum');
  //     }

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }
  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //   }
  // },
  created() {
    console.log(this.$root.data);
  },
  methods: {
    handleClick() {

    }
  },
}
</script>

<style lang="scss" scoped>
.card-item-img {
  width: 83px;
  position: relative;
  top: -12px;
}

.box {
  width: 680px;
  height: 788px;

  .title {
    height: 36px;
    background: url("../../assets/image/Vector-1.png") no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    .beijing {
      width: 276px;
      height: 14px;
      background: url("../../assets/image/组 3221@2x.png") no-repeat center;
      background-size: 100% 100%;
      margin: 0 auto;
      margin-top: 7px;
    }

    .text {
      width: 192px;
      height: 16px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      position: absolute;
      left: 50%;
      margin-left: -96px;
      top: 8px;
    }
  }

  .leftBottom,
  .leftCenter,
  .contentBox {
    height: 250px;
    width: 680px;
  }

  .leftBox {
    width: 680px;
    height: 788px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    border-radius: 0px 0px 0px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 17px 0;
    box-sizing: border-box;

    .card {
      display: flex;

      .card-item {
        width: 133px;
        height: 110px;
        background: url("../../assets/image/renliziyuanwaibao.png") no-repeat center;
        background-size: 100% 100%;
        text-align: center;
        // padding: 20px 0;
        box-sizing: border-box;

        .num {
          font-family: DIN Alternate, DIN Alternate;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-top: 11px;

          .unit {
            font-size: 12px;
            margin-left: 3px;
          }

        }

        .name {
          // position: absolute;
          // width: 100%;
          // text-align: center;
          // margin-top: 10px;
          // margin-bottom: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 12px;
          color: #E5F9FF;
          line-height: 12px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }

      }

      .card-item-1 {
        color: #47D1FF;
      }

      .card-item-2 {
        color: #EDC279;
        // background: url("../../assets/image/lietou.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-3 {
        color: #E3FEBF;
        // background: url("../../assets/image/zhaopin.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-4 {
        color: #69E6B8;
        // background: url("../../assets/image/renliziyuanguanlizixun.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-5 {
        color: #8392FF;
        // background: url("../../assets/image/renliziyuanpeixun.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-6 {
        color: #F292E2;
        // background: url("../../assets/image/renliziyuanceping.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-7 {
        color: #FF8D9C;
        // background: url("../../assets/image/renliziyuanguanliruanjian.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-8 {
        color: #58E2D5;
        // background: url("../../assets/image/laodongguanxizixun.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-9 {
        color: #EDDE79;
        // background: url("../../assets/image/laodongguanxizixun.png") no-repeat center;
        // background-size: 100% 100%;
      }

      .card-item-10 {
        color: #B1D0FF;
        // background: url("../../assets/image/laodongguanxizixun.png") no-repeat center;
        // background-size: 100% 100%;
      }
    }
  }
}

.content-right-title {
  height: 42px;
  width: 100%;

  .content-right-title-name {
    width: 318px;
    height: 42px;
    background: url("../../assets/image/post-title.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #E5F9FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 43.5px;
    text-indent: 30px;
  }
}
</style>