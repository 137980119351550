<template>
  <div class="nav-list">
    <div class="nav-item" :class="{ 'active': activePath == item.path, 'hide': item.hidden }" v-for="item in navList"
      :key="item.name" @click="handleToPage(item.path)">{{
        item.name }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePath: this.$route.path
    }
  },
  computed: {
    navList() {
      return [
        {
          name: "天津人力资源数据分析",
          path: "/home"
        },
        {
          name: "和平人力资源数据分析",
          path: "/hp"
        },

        {
          name: "和平人资数据分析Ⅰ",
          path: "/page3",
          hidden: true,
        },
        {
          name: "和平人力资源市场监测ⅠⅠ",
          path: "/table",
          hidden: true,
        },
        {
          name: "和平海河英才数据分析",
          path: "/maphp",
          hidden: true,
        },
        {
          name: "和平人力资源市场监测",
          path: "/page4",

        },
        {
          name: "海河英才数据分析",
          path: "/map"
        },

      ]
    }
  },
  methods: {
    handleToPage(path) {
      if (path == this.activePath) {
        return;
      }
      if (path) {
        this.$router.push(path);
      }
    },
  },
  watch: {
    "$route": {
      handler(newVal, oldVal) {
        this.activePath = newVal.path;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-list {
  width: 100%;
  position: fixed;
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  box-sizing: border-box;
  top: 86px;
  left: 0;

  .nav-item {
    width: 260px;
    height: 40px;
    background: url("../../assets/page2/默认状态.png") no-repeat center;
    background-size: 100% 100%;
    font-size: 18px;
    font-weight: 500;
    color: #47D1FF;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    color: #28F1FA;
    background: url("../../assets/page2/当前状态.png") no-repeat center;
    background-size: 100% 100%;
  }

  .hide {
    visibility: hidden;
  }
}
</style>