<template>
  <div class="chart">
    <div class="title">
      服务单位数量占比分析
    </div>
    <div class="chartbody">
      <div class="chartbody-left">
        <div ref="echart" id="echart" class="echartDiv"></div>
      </div>
      <div class="chartbody-right">
        <ul class="list">
          <li class="list-item" v-for="item in pieData" :key="item.itemStyle.color">
            <div class="name"><span class="icon" :style="{ 'backgroundColor': item.itemStyle.color }"></span>{{
              item.name }}
            </div>
            <div class="proportion">{{ item.value }}家</div>
            <div class="num">{{ item.proportion }}%</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      pieData: [
        {
          name: "国有企业事业单位",
          value: 0,
          proportion: 0,
          itemStyle: {
            color: '#308DFB',
          }
        },
        {
          name: "民营企业",
          value: 0,
          proportion: 0,
          itemStyle: {
            color: '#34C0B0',
          }
        },
        {
          name: "外资企业",
          value: 0,
          proportion: 0,
          itemStyle: {
            color: '#FC9F72',
          }
        },
        {
          name: "其他",
          value: 0,
          proportion: 0,
          itemStyle: {
            color: '#FDD970',
          }
        },
      ],
    }
  },
  watch: {
    '$store.state.page3Data.serviceEnterpriseDataVo'(newValue, oldVal) {
      const { stateEnterpriseNum, privateEnterpriseNum, foreignEnterpriseNum, otherNum } = newValue;
      let totalNum = stateEnterpriseNum + privateEnterpriseNum + foreignEnterpriseNum + otherNum;
      let guoyou = parseFloat(stateEnterpriseNum / totalNum * 100).toFixed(2);
      let minying = parseFloat(privateEnterpriseNum / totalNum * 100).toFixed(2);
      let waizi = parseFloat(foreignEnterpriseNum / totalNum * 100).toFixed(2);
      let qita = parseFloat(otherNum / totalNum * 100).toFixed(2);
      this.pieData[0].value = stateEnterpriseNum
      this.pieData[1].value = privateEnterpriseNum
      this.pieData[2].value = foreignEnterpriseNum
      this.pieData[3].value = otherNum
      this.pieData[0].proportion = parseFloat(guoyou);
      this.pieData[1].proportion = parseFloat(minying);
      this.pieData[2].proportion = parseFloat(waizi);
      this.pieData[3].proportion = parseFloat(qita);
      this.initChart();
    }
  },
  methods: {
    initChart() {
      const myChart = echarts.init(this.$refs.echart);
      const YData = this.pieData.map(item => item.value)
      const sum = YData.reduce((item, index) => item + index, 0); // 求数据之和
      const option = {
        title: {
          text: sum,
          textStyle: {
            color: "#47D1FF",
            fontSize: 20,
            fontWeight: "bold",
          },
          subtext: "服务量",
          subtextStyle: {
            fontSize: 12,
            color: "#47D1FF",
          },
          x: "center",
          y: 130,
        },
        grid: {
          bottom: 150,
          left: 100,
          right: "10%",
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ["35%", "75%"],
            center: ["50%", "50%"],
            data: this.pieData,
            avoidLabelOverlap: true, // 开启智能布局 (防止折线位置冲突)
            label: {
              show: false,
            },
          },
          // 边框的设置
          {
            radius: ["35%", "42%"],
            center: ["50%", "50%"],
            type: "pie",
            animation: false,
            tooltip: {
              show: false,
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  color: "rgba(0,0,0,0.3)",
                },
              },
            ],
          },
        ]
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 394px;
  width: 100%;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    display: flex;

    .chartbody-left {
      width: 50%;
      height: 100%;

      .echartDiv {
        width: 100%;
        height: 100%;
      }
    }

    .chartbody-right {
      width: 50%;
      height: 100%;
      position: relative;

      .list {
        list-style: none;
        font-size: 12px;
        margin: 0;
        padding: 0;
        margin-top: 35px;
        position: absolute;
        top: 50px;

        .list-item {
          margin-bottom: 20px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          font-size: 12px;
          display: flex;

          .name {
            width: 150px;
            color: rgba(255, 255, 255, 0.85);

            .icon {
              width: 12px;
              height: 8px;
              display: inline-block;
              margin-right: 5px;
            }
          }

          .proportion {
            width: 100px;
            color: #47D1FF;
          }

          .num {
            width: calc(100% - 250px);
            color: #47D1FF;
          }
        }

      }
    }

  }



}
</style>