<template>
  <div class="chart">
    <div class="title">
      七大产业薪资中位数变化
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";

export default {
  data () {
    return {
      xData: ['金融服务', '商贸零售', '人力资源', '航运服务', '文化旅游', '数字信息', '医药健康'],
      eventWarning: [0, 0, 0, 0, 0, 0, 0],
      eventHandling: [0, 0, 0, 0, 0, 0, 0],
      legend: [
        '2023年上半年', '2024年上半年'
      ]
    }
  },
  watch: {
    '$store.state.page4Data' (newValue, oldVal) {
      const { medianSalaryYoy1, medianSalaryYoy2 } = newValue;
      this.eventWarning = [
        medianSalaryYoy1.financialServicePercent,
        medianSalaryYoy1.intelligentManufacturingPercent,
        medianSalaryYoy1.modernServicePercent,
        medianSalaryYoy1.transportationPercent,
        medianSalaryYoy1.cultureEntertainmentPercent,
        medianSalaryYoy1.artificialIntelligencePercent,
        medianSalaryYoy1.medicalHealthPercent
      ].reverse();
      this.eventHandling = [
        medianSalaryYoy2.financialServicePercent,
        medianSalaryYoy2.intelligentManufacturingPercent,
        medianSalaryYoy2.modernServicePercent,
        medianSalaryYoy2.transportationPercent,
        medianSalaryYoy2.cultureEntertainmentPercent,
        medianSalaryYoy2.artificialIntelligencePercent,
        medianSalaryYoy2.medicalHealthPercent
      ].reverse();
      this.init();
    }
  },
  methods: {
    init () {
      const myChart = echarts.init(this.$refs.echart);
      const color = ['#8AFFFF', '#027fff', '#027fff']; //2个以上的series就需要用到color数组
      const color2 = ['#333', '#666', '#4aa4ff'];
      const legend = {
        show: true,
        //data，就是取得每个series里面的name属性。
        orient: 'horizontal',
        icon: 'circle', //图例形状
        padding: 0,
        top: 0,
        right: 40,
        itemWidth: 14, //小圆点宽度
        itemHeight: 14, // 小圆点高度
        itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
          fontSize: 14,
          color: '#00E4FF',
        },
      };
      const tooltip = {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      };
      const commonSeries = {
        type: 'bar',
        barWidth: '30%', //柱条的宽度，不设时自适应。
        itemStyle: {
          //定义柱子的样式
          borderRadius: [0, 20, 20, 0], //柱子圆角[上右下左]，也可以统一值。
          // color:'red' //不写color，每个series取上面定义的全局color，很好的啊
          //自己来自定义color
          color: (params) => {
            return {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: color2[params.seriesIndex], // 0% 处的颜色 rgba(0,0,0,.1)
                },
                {
                  offset: 1,
                  color: color[params.seriesIndex], // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            };
          },
        },
        showBackground: true, //柱子是否带有背景，默认是没有的
        backgroundStyle: {
          //只有showBackground=true，设置backgroundStyle才会有效果
          color: 'rgba(180, 180, 180, .2)', //
          borderRadius: [0, 20, 20, 0],
        },
      };
      let series = [
        { name: '2023年上半年', data:  this.eventWarning},
        { name: '2024年上半年', data:this.eventHandling },
        // { name: '2011', data: [18203, 23489, 29034, 104970, 131744, 630230] },
        // { name: '2012', data: [19325, 23438, 31000, 121594, 134141, 681807] },
      ];
      series = series.map((item) => ({
        ...item,
        ...commonSeries,
      }));
      const option = {
        color,
        // title,
        legend,
        tooltip,
        grid:{top:30,bottom:30},
        xAxis: {
          type: 'value',
          axisLine: {
            show: true, //显示Y轴
          },
          axisTick: {
            show: false, //不显示小的刻度线
          },
          splitLine: {
            show: false, //不显示竖向分割线
          },axisLabel:{
            color:'#FFF'
          },
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: true, //显示X轴
          },
          axisTick: {
            show: false, //不显示小的刻度线
          },axisLabel:{
            color:'#FFF'
          },
          splitLine: {
            show: false, //不显示横向分割线
          },
          data: ['医疗健康', '数字信息', '文化旅游', '交通运输', '现代服务', '现代商贸','金融服务'],
        },
        series,
      };
      option && myChart.setOption(option);
      //图表自动轮播高亮显示函数 myChart直接用
      // const animation = (delay = 3000) => {
      //   let currentIndex = -1;
      //   const length = option.series[0].data.length;
      //   setInterval(() => {
      //     currentIndex = (currentIndex + 1) % length;
      //     // 高亮当前图形
      //     myChart.dispatchAction({
      //       type: 'highlight',
      //       seriesIndex: 0,

      //       dataIndex: currentIndex,
      //     });
      //     // 显示 tooltip
      //     myChart.dispatchAction({
      //       type: 'showTip',

      //       seriesIndex: 0,
      //       dataIndex: currentIndex,
      //     });
      //   }, delay);
      // };
    },
    getEcharts3DBar () {
      const myChart = echarts.init(this.$refs.echart);
      const option = {
        color: ['#43E9A3', '#0088FF'],
        timeline: {
          show: false,
        },
        legend: {
          top: 0,
          left: "center",
          left: 180,
          itemWidth: 16,
          itemHeight: 6,
          itemGap: 20,
          icon: "horizontal",
          textStyle: {
            color: "#ffffff",
            fontSize: 14,
          },
          data: this.legend,
        },
        grid: [
          // 左边
          {
            show: false,
            left: "5%",
            top: "15%",
            bottom: "2%",
            containLabel: true,
            width: "38%",
          },
          // 中间
          {
            show: false,
            left: "51%",
            top: "15%",
            bottom: "9%",
            width: "0%",
          },
          // 右边
          {
            show: false,
            right: "5%",
            top: "15%",
            bottom: "2%",
            containLabel: true,
            width: "38%",
          },
        ],
        xAxis: [
          {
            type: "value",
            inverse: true,
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLabel: {
              show: true,
              verticalAlign: 'middle',
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontSize: 12,
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            gridIndex: 1,
            show: false,
          },
          {
            gridIndex: 2,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontSize: 12,
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            position: "right",
            axisLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            data: this.xData,
          },
          {
            gridIndex: 1,
            type: "category",
            inverse: true,
            position: "left",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              color: "rgba(255, 255, 255, 1)",
              fontSize: 12,
              align: "center",
              verticalAlign: "middle"
            },
            data: this.xData.map(function (value) {
              return {
                value: value,
                textStyle: {
                  align: "center",
                },
              };
            }),
          },
          {
            gridIndex: 2,
            type: "category",
            inverse: true,
            position: "left",
            axisLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            data: this.xData,
          },
        ],
        series: [
          {
            name: this.legend[0],
            type: "bar",
            barWidth: 10,
            stack: "1",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: "#43E9A3",
                },
                {
                  offset: 1,
                  color: "#43E9A3",
                },
              ]),
            },
            label: {
              show: false,
              position: "left",
              color: "rgba(255, 255, 255, 1)",
            },
            data: this.eventWarning,
            animationEasing: "elasticOut",
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: this.legend[1],
            type: "bar",
            stack: "2",
            barWidth: 10,
            xAxisIndex: 2,
            yAxisIndex: 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: "#0088FF",
                },
                {
                  offset: 1,
                  color: "#47D1FF",
                },
              ]),
            },
            label: {
              show: false,
              position: "right",
              color: "rgba(255, 255, 255, 1)",
            },
            data: this.eventHandling,
            animationEasing: "elasticOut",
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
        ],
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 240px;
    }
  }
}
</style>