import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    employeeTotalNm: 0,
    postTotalNum: 0,
    allData: {},
    industryIndexEntity: {},
    page2Data: {
      ageStructureDataVo: {},
      educationalStructureDataVo: {},
      employeeNumCount: {},
      haiheTalentIntroCount: {},
      haiheTalentIntroCount2: {},
      industryIndexEntity: {},
      jobSeekerNumCount: {},
      postNumCount: {},
      regionDistributionDataVo: {},
      servicePersonnelNumber: 0,
      totalOutputValue: 0
    },
    hpData: {
      ageStructureDataVo: {},
      educationalStructureDataVo: {},
      employeeNumCount: {},
      haiheTalentIntroCount: {},
      haiheTalentIntroCount2: {},
      industryIndexEntity: {},
      jobSeekerNumCount: {},
      postNumCount: {},
      regionDistributionDataVo: {},
      servicePersonnelNumber: 0,
      totalOutputValue: 0,
    },
    mapData: {
      ageStructureDataVo: {},
      educationalStructureDataVo: {},
      employeeNumCount: {},
      haiheTalentIntroCount: {},
      haiheTalentIntroCount2: {},
      industryIndexEntity: {},
      jobSeekerNumCount: {},
      postNumCount: {},
      regionDistributionDataVo: {},
      servicePersonnelNumber: 0,
      totalOutputValue: 0,
    },
    page3Data: {
      educationalStructureDataVo: {},
      hpqGrossRevenue: null,
      hpqTaxAmount: null,
      serviceEnterpriseDataVo: {},
      year: null
    },
    page4Data: {
      industryRecruitmentRatio1: {},
      industryRecruitmentRatio2: {},
      industryStatisticsDataVo: {},
      jobApplicationMultiple: {},
      jobSeekerNumCount: {},
      jobseekerYoy1: {},
      jobseekerYoy2: {},
      medianSalaryYoy1: {},
      medianSalaryYoy2: {},
      postNumCount: {},
      prosperityIndex: {},
      topStructureDataVo: {},
    },
    tableData:{
      educationalStructureDataVo:{}
    }
  },
  mutations: {
    setEmployeeTotalNm(state, data) {
      state.employeeTotalNm = data;
    },
    setPostTotalNum(state, data) {
      state.postTotalNum = data;
    },
    setAllData(state, data) {
      state.allData = data;
    },
    setIndustryIndexEntity(state, data) {
      state.industryIndexEntity = data;
    },
    setPage2Data(state, data) {
      state.page2Data = data;
    },
    setHpData(state, data) {
      state.hpData = data;
    },
    setMapData(state, data) {
      state.mapData = data;
    },
    setPage3Data(state, data) {
      state.page3Data = data;
    },
    setPage4Data(state, data) {
      state.page4Data = data;
    },
    setTableData(state, data) {
      state.tableData = data;
    },
  }
})

export default store
