<template>
  <div class="chart">
    <div class="title">
      求职者关注的十大职业大类
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
    <!-- <div class="label">
      <div class="label-1"><span></span>2023年上半年</div>
      <div class="label-2"><span></span>2024年上半年</div>
    </div> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data () {
    return {
      eventWarning: [],
    }
  },
  watch: {
    '$store.state.page4Data'(newValue, oldVal) {
      const { topStructureDataVo, medianSalaryYoy2 } = newValue;
      this.eventWarning = [
        [topStructureDataVo.top1Name,
        topStructureDataVo.top2Name,
        topStructureDataVo.top3Name,
        topStructureDataVo.top4Name,
        topStructureDataVo.top5Name,
        topStructureDataVo.top6Name,
        topStructureDataVo.top7Name,
        topStructureDataVo.top8Name,
        topStructureDataVo.top9Name,
        topStructureDataVo.top10Name,
        ],
        [topStructureDataVo.top1Percent,
        topStructureDataVo.top2Percent,
        topStructureDataVo.top3Percent,
        topStructureDataVo.top4Percent,
        topStructureDataVo.top5Percent,
        topStructureDataVo.top6Percent,
        topStructureDataVo.top7Percent,
        topStructureDataVo.top8Percent,
        topStructureDataVo.top9Percent,
        topStructureDataVo.top10Percent,
        ],
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar () {
      const myChart = echarts.init(this.$refs.echart);
      const createSvg = (shadowColor, shadowBlur) => `  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
        x="0px" y="0px" 
        viewBox="0 0 32 128"
        xml:space="preserve"
    >
        <style>
            .st2 {
                fill: transparent;
                stroke: ${shadowColor};
                stroke-width: ${shadowBlur}px;
                filter: url(#chart-inset-shadow);
            }
        </style>
        <defs>
            <filter id="chart-inset-shadow" width="200%" height="200%" x="-50%" y="-50%">
        		<feGaussianBlur in="SourceGraphic" result="gass" stdDeviation="${shadowBlur * 0.75}" />
        		<feMerge>
        			<feMergeNode in="gass" />
        		</feMerge>
        	</filter>
        </defs>
        <path class="st2" d="M0 0 L32 0 L32 128 L0 128 Z" />
    </svg>
`;

      const svgString = createSvg('#8AFFFF', 8);
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

      const DOMURL = window.URL || window.webkitURL || window;
      const insetShadowUrl = DOMURL.createObjectURL(svg);

      const dataSet = this.eventWarning

      const option = {
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(125, 147, 202, 1)',
            },
          },
          axisTick: {
            show: false,
          },
          // axisLabel: {
          //   color: 'rgba(125, 147, 202, 1)',
          //   padding: [10, 0, 0, 0],
          // },
          axisLabel: {
            show: true,
            // margin: 14,
            fontSize: 10,
            // rotate: 30,
            textStyle: {
              color: "#fff" //X轴文字颜色
            }
          },
          splitLine: {
            show: false,
          },
          data: dataSet[0],
        },
        grid:{
          bottom:40
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(125, 147, 202, 1)',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: 'rgba(255,255,255, 0.5)',
            formatter: '{value}%',
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(255,255,255,0.1)',
              width: 2,
            },
          },
        },
        series: [

          {
            data: dataSet[1],
            type: 'pictorialBar',
            symbol: 'image://' + insetShadowUrl,
            barWidth: 20,
          },
          {
            data: dataSet[1],
            type: 'bar',
            barWidth: 50,
            itemStyle: {
              color: 'transparent',
              shadowColor: 'blue',
              shadowBlur: 12,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
            },
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: (e) => {
                  // return e.value + '次';
                  return e.value +"%";
                },
                fontSize: 12,
                color: "#fff",
                offset: [-30, -1],
              },
            },
            itemStyle: {
              color: 'transparent',
            },
            tooltip: {},
            data:  dataSet[1],
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
    text-indent: 2em;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>