<template>
  <div class="chart">
    <div class="chart-header">
      历年引进人数
    </div>
    <div class="chart-body">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      optionsData: []
    }
  },
  watch: {
    '$store.state.mapData'(newValue, oldVal) {
      this.initChart()
    }
  },
  methods: {
    initChart() {
      var myChart = echarts.init(this.$refs.echart);
      let xLabel = this.$store.state.mapData.haiheHistoryList.map(item => item.yearNum).reverse();
      let total = 0;
      this.$store.state.mapData.haiheHistoryList.forEach(item => {
        total += item.introNum;
      })

      let values = this.$store.state.mapData.haiheHistoryList.map(item => {
        // return (item.introNum / total * 100).toFixed(2);
        return item.introNum;
      }).reverse();

      console.log(xLabel, "::::::::::::::xLabel");


      console.log(values, "::::::::::::::::values");


      var option = {
        title: {
          text: '单位（万人）',
          textStyle: {
            fontWeight: 400,
            fontSize: '10px',
            color: '#B1D2EB',
          }
        },
        tooltip: {},
        animation: false,
        grid: {
          top: "15%",
          bottom: "20%"//也可设置left和right设置距离来控制图表的大小
        },
        xAxis: {
          data: xLabel,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#1E4981'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1E4981',
              width: 1
            },
          },
          axisTick: {
            show: true //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            // margin: 14,
            fontSize: 10,
            // rotate: 30,
            textStyle: {
              color: "#fff" //X轴文字颜色
            }
          },

        },
        yAxis: [
          {
            type: "value",
            gridIndex: 0,
            // min: 0,
            // max: 100,
            // interval: 25,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#1E4981',
                width: 1
              },
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#1E4981',
              }
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 10,
              textStyle: {
                color: "#A3C0DF" //X轴文字颜色
              }
            },
          },
        ],
        series: [
          {
            name: "主营业务",
            type: "bar",
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#CA41FB"
                },
                {
                  offset: 1,
                  color: "#64BDFC"
                }
                ])
              }
            },
            data: values,
            z: 10,
            zlevel: 0,
            "label": {
              "show": true,
              "position": "top",
              "distance": 10,
              fontSize: 16,
              "color": "#CA41FB"
            }
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#0F375F"
              }
            },
            symbolRepeat: "fixed",
            symbolMargin: 2,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: "start",
            symbolOffset: [0, -1],
            // symbolBoundingData: this.total,
            data: values,
            width: 25,
            z: 0,
            zlevel: 1,
          },
        ]
      };
      console.log(option, "::::::::::::option");

      myChart.setOption(option, true);
      myChart.on('click', (param) => {
        // 当前点击的索引，默认只可以点击（如柱状图bar,折线图折点）
        console.log(param.dataIndex)
        // this.$router.push({
        //   path:'/table'
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 282px;
  box-sizing: border-box;
  padding: 0 15px;
  overflow: hidden;

  .chart-header {
    width: 184px;
    height: 32px;
    background: url("../../assets/image/Vector 411@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #47D1FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 31px;
    text-indent: 20px;
  }

  .chart-body {
    display: flex;
    box-sizing: border-box;
    // padding: 20px;
    height: 207px;
    // margin-top: -30px;

    .echartDiv {
      margin-top: 20px;
      width: 100%;
      height: 100%;
    }

  }
}
</style>