<template>
  <div class="chart">
    <div class="chart-header">
      岗位地域分布
    </div>
    <div class="chart-body">
      <div class="chart-left">
        <div class="chart-dipan"></div>
        <div ref="echart" id="echart" class="echartDiv"></div>
      </div>
      <div class="chart-right">
        <ul class="list">
          <li class="list-item" v-for="item in series" :key="item.itemStyle.color">
            <div class="name"><span class="icon" :style="{ 'backgroundColor': item.itemStyle.color }"></span>{{
              item.name }}
            </div>
            <div class="value">
              <span style="margin-left: 10px;">{{ item.value }}个</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      series: []
    }
  },
  watch: {
    '$store.state.allData'(newValue, oldVal) {
      this.series = [
        {
          name: "和平区",
          color: "#A3CD61",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hpingPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hpingNum,
          itemStyle: {
            color: "#A3CD61",
          },
        },
        {
          name: "河东区",
          color: "#8E62D7",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hdongPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hdongNum,
          itemStyle: {
            color: "#8E62D7",
          },
        },
        {
          name: "河西区",
          color: "#E7BA7B",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hxiPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hxiNum,
          itemStyle: {
            color: "#E7BA7B",
          },
        },
        {
          name: "南开区",
          color: "#54C178",
          proportion: this.$store.state.allData?.jobseekersRegDis?.nkaiPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.nkaiNum,
          itemStyle: {
            color: "#54C178",
          },
        },
        {
          name: "河北区",
          color: "#D66750",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hbeiPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hbeiNum,
          itemStyle: {
            color: "#D66750",
          },
        },
        {
          name: "红桥区",
          color: "#BE99A9",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hqiaoPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hqiaoNum,
          itemStyle: {
            color: "#BE99A9",
          },
        },
        {
          name: "滨海新区",
          color: "#044DC8",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hdianxinPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.hdianxinNum,
          itemStyle: {
            color: "#044DC8",
          },
        },
        {
          name: "东丽区",
          color: "#3D5DC5",
          proportion: this.$store.state.allData?.jobseekersRegDis?.dliPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.dliNum,
          itemStyle: {
            color: "#3D5DC5",
          },
        },
        {
          name: "西青区",
          color: "#3D5DC4",
          proportion: this.$store.state.allData?.jobseekersRegDis?.xqingPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.xqingNum,
          itemStyle: {
            color: "#3D5DC4",
          },
        },
        {
          name: "津南区",
          color: "#219D87",
          proportion: this.$store.state.allData?.jobseekersRegDis?.jnanPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.jnanNum,
          itemStyle: {
            color: "#219D87",
          },
        },
        {
          name: "北辰区",
          color: "#459BA8",
          proportion: this.$store.state.allData?.jobseekersRegDis?.bchenPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.bchenNum,
          itemStyle: {
            color: "#459BA8",
          },
        },
        {
          name: "武清区",
          color: "#3EB5AB",
          proportion: this.$store.state.allData?.jobseekersRegDis?.wqingPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.wqingNum,
          itemStyle: {
            color: "#3EB5AB",
          },
        },
        {
          name: "宝坻区",
          color: "#B8860B",
          proportion: this.$store.state.allData?.jobseekersRegDis?.wqingPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.bdiNum,
          itemStyle: {
            color: "#B8860B",
          },
        },
        
        {
          name: "宁河区",
          color: "#228B22",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hdianxinPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.nheNum,
          itemStyle: {
            color: "#228B22",
          },
        },
        {
          name: "静海区",
          color: "#E6E6FA",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hdianxinPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.jhaiNum,
          itemStyle: {
            color: "#E6E6FA",
          },
        },
        {
          name: "蓟州区",
          color: "#483D8B",
          proportion: this.$store.state.allData?.jobseekersRegDis?.hdianxinPercent,
          value: this.$store.state.allData?.jobseekersRegDis?.jzhouNum,
          itemStyle: {
            color: "#483D8B",
          },
        },
      ]
      this.echartInit();
    }
  },
  methods: {
    echartInit() {
      const chart = echarts.init(this.$refs.echart);
      chart.setOption(this.getPie3D(this.series, 0.75));
    },
    // 生成扇形的曲面参数方程
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      const midRatio = (startRatio + endRatio) / 2;

      const startRadian = startRatio * Math.PI * 2;
      const endRadian = endRatio * Math.PI * 2;
      const midRadian = midRatio * Math.PI * 2;

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        // eslint-disable-next-line no-param-reassign
        isSelected = false;
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      // eslint-disable-next-line no-param-reassign
      k = typeof k !== "undefined" ? k : 1 / 3;

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      const hoverRate = isHovered ? 1.05 : 1;

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x(u, v) {
          if (u < startRadian) {
            return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y(u, v) {
          if (u < startRadian) {
            return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z(u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * 300 * 0.01;
          }
          // 当前图形的高度是Z根据h（每个value的值决定的）
          return Math.sin(v) > 0 ? 1 * 300 * 0.01 : -1;
        },
      };
    },
    getPie3D(pieData, internalDiameterRatio) {
      const series = [];
      // 总和
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      const legendData = [];
      const k =
        typeof internalDiameterRatio !== "undefined"
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i += 1) {
        sumValue += pieData[i].value;

        const seriesItem = {
          name: typeof pieData[i].name === "undefined" ? `series${i}` : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k,
          },
        };

        if (typeof pieData[i].itemStyle !== "undefined") {
          const { itemStyle } = pieData[i];

          // eslint-disable-next-line no-unused-expressions
          typeof pieData[i].itemStyle.color !== "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          // eslint-disable-next-line no-unused-expressions
          typeof pieData[i].itemStyle.opacity !== "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }
      for (let i = 0; i < series.length; i += 1) {
        endValue = startValue + series[i].pieData.value;

        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          // 我这里做了一个处理，使除了第一个之外的值都是10
          series[i].pieData.value
        );

        startValue = endValue;

        legendData.push(series[i].name);
      }

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      const option = {
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 4,

          viewControl: {
            alpha: 25,
            // beta: 30,
            rotateSensitivity: 1,
            zoomSensitivity: 0,
            panSensitivity: 0,
            autoRotate: true,
            distance: 110,
          },
          postEffect: {
            enable: false,
            bloom: {
              enable: true,
              bloomIntensity: 0.1,
            },
            SSAO: {
              enable: true,
              quality: "medium",
              radius: 2,
            },
            // temporalSuperSampling: {
            //   enable: true,
            // },
          },
        },
        series: [
          ...series,
          {
            type: "pie",
            labelLine: {
              length: 10,
              length2: 30,
            },
            startAngle: -25,
            // 图像会自动旋转
            clockwise: true,
            radius: ["65%", "65%"],
            center: ["35%", "40%"],
            data: pieData,
            label: {
              show: false,
              color: "#fff",
            },
            zlevel: 999,
          },
        ],
      };
      return option;
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 50%;
  height: 232px;
  box-sizing: border-box;
  padding: 0 15px 0 0;

  .chart-header {
    width: 219.6px;
    height: 43.5px;
    background: url("../assets/image/post-title.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 43.5px;
    text-indent: 30px;
    margin-top: -8px;
  }

  .chart-body {
    display: flex;
    padding-left: 15px;

    .chart-left {
      width: 60%;
      height: 200px;
      position: relative;

      .chart-dipan {
        width: 500px;
        height: 400px;
        background: url("../assets/image/chart-2.png") no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        margin-left: -280px;
        top: -80px;
      }

      .echartDiv {
        margin-top: -20px;
        width: 100%;
        height: 170px;
        position: absolute;
        left: -25px;
        top: 10px;
      }
    }

    .chart-right {
      width: 40%;
      height: 200px;
      position: relative;

      .list {
        list-style: none;
        font-size: 12px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        width: 320px;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        left: -30px;
        top: -10px;

        .list-item {
          width: 80px;
          margin-bottom: 15px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          font-size: 12px;

          .name {
            width: 100px;
            color: rgba(255, 255, 255, 0.85);

            .icon {
              width: 12px;
              height: 8px;
              display: inline-block;
              margin-right: 5px;
            }
          }

          .value {
            color: #47D1FF;
            text-indent: 10px;
          }

        }

      }
    }
  }
}
</style>