<template>
  <div class="chart">
    <div class="chart-header">
      年龄分布
    </div>
    <div class="chart-body distribution">
      <div class="distribution_item" @click="handleClick(item)" style="cursor: pointer;"
        v-for="(item, index) in distribution" :key="index">
        <div class="distribution_item-img">{{ item.name }}</div>
        <div class="distribution_item-content">
          <div class="distribution_item-progress">
            <div :style="`width:${item.percentage}%`"></div>
          </div>
        </div>
        <div class="distribution_item-text">{{ item.percentage }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  data() {
    return {
      distribution: [

      ]
    }
  },
  created() {

  },
  watch: {
    '$store.state.page2Data.ageStructureDataVo'(newValue, oldVal) {
      this.distribution = [
        { name: '25岁以下', percentage: newValue.over10Percent, value: 10000 },
        { name: '25-35岁', percentage: newValue.over20Percent, value: 10000 },
        { name: '35-45岁', percentage: newValue.over30Percent, value: 10000 },
        { name: '45-55岁', percentage: newValue.over40Percent, value: 10000 },
        { name: '55岁以上', percentage: newValue.over50Percent, value: 10000 },
      ]
    }
  },
  methods: {
    handleClick() {

    }
  }
}
</script>
<style lang="sass" scoped>
.distribution
  // text-align: left
  // display: grid
  // // height: calc( 100% - 40px )
  // padding-top: 45px
  // padding-bottom: 20px
  // grid-template-rows: repeat(1fr)
  // grid-template-columns: 1fr
  display: flex
  flex-direction: column
  &_item
    display: flex
    align-items: center
    justify-content: center
    height: 39px
    &-img
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC
      font-weight: 500
      font-size: 10px
      color: #FFFFFF
      text-align: right
      font-style: normal
      text-transform: none
      width: 70px
      margin-right: 20px
    &-text
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC
      font-weight: 500
      font-size: 12px
      color: #47D1FF
      text-align: left
      font-style: normal
      text-transform: none
    &-content
      // margin-right: 45px
      // &>div:first-child
      //   display: flex
      //   align-items: center
    &-name
      font-size: 16px
      color: #FFF
      flex: 1
    &-num
      font-size: 18px
      color: #47D1FF
      margin-right: 17px
    &-unit
      font-size: 18px
      color: #47D1FF
    &-progress
      height: 10px
      margin-right: 20px
      width: 457px
      background: #0072C8
      // margin-top: 10px
      border-radius: 10px
      // border-top-right-radius: 10px
      // border-bottom-right-radius: 10px
      &>div
        height: 10px
        background-image: linear-gradient(270deg, #47D1FF 0%, #0088FF 100%)
        border-radius: 10px
</style>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  padding: 0 15px;
  overflow: hidden;

  .chart-header {
    width: 184px;
    height: 32px;
    background: url("../../assets/image/Vector 411@2x.png") no-repeat center;
    background-size: 100% 100%;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #47D1FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 31px;
    text-indent: 20px;
  }

  .chart-body {
    display: flex;
    margin-top: 30px;

    .chart-left {
      width: 60%;
      height: 200px;
      position: relative;

      .chart-dipan {
        width: 275px;
        height: 140px;
        background: url("../../assets/image/chart-1.png") no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        margin-left: -137.5px;
        bottom: 20px;
      }

      .echartDiv {
        margin-top: 0;
        width: 100%;
        height: 200px;
      }
    }

    .chart-right {
      width: 40%;
      height: 200px;

      .list {
        list-style: none;
        font-size: 12px;
        margin: 0;
        padding: 0;
        margin-top: 10px;

        .list-item {
          margin-bottom: 10px;

          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          font-size: 12px;
          display: flex;

          .name {
            width: 100px;
            color: rgba(255, 255, 255, 0.85);

            .icon {
              width: 12px;
              height: 8px;
              display: inline-block;
              margin-right: 5px;
            }
          }

          .proportion {
            width: 50px;
            color: #47D1FF;
          }

          .num {
            width: calc(100% - 150px);
            color: #47D1FF;
          }
        }

      }
    }
  }
}
</style>