import Vue from 'vue';
import Router from 'vue-router';
import Index from '../views/index.vue';
import Page1 from '../views/page1.vue';
import Page2 from '../views/page2.vue';
import Page3 from '../views/page3.vue';
import Page4 from '../views/page4.vue';
import hp from '../views/hp.vue';
import table from '../views/table.vue';
import map from '../views/map.vue';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      component: Page1,
      redirect: '/index',
    },
    {
      path: '/index',
      component: Index
    },
    {
      path: '/home',
      component: Page2
    },
    {
      path: '/page3',
      component: Page3
    },
    {
      path: '/page4',
      component: Page4
    },
    {
      path: '/hp',
      component: hp
    },
    {
      path: '/table',
      component: table
    },
    {
      path: '/map',
      component: map
    },
    {
      path: '/maphp',
      component: map
    },
  ]
});