<template>
  <div class="index">
    <div class="title">
      <img src="@/assets/index/logo.jpg" class="logo" alt="">
      <span class="title-name">天津人力资源服务产业园和平园区</span>
      <div @click="handleChange(y)" class="tabs" :class="active === y && 'active'" v-for="(x, y) in tabs" :key="y">{{ x }}</div>
    </div>
    <div class="content" v-if="active==0">
      <Component1Vue />
    </div>
    <div v-show="active==1">
      <component-2-vue :type="1" :list="list1"></component-2-vue>
    </div>
    <div v-show="active==2">
      <component-2-vue :type="2" :list="list2"></component-2-vue>
    </div>
    <div v-show="active==3" class="content">
      <div class="grid">
        <div class="grid1">
          <img src="@/assets/index/1.png" class="grid-icon" alt="">
          <div>
            <el-button type="primary" @click="handleClick(1)">集群注册</el-button>
          </div>
        </div>
        <div class="grid2">
          <img src="@/assets/index/2.png" class="grid-icon" alt="">
          <div>
            <el-button type="primary" @click="handleClick(2)">税务核定</el-button>
          </div>
        </div>
        <div class="grid3">
          <img src="@/assets/index/3.png" class="grid-icon" alt="">
          <div>
            <el-button type="primary" @click="handleClick(3)">企业管理</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="active==5" class="content">
      <iframe src="https://hripsys-bi.powerinternet.cn/index1/" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <el-dialog
    :visible.sync="dialogVisible"
    width="100%"
    custom-class="previewDialog"
    append-to-body
    :title="null">  
      <img src="@/assets/index/qr.png" class="dialog-img" alt="">
  </el-dialog>
  </div>
</template>

<script>
import Component1Vue from '@/components/index/Component1.vue'
import Component2Vue from '@/components/index/Component2.vue'
import {listCarouse} from '@/api/index'

export default {
  components: {
    Component1Vue,
    Component2Vue,
  },
  data () {
    return {
      tabs: [
        '园区介绍',
        '园区资讯',
        '党建风采',
        // '税务核定',
        '企业工作台',
        '数据中台',
        '智能问询',
      ],
      active: 0,
      list1:[],
      list2:[],
      list3:[],
      dialogVisible:false,
    }
  },
  created () {
    this.listCarouse(1)
    this.listCarouse(2)
    this.listCarouse(3)
    console.info(11);
  },
  methods: {
    handleChange(i) {
      if(i==4){
      //   window.open('http://industrial.powerinternet.cn')
      // }else if(i==4){
        window.open( window.location.origin+'/#/home')
      // } else if (i==3){
      //   window.open('https://ets.17xinfu.com/ets-web-pc/#/login')
      }else{
        this.active=i
      }
    },
    handleClick(i){
      if(i===1){
        this.dialogVisible=true
      }else if ( i ==2){
        window.open('https://ets.17xinfu.com/ets-web-pc/#/login')
      }else if (i==3){
        window.open('http://industrial.powerinternet.cn')
      }
    },
    async listCarouse(type) {
      try {
        const res = await listCarouse(type)
        // this.list[type] = res.rows
        this['list'+type] = res.rows
        // this.$set(this.list,type,res.rows)
        console.log("🚀 ~ listCarouse ~ res:", res)
        console.log("🚀 ~ listCarouse ~ this.list:", this.list)
      } catch (error) {
        
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.dialog-img{
  width: 400px;
  margin: auto;
  position: relative;
  left: -200px;
  border-radius: 20px;
}
.grid{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(3,1fr);
  img{
    width: 400px;
    // height: 200px;
    margin-bottom: 20px;
  }
  & ::v-deep .el-button{
    padding: 16px 50px;
    font-size: 20px;
    background: #165DFD !important;
    border-color: #165DFD !important;
  }
  &1{
    background: #88B0F5;
    align-content: center;
    text-align: center;
  }
  &2{
    background: #F2F2F2;
    align-content: center;
    text-align: center;
  }
  &3{
    background: #F6C2C8;
    align-content: center;
    text-align: center;
  }
}
.content{
  height: calc(100% - 80px);
}
.index {
  width: 100%;
  height: 100%;
  background: #FFF;
}

.active {
  color: red;
  border-bottom: 4px solid red;
}

.tabs {
  padding: 0 10px;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  margin: 0 20px;
}

.title {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 26px;

  &-name {
    margin-right: 60px;
    font-weight: bolder;
  }
}

.logo {
  width: 80px;
  height: 80px;
}
</style>