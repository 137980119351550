<template>
  <div>
    <el-carousel height="1000px"  >
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <img :src="`${baseUrl}${item.fileUrl}`" class="img1" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {listCarouse} from '@/api/index'
export default {
  props: {
    type: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: ()=>[]
    },
  },
  watch: {
    list(newValue, oldValue) {
      console.log("🚀 ~ list ~ newValue:", newValue)
      
    }
  },
  data() {
    return {
      // list: [],
      baseUrl:'http://industrial.powerinternet.cn/api'
    }
  },
  created () {
    // this.listCarouse();
  },
  methods: {
    // async listCarouse() {
    //   try {
    //     const res = await listCarouse(this.type)
    //     this.list = res.rows
    //     console.log("🚀 ~ listCarouse ~ res:", res)
    //   } catch (error) {
        
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &>div {
    width: 50%;
  }
}

.img1 {
  width: 100%;
  height: 100%;
}
.img2div{
  width: 900px;
  height: 650px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.img2{
  width: 440px;
  height: 320px;
  border-radius: 10px;
}
.item {
  background: #E6EAF0;
  width: 100%;
  height: 100%;
}

.u2 {
  position: relative;
  margin-top: 150px;
  margin-bottom: 50px;
  left: 66px;
  width: 660px;
  height: 74px;
  font-family: "微软雅黑 Bold", 微软雅黑, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 55px;
  color: rgb(13, 22, 38);
  border-width: 0px;
}

.u3 {
  position: relative;
  left: 66px;
  width: 870px;
  font-family: 微软雅黑, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(143, 153, 174);
  border-width: 0px;
  font-size: 30px;
}

.btn {
  position: relative;
  left: 66px;
  margin-top: 50px;
}

.u3 p {
  line-height: 1.5;
  margin: 0;
}
.bg{
  background: rgba(72, 133, 239, 1);
  .u3{
    color:#FFF;
  }
  .u2{
    color:#FFF;
  }
}
</style>
