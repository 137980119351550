<template>
  <div class="chart">
    <div class="title">
      经营指标
    </div>
    <div class="chartbody">
      <div class="chartbody-left">
        <div class="name">2023年营业总收入</div>
        <div class="num">
          {{ $store.state.page3Data.hpqGrossRevenue }}<span>亿元</span>
        </div>
      </div>
      <div class="chartbody-right">
        <div class="name">2023年纳税额</div>
        <div class="num">
          {{ $store.state.page3Data.hpqTaxAmount }}<span>亿元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.chart {
  height: 394px;
  width: 100%;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 300px;
    display: flex;

    .chartbody-left {
      width: 266px;
      height: 100%;
      background: url("../../assets/page3/shouru.png") no-repeat bottom;
      margin-left: 32px;
      margin-top: 20px;

      .name {
        width: 100%;
        text-align: center;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 16px;
        font-style: normal;
        text-transform: none;
        margin-top: 48px;
      }

      .num {
        margin-top: 30px;
        text-align: center;
        font-family: DIN Alternate, DIN Alternate;
        font-weight: 700;
        font-size: 50px;
        color: #47D1FF;
        line-height: 36px;
        font-style: normal;
        text-transform: none;

        span {
          font-size: 20px;
        }
      }
    }

    .chartbody-right {
      width: 266px;
      height: 100%;
      background: url("../../assets/page3/shui.png") no-repeat bottom;
      margin-left: 32px;
      margin-top: 20px;

      .name {
        width: 100%;
        text-align: center;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 16px;
        font-style: normal;
        text-transform: none;
        margin-top: 48px;
      }

      .num {
        margin-top: 30px;
        text-align: center;
        font-family: DIN Alternate, DIN Alternate;
        font-weight: 700;
        font-size: 50px;
        color: #47D1FF;
        line-height: 36px;
        font-style: normal;
        text-transform: none;

        span {
          font-size: 20px;
        }
      }
    }
  }
}
</style>