<template>
  <div class="chart">
    <div class="title">
      七大产业就业人员占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      lineData: [
        [10, 15, 30, 14, 56, 67, 27],
        [50, 30, 40, 20, 66, 17, 88],
      ]
    }
  },
  watch: {
    '$store.state.page4Data'(newValue, oldVal) {
      const { postNumCount, jobSeekerNumCount } = newValue;
      this.lineData[0] = [
        postNumCount.financialServicePercent,
        postNumCount.intelligentManufacturingPercent,
        postNumCount.modernServicePercent,
        postNumCount.transportationPercent,
        postNumCount.cultureEntertainmentPercent,
        postNumCount.artificialIntelligencePercent,
        postNumCount.medicalHealthPercent
      ];
      this.lineData[1] = [
        jobSeekerNumCount.financialServicePercent,
        jobSeekerNumCount.intelligentManufacturingPercent,
        jobSeekerNumCount.modernServicePercent,
        jobSeekerNumCount.transportationPercent,
        jobSeekerNumCount.cultureEntertainmentPercent,
        jobSeekerNumCount.artificialIntelligencePercent,
        jobSeekerNumCount.medicalHealthPercent
      ]
      this.initChart();
    }
  },
  methods: {
    initChart() {
      var myChart = echarts.init(this.$refs.echart);
      var option = {
        tooltip: {
          trigger: "axis",
          textStyle: {
            align: "left",
            color: "#fff",
            fontSize: "12px",
          },
          backgroundColor: "rgba(82,82,82,0.4)",
          borderColor: "rgba(82,82,82,0.4)",
        },
        grid: {
          left: "40px",
          right: "40px",
          bottom: "8%",
          top: "16%",
          containLabel: true, //刻度标签
        },
        xAxis: {
          boundaryGap: false,
          axisLabel: {
            // rotate: 30,
            fontSize: "12px", //文字的字体大小
            color: "#fff",
          },
          axisTick: {
            //x轴刻度
            show: false,
          },
          axisLine: {
            //x轴线样式
            show: false,
            lineStyle: {
              color: "#ccc",
              type: [0, 5],
              dashOffset: 5,
            },
          },
          data: ['金融服务', '商贸零售', '人力资源', '航运服务', '文化旅游', '数字信息', '医药健康'],
        },
        yAxis: {
          type: "value",
          splitLine: {
            //分割线
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: "12px",
          },
        },
        series: [
          {
            name: "行业供需人数",
            type: "line",
            smooth: true,
            data: this.lineData[0],
            color: "#FF4747",
            symbolSize: 6, //设定实心点的大小
            label: {
              show: true, // 在折线拐点上显示数据
              fontSize: 12,
              color: "#fff",
              fontWeight: 10,
            },
            areaStyle: {
              origin: "start",
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 71, 71, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 71, 71, 0)",
                },
              ]),
            },
          },
          {
            name: "行业供给人数",
            type: "line",
            smooth: true,
            data: this.lineData[1],
            color: "#FDAA4C",
            symbolSize: 6,
            label: {
              show: true,
              fontSize: 12,
              color: "#fff",
              fontWeight: 10,
            },
            areaStyle: {
              origin: "start",
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(253, 170, 76, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(253, 170, 76, 0)",
                },
              ]),
            },
          },
        ],
      };
      myChart.setOption(option, true);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 260px;
    }
  }
}
</style>