<template>
  <div class="chart">
    <div class="title">
      求职者年龄占比
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
const colors = [
  {
    'left': 'rgba(255, 72, 72, .16)',
    'right': 'rgba(255, 72, 72, .6)',
    'top': 'rgba(255, 72, 72, 1)',
    'bottom': 'rgba(255, 72, 72, .46)',
    'front': 'rgba(255, 72, 72, .66)',
  },
  {
    'left': 'rgba(254, 51, 186, .16)',
    'right': 'rgba(254, 51, 186, .6)',
    'top': 'rgba(254, 51, 186, 1)',
    'bottom': 'rgba(254, 51, 186, .46)',
    'front': 'rgba(254, 51, 186, .66)',
  },
  {
    'left': 'rgba(203, 51, 254, .16)',
    'right': 'rgba(203, 51, 254, .6)',
    'top': 'rgba(203, 51, 254, 1)',
    'bottom': 'rgba(203, 51, 254,  .46)',
    'front': 'rgba(203, 51, 254, .66)',
  },
  {
    'left': 'rgba(51, 92, 254, .16)',
    'right': 'rgba(51, 92, 254, .6)',
    'top': 'rgba(51, 92, 254, 1)',
    'bottom': 'rgba(51, 92, 254, .46)',
    'front': 'rgba(51, 92, 254, .66)',
  },
  {
    'left': 'rgba(46, 237, 237, .16)',
    'right': 'rgba(46, 237, 237, .6)',
    'top': 'rgba(46, 237, 237, 1)',
    'bottom': 'rgba(46, 237, 237, .46)',
    'front': 'rgba(46, 237, 237, .66)',
  },
  {
    'left': 'rgba(51, 254, 163, .16)',
    'right': 'rgba(51, 254, 163, .6)',
    'top': 'rgba(51, 254, 163, 1)',
    'bottom': 'rgba(51, 254, 163, .46)',
    'front': 'rgba(51, 254, 163, .66)',
  },
  {
    'left': 'rgba(226, 199, 62, .16)',
    'right': 'rgba(226, 199, 62, .6)',
    'top': 'rgba(226, 199, 62, 1)',
    'bottom': 'rgba(226, 199, 62, .46)',
    'front': 'rgba(226, 199, 62, .66)',
  },
]
export default {
  data() {
    return {
      eventWarning: [
      ]
    }
  },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { ageStructureDataVo, medianSalaryYoy2 } = newValue;
      this.eventWarning = [
      ageStructureDataVo.over10Percent,
      ageStructureDataVo.over20Percent,
      ageStructureDataVo.over30Percent,
      ageStructureDataVo.over40Percent,
      ageStructureDataVo.over50Percent,
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      const arr =[
        {name:'16岁～24岁',value:this.eventWarning[0]},
        {name:'25岁～34岁',value:this.eventWarning[1]},
        {name:'35岁～44岁',value:this.eventWarning[2]},
        {name:'45岁～54岁',value:this.eventWarning[3]},
        {name:'55岁及以上',value:this.eventWarning[4]},
      ]
      const total = arr.reduce((prev,item)=>{
        return prev+item.value
      },0)
      const option = {
    color: ['#308DFB', '#42DDFD', '#FC9F72', '#FDD970', '#B476FA' ],
    legend: {
        orient: 'vertical',
        top: 'center',
        right: '5%',
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 16,
        textStyle: {
            color: '#fff',
            fontSize: 16,
            rich: {
              a: {
                color: '#47D1FF',
                lineHeight: 18,
                fontSize:16,
              },
              b:{
                width:100
              }
            }
        },
        data: ['16岁～24岁', '25岁～34岁', '35岁～44岁', '45岁～54岁', '55岁及以上' ],
        formatter: (name) => {
        let percentage, value
        switch (name) {
          case '16岁～24岁':
            value = arr[0].value
           
            break;
          case '25岁～34岁':
            value =  arr[1].value
            break;
          case '35岁～44岁':
            value =  arr[2].value
            break;
          case '45岁～54岁':
            value =  arr[3].value
            break;
          case '55岁及以上':
            value = arr[4].value
            break;
          default:
            break;
        }
        percentage=value.toFixed(2)
        const str = [
          '{b|',
          name,
          '%}{a|',
             percentage,
          '%}',

        ].join('')
        return str
      },
    },
    // tooltip: {
    //     trigger: 'item',
    //     formatter: '{a} <br/>{b} : {c} ({d}%)',
    // },
    series: [
        {
            name: '',
            type: 'pie',
            radius: ['30%', '80%'],
            center: ['30%', '50%'],
            roseType: 'radius',
            minShowLabelAngle: 60,
            label: {
                show: false,
            },
            labelLine: {
                show: false,
            },
            data: [
                {
                    name: '16岁～24岁',
                    value: this.eventWarning[0],
                },
                {
                    name: '25岁～34岁',
                    value: this.eventWarning[1],
                },
                {
                    name: '35岁～44岁',
                    value: this.eventWarning[2],
                },
                {
                    name: '45岁～54岁',
                    value: this.eventWarning[3],
                },
                {
                    name: '55岁及以上',
                    value: this.eventWarning[4],
                },
            ],
        },
        {
            name: '',
            type: 'pie',
            radius: ['25%', '25.2%'],
            center: ['30%', '50%'],
            color: '#00ffff',
            roseType: 'radius',
            minShowLabelAngle: 60,
            label: {
                show: false,
            },
            labelLine: {
                show: false,
            },
            data: [1],
        },
    ],
};


      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj.png") no-repeat left;
    background-size: 220px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;



    .echartDiv {
      width: 100%;
      height: 230px;
    }
  }
}
</style>