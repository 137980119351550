<!-- 人力资源产业业态 -->
<template>
  <div class="box">
    <div class="title">
      <div class="beijing"></div>
      <div class="text">人力资源产业业态</div>
    </div>
    <div class="content">
      <div class="card">
        <div class="card-item card-item-1">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.hrOutsourcedNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源服务外包</div>
          <img src="../assets/image/card-icon-1.png" width="68">
        </div>
        <div class="card-item card-item-2">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.headhunterNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">猎头</div>
          <img src="../assets/image/card-icon-2.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-3">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.advertiseNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">招聘</div>
          <img src="../assets/image/card-icon-3.png" width="68">
        </div>
        <div class="card-item card-item-4">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.hrConsultingNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源咨询管理</div>
          <img src="../assets/image/card-icon-4.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-5">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.hrTrainNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源培训</div>
          <img src="../assets/image/card-icon-5.png" width="68">
        </div>
        <div class="card-item card-item-6">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.hrEvaluationNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源测评</div>
          <img src="../assets/image/card-icon-6.png" width="68">
        </div>
      </div>
      <div class="card">
        <div class="card-item card-item-7">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.hrSoftwareNum || 0' :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">人力资源软件管理</div>
          <img src="../assets/image/card-icon-7.png" width="68">
        </div>
        <div class="card-item card-item-8">
          <div class="num">
            <countTo :startVal='0' :endVal='$store.state.industryIndexEntity?.laborRelationConsultingNum || 0'
              :duration='3000' />
            <span class="unit">家</span>
          </div>
          <div class="name">劳动关系查询</div>
          <img src="../assets/image/card-icon-8.png" width="68">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
  data() {
    return {
      updateIndex: []
    }
  },
  // watch: {
  //   "$store.state.industryIndexEntity"(newValue, oldVal) {
  //     let new_advertiseNum = newValue.advertiseNum;
  //     let new_headhunterNum = newValue.headhunterNum;
  //     let new_hrConsultingNum = newValue.hrConsultingNum;
  //     let new_hrEvaluationNum = newValue.hrEvaluationNum;
  //     let new_hrOutsourcedNum = newValue.hrOutsourcedNum;
  //     let new_hrSoftwareNum = newValue.hrSoftwareNum;
  //     let new_hrTrainNum = newValue.hrTrainNum;
  //     let new_laborRelationConsultingNum = newValue.laborRelationConsultingNum;
  //     const { advertiseNum, headhunterNum, hrConsultingNum, hrEvaluationNum, hrOutsourcedNum, hrSoftwareNum, hrTrainNum, laborRelationConsultingNum } = oldVal;

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //     if (new_headhunterNum != headhunterNum) {
  //       this.updateIndex.push('headhunterNum');
  //     }

  //     if (new_hrConsultingNum != hrConsultingNum) {
  //       this.updateIndex.push('hrConsultingNum');
  //     }

  //     if (new_hrEvaluationNum != hrEvaluationNum) {
  //       this.updateIndex.push('hrEvaluationNum');
  //     }

  //     if (new_hrOutsourcedNum != hrOutsourcedNum) {
  //       this.updateIndex.push('hrOutsourcedNum');
  //     }

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }
  //     if (new_advertiseNum != advertiseNum) {
  //       this.updateIndex.push('advertiseNum');
  //     }

  //   }
  // },
  created() {
    console.log(this.$root.data);
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 316px;
  height: 785px;

  .title {
    height: 36px;
    background: url("../assets/image/Vector-1.png") no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;

    .beijing {
      width: 276px;
      height: 14px;
      background: url("../assets/image/组 3221@2x.png") no-repeat center;
      background-size: 100% 100%;
      margin: 0 auto;
      margin-top: 7px;
    }

    .text {
      width: 192px;
      height: 16px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      position: absolute;
      left: 50%;
      margin-left: -96px;
      top: 8px;
    }
  }

  .content {
    width: 316px;
    height: 749px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    border-radius: 0px 0px 0px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 0;
    box-sizing: border-box;

    .card {
      display: flex;

      .card-item {
        width: 158px;
        height: 164px;
        background: url("../assets/image/renliziyuanwaibao.png") no-repeat center;
        background-size: 100% 100%;
        text-align: center;
        padding: 20px 0;
        box-sizing: border-box;

        .num {
          font-family: DIN Alternate, DIN Alternate;
          font-weight: 700;
          font-size: 27px;
          line-height: 27px;
          text-align: center;
          font-style: normal;
          text-transform: none;

          .unit {
            font-size: 12px;
            margin-left: 3px;
          }

        }

        .name {
          margin-top: 10px;
          margin-bottom: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: 14px;
          color: #E5F9FF;
          line-height: 11px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }

      }

      .card-item-1 {
        color: #47D1FF;
      }

      .card-item-2 {
        color: #69E6B8;
        background: url("../assets/image/lietou.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-3 {
        color: #E3FEBF;
        background: url("../assets/image/zhaopin.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-4 {
        color: #FF8D9C;
        background: url("../assets/image/renliziyuanguanlizixun.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-5 {
        color: #8392FF;
        background: url("../assets/image/renliziyuanpeixun.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-6 {
        color: #F292E2;
        background: url("../assets/image/renliziyuanceping.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-7 {
        color: #EDC279;
        background: url("../assets/image/renliziyuanguanliruanjian.png") no-repeat center;
        background-size: 100% 100%;
      }

      .card-item-8 {
        color: #58E2D5;
        background: url("../assets/image/laodongguanxizixun.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
</style>