<template>
  <div class="chart">
    <div class="title">
      和平区高薪岗位TOP7
    </div>
    <div class="chartbody">
      <div ref="echart" id="echart" class="echartDiv"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";

export default {
  data() {
    return {
      xData: ['金融服务', '商贸零售', '人力资源', '航运服务', '文化旅游', '数字信息', '医药健康'],
      eventWarning: [],
      eventWarningName: [],
      eventHandling: [11, 38, 23, 139, 66, 66, 88],
      legend: [
        '2023年上半年', '2024年上半年'
      ]
    }
  },
  watch: {
    '$store.state.tableData'(newValue, oldVal) {
      const { highPayPostTop, medianSalaryYoy2 } = newValue;
      this.eventWarning = [
      highPayPostTop.top1Percent,
      highPayPostTop.top2Percent,
      highPayPostTop.top3Percent,
      highPayPostTop.top4Percent,
      highPayPostTop.top5Percent,
      highPayPostTop.top6Percent,
      highPayPostTop.top7Percent,
      ]
      this.eventWarningName = [
      highPayPostTop.top1Name,
      highPayPostTop.top2Name,
      highPayPostTop.top3Name,
      highPayPostTop.top4Name,
      highPayPostTop.top5Name,
      highPayPostTop.top6Name,
      highPayPostTop.top7Name,
      ]
      this.getEcharts3DBar();
    }
  },
  methods: {
    getEcharts3DBar() {
      const myChart = echarts.init(this.$refs.echart);
      // var salvProName =["人工智能工程师","大数据专家","区块链工程师","自动驾驶系统工程师","通信工程师","金融分析师","医疗数据分析师"];
      var salvProName =this.eventWarningName
      var salvProValue =this.eventWarning
      var salvProColor =['#FDD96B','#43E9A3','#31EEF0',144,135,117,74];
      var salvProMax =[];//背景按最大值
      for (let i = 0; i < salvProValue.length; i++) {
          salvProMax.push(salvProValue[0])
      }
      const option = {
          grid: {
              left: '2%',
              right: '2%',
              bottom: '2%',
              top: '2%',
              containLabel: true
          },
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'none'
              },
              formatter: function(params) {
                  return params[0].name  + ' : ' + params[0].value
              }
          },
          xAxis: {
              show: false,
              type: 'value'
          },
          yAxis: [{
              type: 'category',
              inverse: true,
              axisLabel: {
                  show: true,
                  textStyle: {
                      color: '#fff'
                  },
              },
              splitLine: {
                  show: false
              },
              axisTick: {
                  show: false
              },
              axisLine: {
                  show: false
              },
              data: salvProName
          }, {
              type: 'category',
              inverse: true,
              axisTick: 'none',
              axisLine: 'none',
              show: false,
              axisLabel: {
                  textStyle: {
                      color: '#ffffff',
                      fontSize: '12'
                  },
              },
              data:salvProValue
          }],
          series: [{
                  name: '值',
                  type: 'bar',
                  zlevel: 1,
                  itemStyle: {
                      normal: {
                          barBorderRadius: 30,
                          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                              offset: 0,
                              color: 'rgb(57,89,255,1)'
                          }, {
                              offset: 1,
                              color: 'rgb(46,200,207,1)'
                          }]),
                      },
                  },
                  barWidth: 10,
                  data: salvProValue.map((item,index)=>{
                    if(index<3){
                      return {
                        value:item,
                        itemStyle: {
                        normal: {
                            barBorderRadius: 30,
                            color:salvProColor[index]
                        },
                      },
                      }

                    }else{
                      return {
                        value:item,
                        itemStyle: {
                        normal: {
                            barBorderRadius: 30,
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                              offset: 0,
                              color: '#0088FF'
                          }, {
                              offset: 1,
                              color: '#47D1FF'
                          }]),
                        },
                      },
                      }
                    }
                  })
              },
              {
                  name: '背景',
                  type: 'bar',
                  barWidth: 10,
                  barGap: '-100%',
                  data: salvProMax,
                  itemStyle: {
                      normal: {
                          color: 'rgba(24,31,68,1)',
                          barBorderRadius: 30,
                      }
                  },
              },
          ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 314px;
  width: 100%;
  position: relative;

  .label {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;

    .label-1 {
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #0088FF 0%, #47D1FF 99%);
        border-radius: 0px 0px 0px 0px;
      }
    }

    .label-2 {
      margin-left: 15px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      span {
        margin-right: 5px;
        display: inline-block;
        width: 12px;
        height: 8px;
        background: linear-gradient(180deg, #FDAA4C 4%, #FFF77B 96%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .title {
    width: 100%;
    height: 44px;
    background: url("../../assets/page3/title-bj2.png") no-repeat left;
    background-size: 330px 44px;
    line-height: 44px;
    text-indent: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #E5F9FF;
  }

  .chartbody {
    width: 100%;
    height: 260px;
    display: flex;
    position: relative;

    .echartDiv {
      width: 100%;
      height: 240px;
    }
  }
}
</style>