<template>
  <div class="container-box">
    <BoxContent1></BoxContent1>
    <div style="display: flex;margin-left: 15px;flex-direction:column">
      <BoxContent2></BoxContent2>
      <!-- <BoxContent3></BoxContent3> -->
    </div>
  </div>
</template>

<script>
import BoxContent1 from './BoxContent1.vue'
import BoxContent2 from './BoxContent2.vue'
import BoxContent3 from './BoxContent3.vue'
export default {
  components: { BoxContent1, BoxContent2, BoxContent3 }

}
</script>

<style lang="scss" scoped>
.container-box {
  width: 680px;
  height: 788px;
  background: #053473;
  box-shadow: inset 0px 0px 20px 0px #085ABB;
  display: flex;
}
</style>