<template>
  <div class="container">
    <Header :type="4"></Header>
    <Nav></Nav>
    <div class="box-left">
      <!-- <Chart1></Chart1> -->
      <Chart8></Chart8>
      <Chart2></Chart2>
      <Chart3></Chart3>
    </div>
    <div class="box-centet">
      <div class="dipan">
        <div class="lou">
          <div class="hangyun lou-item">
            <img src="../assets/page4/icon_1.png">
            <div class="text">
              航运服务
            </div>
          </div>
          <div class="yiliao lou-item">
            <img src="../assets/page4/icon_2.png">
            <div class="text">
              医疗健康
            </div>
          </div>
          <div class="jinrong lou-item">
            <img src="../assets/page4/icon_3.png">
            <div class="text">
              金融服务
            </div>
          </div>
          <div class="shuzi lou-item">
            <img src="../assets/page4/icon_4.png">
            <div class="text">
              数字信息
            </div>
          </div>
          <div class="wenhua lou-item">
            <img src="../assets/page4/icon_5.png">
            <div class="text">
              文化旅游
            </div>
          </div>
          <div class="shangmao lou-item">
            <img src="../assets/page4/icon_7.png">
            <div class="text">
              商贸零售
            </div>
          </div>
          <div class="renli lou-item">
            <img src="../assets/page4/icon_6.png">
            <div class="text">
              人力资源
            </div>
          </div>
        </div>
      </div>
      <div class="box-centet-bottom">
        <Chart7></Chart7>
      </div>
    </div>
    <div class="box-right">
      <Chart4></Chart4>
      <Chart5></Chart5>
      <Chart9></Chart9>
      <!-- <Chart6></Chart6> -->

    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Nav from '../components/page2/Nav.vue'
import Chart1 from '../components/page4/Chart1.vue'
import Chart2 from '../components/page4/Chart2.vue'
import Chart3 from '../components/page4/Chart3.vue'
import Chart4 from '../components/page4/Chart4.vue'
import Chart5 from '../components/page4/Chart5.vue'
import Chart6 from '../components/page4/Chart6.vue'
import Chart7 from '../components/page4/Chart7.vue'
import Chart8 from '../components/page4/Chart8.vue'
import Chart9 from '../components/page4/Chart9.vue'
import { selectHPQHrMarketMonitor2, getCode } from '@/api/index'
export default {
  components: { Header, Nav, Chart1, Chart2, Chart3, Chart4, Chart5, Chart6, Chart7,Chart8,Chart9 },
  name: 'Page4',
  data() {
    return {
    }
  },
  created() {
    this.getAllData();
  },
  methods: {
    getAllData() {
      selectHPQHrMarketMonitor2({}).then(res => {
        console.log(res.data, ":setPage4Data");

        this.$store.commit('setPage4Data', res.data);
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}

.container {
  width: 100%;
  height: 100%;
  background: url("../assets/page4/bj.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;

  .box-left {
    width: 558px;
    height: 920px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 140px;
    left: 15px;
  }

  .box-centet {
    width: 760px;
    height: 920px;
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);

    .box-centet-bottom {
      width: 100%;
      height: 292px;
      background: #053473;
      box-shadow: inset 0px 0px 20px 0px #085ABB;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .dipan {
      width: 100%;
      height: 760px;
      // background: url("../assets/page4/image.png") no-repeat center;
      // background-size: 100% 100%;
      position: absolute;
      top: -30px;


      .lou {
        width: 496px;
        height: 424px;
       background: url("../assets/page4/lou1.png") no-repeat center;
        background-size: 100% 100%;
        margin: 0 auto;
        margin-top: 50px;
        position: relative;

        .lou-item {
          width: 160px;
          height: 160px;
          background: url('../assets/page4/yuanquan.png') no-repeat center;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;

          img {
            width: 44px;
            height: 44px;
          }

          .text {
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 500;
            font-size: 12px;
            color: #47D1FF;
            margin-bottom: 30px;
          }
        }

        .hangyun {
          top: 120px;
          left: -45px;
        }

        .yiliao {
          top: 120px;
          right: -60px;
        }

        .jinrong {
          top: 270px;
          left: -130px;
        }

        .shuzi {
          top: 270px;
          right: -130px;
        }

        .wenhua {
          width: 180px;
          height: 180px;
          background: url('../assets/page4/yuanquan.png') no-repeat center;
          background-size: 100% 100%;
          top: 392px;
          left: -40px;
        }

        .shangmao {
          width: 180px;
          height: 180px;
          background: url('../assets/page4/yuanquan.png') no-repeat center;
          background-size: 100% 100%;
          top: 392px;
          right: -30px;
        }

        .renli {
          width: 180px;
          height: 180px;
          background: url('../assets/page4/yuanquan.png') no-repeat center;
          background-size: 100% 100%;
          bottom: -180px;
          left: 150px;

          img {
            width: 54px;
            height: 54px;
          }
        }
      }
    }
  }

  .box-right {
    width: 558px;
    height: 920px;
    background: #053473;
    box-shadow: inset 0px 0px 20px 0px #085ABB;
    position: absolute;
    top: 140px;
    right: 15px;
  }
}
</style>
