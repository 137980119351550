<template>
  <div class="box-bottom">
    <HpLeft></HpLeft>
    <HpCenter></HpCenter>
    <HpRight></HpRight>
    <!-- <Industry></Industry>
    <Industry></Industry> -->
    <!-- <Personnel></Personnel> -->
  </div>
</template>

<script>
import Industry from './Industry.vue'
import Personnel from './Personnel.vue'
import HpLeft from './hp/HpLeft.vue';
import HpCenter from './hp/HpCenter.vue';
import HpRight from './hp/HpRight.vue';
export default {
  components: { Industry, Personnel,HpLeft ,HpRight,HpCenter},
}
</script>

<style lang="scss" scoped>
.box-bottom {
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: -23px;
  display: flex;
}
</style>