<template>
  <div id="app" :style="{
    width: `${style.width}px`,
    height: `${style.height}px`,
    transform: `${style.transform}`
  }">
    <!-- <div class="container">
      <Header></Header>
      <BoxTop></BoxTop>
      <BoxBottom></BoxBottom>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Header from './components/Header.vue'
// import BoxTop from './components/BoxTop.vue'
// import BoxBottom from './components/BoxBottom.vue'
// import { getAllData, getCode } from '@/api/index'
export default {
  // components: { Header, BoxTop, BoxBottom },
  name: 'App',
  data() {
    return {
      style: {
        width: '1920',
        height: '1080',
        transform: 'scaleY(1) scaleX(1) translate(-50%, -50%)'
      },
    }
  },
  created() {
    // this.getAllData()
    // setInterval(() => {
    //   this.getNewCode().then(res => {
    //     if (!res) {
    //       this.getAllData()
    //     }
    //   })
    // }, 1000 * 10)
  },
  methods: {
    // getAllData() {
    //   getAllData().then(res => {
    //     const { employeeTotalNm, postTotalNum, industryIndexEntity } = res.data;
    //     this.$store.commit('setEmployeeTotalNm', employeeTotalNm);
    //     this.$store.commit('setPostTotalNum', postTotalNum);
    //     this.$store.commit('setAllData', res.data);
    //     this.$store.commit('setIndustryIndexEntity', industryIndexEntity);
    //   })
    // },
    // async getNewCode() {
    //   const res = await getCode()
    //   const code = localStorage.getItem('BI-CODE')
    //   if (code && res.data == code) {
    //     return true
    //   } else {
    //     localStorage.setItem('BI-CODE', res.data)
    //   }
    //   return false
    // },
    getScale() {
      const w = window.innerWidth / this.style.width
      const h = window.innerHeight / this.style.height
      return { x: w, y: h }
    },
    setScale() {
      const scale = this.getScale()
      this.style.transform =
        'scaleY(' + scale.y + ') scaleX(' + scale.x + ') translate(-50%, -50%)'
    }
  },
  mounted() {
    const that = this
    that.setScale()
    /* 窗口改变事件 */
    window.addEventListener('resize', function () {
      console.log('窗口发生变化')
      that.setScale()
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}
.previewDialog.el-dialog {
  .el-dialog__header {
    display: none !important;
  }
  .dj-dialog-content {
    padding: 0 !important;
    overflow: unset !important;
  }
  .el-dialog__body{
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 auto !important;
  }
}
.container {
  width: 100%;
  height: 100%;
  background: url("./assets/image/bg.png") no-repeat center;
  background-size: 100% 100%;
}
</style>
