<template>
  <div class="container">
    <Header :type="4"></Header>
    <Nav></Nav>
    <BoxTop></BoxTop>
    <HpBoxBottom></HpBoxBottom>
  </div>
</template>

<script>
import Nav from '../components/page2/Nav.vue'
// import Header from '../components/hp/Header.vue'
import Header from '../components/Header.vue'
import BoxTop from '../components/hp/BoxTop.vue'
import HpBoxBottom from '../components/HpBoxBottom.vue'
import { selectHPQDetailInfoByLastCode, getCode } from '@/api/index'
export default {
  components: { Header, BoxTop, HpBoxBottom, Nav },
  name: 'App',
  data() {
    return {
    }
  },
  created() {
    this.getAllData()
    setInterval(() => {
      this.getNewCode().then(res => {
        if (!res) {
          this.getAllData()
        }
      })
    }, 1000 * 10)
  },
  methods: {
    getAllData() {
      selectHPQDetailInfoByLastCode().then(res => {
        this.$store.commit('setHpData', res.data);
        console.log(res.data);
      })
    },
    async getNewCode() {
      const res = await getCode()
      const code = localStorage.getItem('BI-CODE')
      if (code && res.data == code) {
        return true
      } else {
        localStorage.setItem('BI-CODE', res.data)
      }
      return false
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  z-index: 100;
  transform-origin: 0 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #012554;
}

.container {
  width: 100%;
  height: 100%;
  background: url("../assets/hp/背景@2x.png") no-repeat center;
  background-size: 100% 100%;
}
</style>
