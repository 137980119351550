<template>
  <div class="box-bottom">
    <BoxBottomLeft></BoxBottomLeft>
    <BoxBottomCenter></BoxBottomCenter>
    <BoxBottomRight></BoxBottomRight>
  </div>
</template>

<script>
import BoxBottomLeft from './BoxBottomLeft.vue'
import BoxBottomCenter from './BoxBottomCenter.vue'
import BoxBottomRight from './BoxBottomRight.vue'
export default {
  components: { BoxBottomLeft, BoxBottomCenter, BoxBottomRight },
}
</script>

<style lang="scss" scoped>
.box-bottom {
  width: 100%;
  height: 788px;
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: -24px;
  display: flex;
  justify-content: space-between;
}
</style>