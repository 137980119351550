import { render, staticRenderFns } from "./BoxBottomLeft.vue?vue&type=template&id=f1402434&scoped=true"
import script from "./BoxBottomLeft.vue?vue&type=script&lang=js"
export * from "./BoxBottomLeft.vue?vue&type=script&lang=js"
import style0 from "./BoxBottomLeft.vue?vue&type=style&index=0&id=f1402434&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1402434",
  null
  
)

export default component.exports